import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserRoles } from "lib/api";
import { AxiosError } from "axios";
import { Status } from "lib/enums";

export const getUserRolesThunk = createAsyncThunk<
  string[],
  void,
  { rejectValue: string }
>("userRoles/getUserRoles", async (_, { rejectWithValue }) => {
  try {
    return await getUserRoles();
  } catch (err) {
    let error: AxiosError<string> = err;
    return rejectWithValue(error.response.data);
  }
});

type UserRolesState = {
  allRoles: string[];
  getAllRolesStatus: Status;
  backendError: string;
};

const initialState: UserRolesState = {
  allRoles: [],
  getAllRolesStatus: Status.Idle,
  backendError: "",
};

const userSlice = createSlice({
  name: "userRoles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserRolesThunk.pending, (state) => {
        state.getAllRolesStatus = Status.Pending;
      })
      .addCase(getUserRolesThunk.rejected, (state, action) => {
        state.getAllRolesStatus = Status.Rejected;
        state.backendError = action.payload;
      })
      .addCase(getUserRolesThunk.fulfilled, (state, action) => {
        state.getAllRolesStatus = Status.Success;
        state.backendError = "";
        state.allRoles = action.payload;
      });
  },
});

export default userSlice.reducer;
