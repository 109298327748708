import classNames from "classnames/bind";
import { ButtonStyles, ButtonSize } from "../../lib/enums";

const Button = ({
  id,
  label,
  href,
  size,
  styling,
  cssClass,
  eventCategory,
  eventLabel,
  onClickHandler,
}: ButtonProps) => {
  return (
    <a
      id={id}
      className={classNames(
        "msds-btn",
        size ? size : ButtonSize.Medium,
        styling ? styling : ButtonStyles.Primary,
        cssClass ? cssClass : ""
      )}
      onClick={onClickHandler}
      href={href}
      // data-scrollto=""
      // data-enable-google-event-tracking="False"
      // data-category=""
      // data-label=""
      data-event-category={eventCategory}
      data-event-label={eventLabel}
      tabIndex={0}
    >
      {label}
    </a>
  );
};

type ButtonProps = {
  id?: string;
  label: string;
  href?: string;
  size?: ButtonSize;
  styling?: ButtonStyles;
  cssClass?: string;
  onClickHandler?: () => void;
  eventCategory?: string;
  eventLabel?: string;
};

export default Button;
