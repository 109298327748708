import axios, { AxiosResponse } from "axios";
import {
    CompanyUserType,
    SupportEmailType,
    MyDetailsType,
    UpdateSubscriptionType,
    SubscriptionType,
    UpdatePasswordType,
    CompanyVerificationResponseWithMatches,
    LicenseType,
} from "lib/types";
import { UserTempDataType } from "lib/types";
import { AdminDeclineUserApplicationData } from "../redux/api/AdminDeclineUserApplication/adminDeclineUserApplicationSlice";
import { API_URL } from "../config";
import { UpdateUserProfileDataType, BaseUserType } from "lib/types";

export const resetPassword = async (
    userName: string
): Promise<AxiosResponse> => {
    const response = await axios.post(`${API_URL()}/resetpassword`, {
        username: userName,
    });
    return response.data;
};

export const getConfigurationList = async (
    configurationListName: string
): Promise<{
    data: Array<{ key: string; value: string }>;
    listName: string;
}> => {
    const response = await axios.get(`${API_URL()}/configurationlist`, {
        params: { configurationListName: configurationListName },
    });
    return { data: response.data, listName: configurationListName };
};

export const createLogin = async (submitData): Promise<AxiosResponse> => {
    const response = await axios.post(
        `${API_URL()}/userApplication`,
        submitData
    );
    return response;
};

export const getCreateNewPasswordUserEmail = async (
    createNewPasswordId: string
): Promise<AxiosResponse> => {
    const response = await axios.get(`${API_URL()}/createpassword`, {
        params: { resetCode: createNewPasswordId },
    });
    return response.data;
};

export const postCreateNewPassword = async (
    createNewPasswordData
): Promise<AxiosResponse> => {
    const response = await axios.post(
        `${API_URL()}/createpassword`,
        createNewPasswordData
    );
    return response.data;
};

export const activateUserAccount = async (
    activationCode: string
): Promise<AxiosResponse> => {
    const response = await axios.post(
        `${API_URL()}/contact/userapplicationid/${activationCode}`,
        { activationCode: activationCode }
    );
    return response.data;
};

export const getMyDetails = async (): Promise<CompanyUserType> => {
    const response = await axios.get(`${API_URL()}/userdetails/my`);
    return response.data;
};

export const updateMyDetailsData = async (
    payload: MyDetailsType
): Promise<any> => {
    const response = await axios.put(`${API_URL()}/userdetails`, {
        firstName: payload.firstName,
        lastName: payload.lastName,
        jobTitle: payload.jobTitle,
        telephone: payload.telephone,
        mobile: payload.mobile,
        prefferedLanguageId: payload.prefferedLanguageId,
        email: payload.email,
        username: payload.username,
    });
    return response.data;
};

export const getMySubscription = async (): Promise<SubscriptionType> => {
    const response = await axios.get(`${API_URL()}/communication-subscription`);
    return response.data;
};

export const updateMySubscription = async (
    payload: UpdateSubscriptionType
): Promise<any> => {
    const response = await axios.put(
        `${API_URL()}/communication-subscription`,
        {
            subscribed: payload.subscribed,
            privacyNoticeText: payload.privacyNoticeText,
            consentText: payload.consentText,
            currentPagePathAndQuery: payload.currentPagePathAndQuery,
        }
    );
    return response.data;
};

export const updateMyPassword = async (
    payload: UpdatePasswordType
): Promise<any> => {
    const response = await axios.put(`${API_URL()}/updatepassword`, {
        oldPassword: payload.oldPassword,
        newPassword: payload.newPassword,
        confirmPassword: payload.confirmPassword,
    });
    return response.data;
};

export const getUserDetailsCompany = async (): Promise<CompanyUserType[]> => {
    const response = await axios.get(`${API_URL()}/userdetails/company`);
    return response.data;
};

export const getUserRoles = async (): Promise<string[]> => {
    const response = await axios.get(`${API_URL()}/user-roles`);
    return response.data;
};

export const createUserTemporaryProfile = async (
    payload: BaseUserType
): Promise<any> => {
    const response = await axios.post(`${API_URL()}/usertemporaryprofile`, {
        email: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
        roles: payload.roles,
    });
    return response.data;
};

export const updateExistingUserData = async (
    payload: UpdateUserProfileDataType
): Promise<any> => {
    const response = await axios.put(`${API_URL()}/contact`, {
        id: payload.id,
        email: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
        roles: payload.roles,
        phone: payload.phone,
        mobilePhone: payload.mobilePhone,
        noLongerWithCompany: payload.noLongerWithCompany,
    });
    return response.data;
};

export const getUserTemporaryProfile = async (
    id: string
): Promise<AxiosResponse<UserTempDataType>> => {
    const response = await axios.get(`${API_URL()}/usertemporaryprofile`, {
        params: { id: id },
    });
    return response;
};

export const postUserTemporaryProfile = async (
    registerTemporaryUserData
): Promise<AxiosResponse<UserTempDataType>> => {
    const response = await axios.post(
        `${API_URL()}/contact/usertemporaryprofiles/`,
        registerTemporaryUserData
    );
    return response;
};

export const getUserTemporaryProfileAdmin = async (
    activationCode: string
): Promise<UserTempDataType> => {
    const response = await axios.get(`${API_URL()}/userapplication`, {
        params: { activationCode: activationCode },
    });
    return response.data;
};

export const postUserTemporaryProfileAdmin = async (
    activationCode: string,
    userRolesPayload: string[]
): Promise<AxiosResponse> => {
    const response = await axios.post(
        `${API_URL()}/contact/admin/userapplicationid/${activationCode}`,
        { roles: userRolesPayload }
    );
    return response.data;
};

export const postAdminDeclineUserApplication = async (
    id: string
): Promise<AxiosResponse<AdminDeclineUserApplicationData>> => {
    const response = await axios.delete(
        `${API_URL()}/userapplication?activationCode=${id}`
    );
    return response;
};

export const postUpdatedEmailConfirmation = async (
    id: string
): Promise<any> => {
    const response = await axios.put(`${API_URL()}/contact/update-email`, {
        userDetailsResetRequestId: id,
    });
    return response.data;
};

export const sendSupportEmail = async (
    supportEmailData: SupportEmailType
): Promise<AxiosResponse<any>> => {
    const response = await axios.post(
        `${API_URL()}/support-email`,
        supportEmailData
    );
    return response.data;
};

export const postCompanyVerification = async (
    contactId: string
): Promise<AxiosResponse<CompanyVerificationResponseWithMatches>> => {
    const response = await axios.post(
        `${API_URL()}/company-verification/contactId/${contactId}`
    );
    return response;
};

export const postCompanyVerificationWithoutId = async (
): Promise<AxiosResponse<CompanyVerificationResponseWithMatches>> => {
    const response = await axios.post(
        `${API_URL()}/company-verification/authorized`
    );
    return response;
};


export const updateCompanyWithDunsNumber = async ({
    accountId,
    dunsNumber,
    contactEmail,
}: {
    accountId: string;
    dunsNumber: string;
    contactEmail: string;
}): Promise<AxiosResponse<any>> => {
    const response = await axios.put(`${API_URL()}/account`, {
        accountId: accountId,
        dunsNumber: dunsNumber,
        contactEmail: contactEmail,
    });
    return response;
};

export const getLicenses = async (): Promise<AxiosResponse<LicenseType[]>> => {
    const response = await axios.get(`${API_URL()}/license-handler`);    
    return response;
};

export const postLicenseClaim = async ({
    slc,
    token,
}: {
    slc: string;
    token: string;
}): Promise<AxiosResponse<any>> => {
    const response = await axios.post(
        `${API_URL()}/license-handler`, 
            { 
                softwareLicenseCode: slc,
                secret: token
            }
    );     
    return response;
};

export const postSignupCompanyVerification = async (
    companyData
): Promise<AxiosResponse> => {
    const response = await axios.post(
        `${API_URL()}/company-verification`,
        companyData
    );
    return response;
};

export const postSignupDataSubmission = async (
    data
): Promise<AxiosResponse> => {
    const response = await axios.post(`${API_URL()}/userapplication`, data);
    return response;
};

export const checkIfUserExists = async (
    userName: string
): Promise<AxiosResponse> => {
    const response = await axios.get(`${API_URL()}/contact`, {
        params: { userName: userName },
    });
    return response;
};
