import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FormButton from "../FormComponents/FormButton/FormButton";
import {
  getCreateNewPasswordUserEmailThunk,
  postCreateNewPasswordThunk,
} from "../../redux/userActions/actions";
import { ButtonSize, ButtonStyles, Status } from "lib/enums";
import { useFormFieldState } from "components/FormComponents/formHooks/useFormFieldState";
import Form from "components/FormComponents/Form/Form";
import Input from "components/FormComponents/Input/Input";
import { ValidationTypes } from "components/FormComponents/formHooks/useValidation";
import "./create-new-password-form.scss";
import { MILESTONE_URL } from "config";
import Button from "components/Button/Button";

const CreatePasswordForm = () => {
  const { t } = useTranslation();
  const { resetCode } = useParams();
  const dispatch = useAppDispatch();

  const password = useFormFieldState();
  const confirmPassword = useFormFieldState();
  const privacyNoticeText = t("forms.consents.privacy_notice");

  const requiredFormFields = [password, confirmPassword];

  const psotData = {
    password: password.state.value as string,
    confirmPassword: confirmPassword.state.value as string,
    currentPagePathAndQuery: window.location.href as string,
    privacyNoticeText: privacyNoticeText as string,
    validResetCode: resetCode as string,
  };
  const userEmail = useAppSelector(
    (state) => state.userActions.createNewPasswordEmail
  );

  const getCreateNewPasswordStatus = useAppSelector(
    (state) => state.userActions.getCreateNewPasswordStatus
  );

  const getBackendErrorMessage = useAppSelector(
    (state) =>
      state.userActions.backendErrors.getCreateNewPasswordUserEmailErrorMsg
  );

  const postBackendErrorMessage = useAppSelector(
    (state) => state.userActions.backendErrors.postCreateNewPasswordErrorMsg
  );

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    dispatch(postCreateNewPasswordThunk(psotData));
  };

  useEffect(() => {
    dispatch(getCreateNewPasswordUserEmailThunk(resetCode));
  }, []);

  return (
    <>
      <div className="msds-input msds-input--error">
        <p className="msds-input__info-message">
          {getBackendErrorMessage}
          {postBackendErrorMessage}
        </p>
      </div>
      {(getCreateNewPasswordStatus === Status.Success) ? (
        <Form
          cssClasses="user-portal__create-new-password-form"
          handleSubmit={handleSubmitForm}
          requiredFields={requiredFormFields}
        >
          <Input
            id="create-new-password-form__e2e-password-input"
            type="password"
            placeholder={t("forms.labels.password", { required: "*" })}
            label={t("forms.labels.password", { required: "*" })}
            initialMessage={{
              validation:
                "Please enter a password. Password must be 8 characters or more, contain upper- and lowercase letters, at least 1 number and 1 special character.",
            }}
            validation={ValidationTypes.IsPassword}
            makeInvalid={password.state.makeInvalid}
            handleDataChange={(newValue, isValid) =>
              password.setState({ value: newValue, isValid: isValid })
            }
          />

          <Input
            id="create-new-password-form__e2e-confirm-password-input"
            type="password"
            placeholder={t("forms.labels.confirmPassword", {
              required: "*",
            })}
            label={t("forms.labels.confirmPassword", { required: "*" })}
            initialMessage={{ validation: "Password does not match." }}
            validation={ValidationTypes.IsConfirmPassword}
            makeInvalid={confirmPassword.state.makeInvalid}
            data={{ password: password.state.value }}
            handleDataChange={(newValue, isValid) =>
              confirmPassword.setState({
                value: newValue,
                isValid: isValid,
              })
            }
          />

          <Input
            id="create-new-password-form__e2e-email-input"
            type="text"
            placeholder={t("forms.labels.email", {
              required: "*",
            })}
            label={t("forms.labels.email", {
              required: "*",
            })}
            initialValue={userEmail}
            isDisabled
          />
          <p className="msds-text-body-3 msds-text-gray-10">
            <Trans
              components={{
                anchor: <a href={`${MILESTONE_URL()}/privacy-policy/`} />,
              }}
            >
              {t("forms.privacyNoticeRich")}
            </Trans>
          </p>
          <FormButton
            id="create-new-password-form__e2e-save-button"
            label={t("buttons.save")}
            size={ButtonSize.Large}
            styling={ButtonStyles.Secondary}
            eventCategory="User Portal"
            eventLabel="Create New Password Form"
          />
        </Form>
      ) : (
        <Button
          href={`${MILESTONE_URL()}/my-milestone/`}
          label={t("buttons.goToLoginPage")}
          size={ButtonSize.Medium}
          styling={ButtonStyles.Secondary}
        />
      )}
    </>
  );
};

export default CreatePasswordForm;
