import classnames from "classnames/bind";
import { ReactNode, useState, useEffect } from "react";
import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg";
import "./checkboxes.scss";
import { useValidation, ValidationTypes } from "../formHooks/useValidation";
import { Trans, useTranslation } from "react-i18next";
import i18n from "i18next";
import cloneDeep from "lodash.clonedeep";
import Popover, { PopoverPlacement } from "components/Popover/Popover";
import { format } from "path";

const CheckBoxes = ({
  checkboxesHeader,
  checkboxes,
  initialSelected,
  validation,
  makeInvalid,
  handleChangeData,
  displayTwoColumns,
}: CheckboxesProps) => {
  const [selected, setSelected] = useState(initialSelected ?? []);
  const { isValid, message } = useValidation(selected, validation);
  const { t } = useTranslation();

  const defaultValueForSingleCheckbox = "true";
  const formatcheckboxLableToMatchTranslation = (checkbox: {
    label?: string;
  }) => {
    if (typeof checkbox.label !== "string") {
      return "";
    }
    const formatedCheckboxLabel = checkbox.label
      ?.trim()
      ?.toLowerCase()
      ?.replace(/\s+(\w)/g, (_, letter) => letter.toUpperCase()); // transform label to camel case

    return formatedCheckboxLabel;
  };
  const getUserRoleInfo = (string) => {
    const userRoleInfo = {
      header: i18n.t(`userRoles.${string}.roleKey`),
      description: i18n.t(`userRoles.${string}.roleDescription`),
    };
    return userRoleInfo;
  };
  const addPopoverInfoToCheckbox = (checkbox) => {
    const formatedTextboxLable =
      formatcheckboxLableToMatchTranslation(checkbox);
    const userRoleInfo = getUserRoleInfo(formatedTextboxLable);
    return userRoleInfo;
  };
  const handleChange = (e) => {
    const newSelected = cloneDeep(selected);

    if (e.target.checked) {
      if (e.target.value.length == 0) {
        newSelected.push(defaultValueForSingleCheckbox);
      } else {
        newSelected.push(e.target.value);
      }
    } else {
      const index = newSelected.indexOf(e.target.value);
      newSelected.splice(index, 1);
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    if (initialSelected) setSelected(initialSelected);
  }, [initialSelected]);

  const checkboxElements = (
    <>
      {checkboxes?.map((checkbox) => {
        const popoverData = addPopoverInfoToCheckbox(checkbox);
        const colClassName = displayTwoColumns
          ? "col-12 col-sm-6"
          : "col-12 col-6";
        return (
          <div key={checkbox.id} className={colClassName}>
            <Popover
              description={popoverData.description}
              header={popoverData.header}
              placement={PopoverPlacement.Top}
            >
              <div key={checkbox.id} className="msds-checkbox">
                <input
                  className="msds-checkbox__input"
                  id={checkbox.id}
                  type="checkbox"
                  checked={selected.includes(checkbox.value)}
                  value={checkbox.value}
                  disabled={checkbox.isDisabled ?? false}
                  onChange={(e) => handleChange(e)}
                  onBlur={() => handleChangeData(selected, isValid)}
                />
                <label
                  className="msds-checkbox__label msds-text-body-1 msds-text-gray-10"
                  htmlFor={checkbox.id}
                >
                  <svg className="msds-checkbox__checkmark">
                    <use href={`${spritemap}#checkmark`} />
                  </svg>
                  {checkbox.label}
                </label>
              </div>
            </Popover>
          </div>
        );
      })}
    </>
  );

  return (
    <div
      className={classnames(
        isValid === true && "msds-checkboxes-field",
        (isValid === false || makeInvalid) && "msds-checkboxes-field--error"
      )}
    >
      <div className="msds-checkboxes-field__wrapper">
        {checkboxesHeader ? (
          <div className="msds-checkboxes-field__header">
            {checkboxesHeader}
          </div>
        ) : (
          <></>
        )}
        <div className="msds-checkboxes-field__list">{checkboxElements}</div>
      </div>
    </div>
  );
};

export default CheckBoxes;

type CheckboxesProps = {
  checkboxesHeader?: string;
  checkboxes?: CheckboxType[];
  initialSelected?: string[];
  validation?: ValidationTypes;
  makeInvalid?: boolean;
  handleChangeData: (newValue: any, isValid: boolean) => void;
  displayTwoColumns?: boolean;
};

type CheckboxType = {
  id: string;
  label: string | ReactNode;
  value: string;
  isDisabled?: boolean;
};
