import { createSlice } from "@reduxjs/toolkit";
import { Nullable, CompanyVerificationResponseDataType } from "lib/types";
import { Status } from "../../lib/enums";
import {
  postCompanyVerificationThunk as postCompanyVerification,
  updateCompanyWithDunsNumberThunk as updateCompanyWithDunsNumber,
} from "./actions";

export type CompanyVerificationState = {
  data: Nullable<CompanyVerificationResponseDataType>;
  postCompanyVerificationStatus: Status;
  dunsNumberAlreadyRegistered: boolean;
  dunsNumber:string;
  updateCompanyWithDunsNumberStatus: Status;
  backendErrors: {
    postCompanyVerificationErrorMsg: string;
    updateCompanyWithDunsNumberStatusErrorMsg: "";
  };
};

const initialState: CompanyVerificationState = {
  data: null,
  dunsNumberAlreadyRegistered: false,
  dunsNumber: "",
  postCompanyVerificationStatus: Status.Idle,
  updateCompanyWithDunsNumberStatus: Status.Idle,
  backendErrors: {
    postCompanyVerificationErrorMsg: "",
    updateCompanyWithDunsNumberStatusErrorMsg: "",
  },
};

const companyVerificationSlice = createSlice({
  name: "companyVerification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postCompanyVerification.pending, (state) => {
        state.postCompanyVerificationStatus = Status.Pending;
      })
      .addCase(postCompanyVerification.rejected, (state, action) => {
        state.postCompanyVerificationStatus = Status.Rejected;
        if (action.payload && action.payload.response && action.payload.response.status === 500) {
          state.backendErrors.postCompanyVerificationErrorMsg =
          action.payload.response.data.title;
        } else if (action.payload && action.payload.response) {   
          state.data = action.payload.response.data;
        }
      })
      .addCase(postCompanyVerification.fulfilled, (state, action) => {
        state.postCompanyVerificationStatus = Status.Success;
        state.data = action.payload.data;
        state.backendErrors.postCompanyVerificationErrorMsg = "";
      })
      .addCase(updateCompanyWithDunsNumber.pending, (state) => {
        state.updateCompanyWithDunsNumberStatus = Status.Pending;
      })
      .addCase(updateCompanyWithDunsNumber.rejected, (state, action) => {
        state.updateCompanyWithDunsNumberStatus = Status.Rejected;
        if(action.payload.response.status === 400) {
          state.dunsNumberAlreadyRegistered = true;
          state.dunsNumber = action.payload.response.data          
        }
        else{          
          state.backendErrors.updateCompanyWithDunsNumberStatusErrorMsg =
            action.payload.response.data;          
        }
      })
      .addCase(updateCompanyWithDunsNumber.fulfilled, (state) => {
        state.updateCompanyWithDunsNumberStatus = Status.Success;
        state.backendErrors.updateCompanyWithDunsNumberStatusErrorMsg = "";
      });
  },
});

export default companyVerificationSlice.reducer;
