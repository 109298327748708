import { useAppSelector } from "redux/hooks";
import { ReactComponent as AlertmarkIcon } from "@milestone-sys/web-design-system/svg/rich/warning.svg";
import { MILESTONE_URL } from "config";
import "./error.scss";

export const Error = () => {
    const dunsNumberData: any = useAppSelector((state) => state.companyVerification.dunsNumber)
    return (
        <>
            <div className="container text-center d-flex flex-column align-items-center align-content-center justify-content-center error">
                <div className="row">
                    <div className="col-12 mb-4">
                        <AlertmarkIcon className="user-portal__alert-icon mb-4 error__icon" />
                        <div className="error__message msds-text-lead-bold mb-4">
                            Sorry, an error has occured during the validation
                        </div>
                        <div className="error__description msds-text-body-0 mb-4">                            
                            Please contact 
                            <a href={MILESTONE_URL()+'/support/contact-us/contact-us#CDSBGlobalBlock-Contactyourregion' } className="error__link">
                                {" " + "Milestone Sales Support" + " "}
                            </a> 
                            and share this reference number: 
                            <span className="error__duns-number msds-text-body-0-bold mb-4">                            
                                {" " + dunsNumberData.dunsNumber + " "}
                            </span>
                            Sorry for the inconvenience.
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
