import i18next from "i18next";

const t = i18next.t;

export const licenseColumns = [
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: () => t("pages.myLicenses.table.columnLabels.slc"),
    accessor: "softwareLicenseCode",
  },
  {
    Header: () => t("pages.myLicenses.table.columnLabels.productName"),
    accessor: "productName",
  },
  {
    Header: () => t("pages.myLicenses.table.columnLabels.status"),
    accessor: "lastOnlineActivationDate",
  },
];
