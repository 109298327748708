import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import FormButton from "components/FormComponents/FormButton/FormButton";
import {
  getUserTemporaryProfileThunk,
  postUserTemporaryProfileThunk,
} from "redux/api/userTemporaryProfile/actions";
import { ButtonSize, ButtonStyles } from "lib/enums";
import { useFormFieldState } from "components/FormComponents/formHooks/useFormFieldState";
import Form from "components/FormComponents/Form/Form";
import Input from "components/FormComponents/Input/Input";
import CheckBoxes from "components/FormComponents/CheckBoxes/CheckBoxes";
import { ValidationTypes } from "components/FormComponents/formHooks/useValidation";
import { MILESTONE_URL } from "config";

const UserTemporaryProfileForm = () => {
  // unpack of "t", which is a i18next method that implements content translation
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const userTempData = useAppSelector(
    (state) => state.userTemporaryProfile.UserTempData
  );

  const email = useFormFieldState(userTempData?.email);
  const firstName = useFormFieldState(userTempData?.firstName);
  const lastName = useFormFieldState(userTempData?.lastName);
  const telephone = useFormFieldState();
  const password = useFormFieldState();
  const confirmPassword = useFormFieldState();
  const isConsentCheckboxSelected = useFormFieldState();

  const requiredFormFields = [
    email,
    firstName,
    lastName,
    password,
    confirmPassword,
  ];

  const postData = {
    id: id,
    email: email.state.value,
    firstName: firstName.state.value,
    lastName: lastName.state.value,
    telephone: telephone.state.value,
    password: password.state.value,
    confirmPassword: confirmPassword.state.value,
    isConsentCheckboxSelected:
      isConsentCheckboxSelected.state.value?.length > 0 ? true : false,
    privacyNoticeText: t("forms.privacyNotice"),
    consentText: t("forms.consents.marketing"),
    purposeText: t("forms.consents.termsConditions"),
  };

  const getBackendRequestErrorMessage = useAppSelector(
    (state) =>
      state.userTemporaryProfile.backendErrors
        .getUserTemporaryProfileDataErrorMsg
  );

  const postBackendRequestErrorMessage = useAppSelector(
    (state) =>
      state.userTemporaryProfile.backendErrors.postUserTemporaryProfileErrorMsg
  );
  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    dispatch(postUserTemporaryProfileThunk(postData));
  };

  useEffect(() => {
    dispatch(getUserTemporaryProfileThunk(id));
  }, []);

  const consentCheckboxLabel = () => (
    <p className="user-portal__temporary-profile-marketing-text msds-text-body-0">
      <Trans
        components={{
          companies: (
            <a key="href-1" href={`${MILESTONE_URL()}/about-us/locations/`} />
          ),
          partners: (
            <a
              key="href-2"
              href={`${MILESTONE_URL()}/community/become-a-partner/`}
            />
          ),
          unsubscribe: <a key="href-3" href="https://pardot.milestonesys.com/preferences?epc_hash=FHmZ_64RVPy5bNsGooMMwzaJbwHXRSUV_Qu1b1rElEo" />,
        }}
      >
        {t("forms.consents.marketingRich")}
      </Trans>
    </p>
  );

  const consentCheckboxData = [
    {
      id: "consent",
      label: consentCheckboxLabel(),
      value: "true",
    },
  ];

  return (
    <>
      <div className="msds-input msds-input--error">
        <p className="msds-input__info-message">
          {getBackendRequestErrorMessage}
          {postBackendRequestErrorMessage}
        </p>
      </div>
      <Form
        cssClasses="user-portal__user-temporary-profile-form"
        handleSubmit={handleSubmitForm}
        requiredFields={requiredFormFields}
      >
        <p className="msds-text-body-3 msds-text-gray-10">
          <Trans i18nKey={t("pages.userTemporaryProfile.purposeTextRich")}>
            By submitting this form you accept to be contacted by Milestone or a
            Milestone Partner. You may at any time withdraw your consent{" "}
            <a href={`${MILESTONE_URL()}/privacy-policy/`}>here.</a>
          </Trans>
        </p>
        <Input
          id="user-temporary-profile-form__e2e-first-name-input"
          type="text"
          placeholder={t("forms.labels.firstName", {
            required: "*",
          })}
          label={t("forms.labels.firstName", { required: "*" })}
          initialValue={userTempData?.firstName}
          initialMessage={{
            validation: "Please enter your first name.",
          }}
          validation={ValidationTypes.HasValue}
          makeInvalid={firstName.state.makeInvalid}
          handleDataChange={(newValue, isValid) =>
            firstName.setState({
              value: newValue,
              isValid: isValid,
            })
          }
        />
        <Input
          id="user-temporary-profile-form__e2e-last-name-input"
          type="text"
          placeholder={t("forms.labels.lastName", {
            required: "*",
          })}
          label={t("forms.labels.lastName", { required: "*" })}
          initialValue={userTempData?.lastName}
          initialMessage={{
            validation: "Please enter your last name.",
          }}
          validation={ValidationTypes.HasValue}
          makeInvalid={lastName.state.makeInvalid}
          handleDataChange={(newValue, isValid) =>
            lastName.setState({
              value: newValue,
              isValid: isValid,
            })
          }
        />
        <Input
          id="user-temporary-profile-form__e2e-email-input"
          type="email"
          placeholder={t("forms.labels.email", { required: "*" })}
          label={t("forms.labels.email", { required: "*" })}
          initialValue={userTempData?.email}
          initialMessage={{
            validation:
              "Please provide a valid email address from your business or organization.",
          }}
          validation={ValidationTypes.IsEmail}
          makeInvalid={email.state.makeInvalid}
          handleDataChange={(newValue, isValid) => {
            email.setState({
              value: newValue,
              isValid: isValid,
            });
          }}
        />
        <Input
          id="user-temporary-profile-form__e2e-telephone-input"
          type="text"
          placeholder={t("forms.labels.telephone")}
          label={t("forms.labels.telephone", { required: "" })}
          initialMessage={{
            validation: "Please enter a valid telephone number..",
          }}
          validation={ValidationTypes.IsNumber}
          makeInvalid={telephone.state.makeInvalid}
          handleDataChange={(newValue, isValid) => {
            telephone.setState({
              value: newValue,
              isValid: isValid,
            });
          }}
        />
        <Input
          id="user-temporary-profile-form__e2e-password-input"
          type="password"
          placeholder={t("forms.labels.password", {
            required: "*",
          })}
          label={t("forms.labels.password", { required: "*" })}
          initialMessage={{
            validation:
              "Please enter a password. Password must be 8 characters or more, contain upper- and lowercase letters, at least 1 number and 1 special character.",
          }}
          validation={ValidationTypes.IsPassword}
          makeInvalid={password.state.makeInvalid}
          handleDataChange={(newValue, isValid) =>
            password.setState({
              value: newValue,
              isValid: isValid,
            })
          }
        />
        <Input
          id="user-temporary-profile-form__e2e-confirm-password-input"
          type="password"
          placeholder={t("forms.labels.confirmPassword", {
            required: "*",
          })}
          label={t("forms.labels.confirmPassword", {
            required: "*",
          })}
          initialMessage={{
            validation: "Password does not match.",
          }}
          validation={ValidationTypes.IsConfirmPassword}
          makeInvalid={confirmPassword.state.makeInvalid}
          data={{ password: password.state.value }}
          handleDataChange={(newValue, isValid) =>
            confirmPassword.setState({
              value: newValue,
              isValid: isValid,
            })
          }
        />
        <p className="msds-text-body-0">
          <Trans
            components={{
              anchor: <a href={`${MILESTONE_URL()}/privacy-policy/`} />,
            }}
          >
            {t("forms.privacyNoticeRich")}
          </Trans>
        </p>
        <CheckBoxes
          initialSelected={isConsentCheckboxSelected.state.value as string[]}
          checkboxes={consentCheckboxData}
          handleChangeData={(newSelected, isValid) =>
            isConsentCheckboxSelected.setState({
              value: newSelected,
              isValid: isValid,
            })
          }
        />
        <FormButton
          id="user-temporary-profile-form__e2e-save-button"
          label={t("buttons.save")}
          size={ButtonSize.Large}
          styling={ButtonStyles.Secondary}
        />
      </Form>
    </>
  );
};

export default UserTemporaryProfileForm;
