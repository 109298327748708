import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse, AxiosError } from "axios";
import { postLicenseClaim } from "lib/api";

export const postLicenseClaimThunk = createAsyncThunk<
    AxiosResponse,
    { slc: string; token: string },
    { rejectValue: AxiosError<any> }
>("licenseClaim/postLicenseClaim", async (payload, { rejectWithValue }) => {
    try {
        return await postLicenseClaim(payload);
    } catch (err) {
        let error: AxiosError = err;
        return rejectWithValue(error);
    }
});
