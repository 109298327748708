import { useEffect } from "react";
import UserDetailsForm from "../../components/UserDetailsForm/UserDetailsForm";
import { useParams, useNavigate } from "react-router-dom";
import { decrypt } from "lib/helpers";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import LeftSideColumn from "components/LeftSideColumn/LeftSideColumn";
import RightSideColumn from "components/RightSideColumn/RightSideColumn";
import Button from "components/Button/Button";
import { ButtonSize, ButtonStyles, Status } from "../../lib/enums";
import { resetUpdateExistingUserDataStatus } from "../../redux/api/userDetails/userDetailsSlice";
import { resetCreateUserTemporaryProfileStatus } from "../../redux/api/userTemporaryProfile/userTemporaryProfileSlice";
import FormConfirmation from "components/FormConfirmation/FormConfirmation";
import UnauthorizedBanner from "components/UnauthorizedBanner/UnauthorizedBanner";
import { useTranslation } from "react-i18next";
import { getLanguageUrlParam } from "localization/helpers";

function UserDetailsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { uId } = useParams();
  let userId = null;
  if (uId) {
    userId = decrypt(uId, "CDS:User-List-Details__User-Id", true);
  }

  const languageUrlParam = getLanguageUrlParam();

  const userData = useAppSelector(
    (state) => state.userDetails.companyUsersById[userId]
  );

  const allRoles = useAppSelector((state) => state.userRoles.allRoles);

  const createUserTemporaryProfileStatus = useAppSelector(
    (state) => state.userTemporaryProfile.createUserTemporaryProfileStatus
  );

  const myDetails = useAppSelector((state) => state.userDetails.myDetails);

  const isAdmin = useAppSelector((state) => state.user.isAdmin);

  const description_success = (
    <>
      <p className="msds-text-gray-10 msds-text-body-1">
        {t("pages.userDetails.descriptionSuccess.message1")}
      </p>
      <p className="msds-text-gray-10 msds-text-body-1">
        {t("pages.userDetails.descriptionSuccess.bestRegards")}
        <br />
        {t("pages.userDetails.descriptionSuccess.milestoneTeam")}
      </p>
    </>
  );

  const adminAddTemporaryUser = (
    <div className="container msds-block-padding-medium">
      <div className="row">
        <div className="col-12 text-center">
          <h1 className="msds-text-header-1 mt-0">
            {t("pages.userDetails.title")}
          </h1>
          <p className="msds-text-body-0">
            {myDetails?.isMilestoneEmployee
                ? t("pages.userDetails.descriptionMilestoneEmployee")
                : t("pages.userDetails.description")}
          </p>
        </div>

        <div className="col-12 mt-6">
          {isAdmin ? (
            <UserDetailsForm
              userId={userId}
              userData={userData}
              allRoles={allRoles}
            />
          ) : (
            <UnauthorizedBanner />
          )}
        </div>
      </div>
    </div>
  );

  const adminAddTemporaryUserSuccess = (
    <>
      <div className="container-fluid flex-grow-1">
        <div className="user-portal__split-container row flex-column flex-lg-row">
          <LeftSideColumn>
            <div className="msds-text-gray-10 msds-text-header-2">
              {t("pages.userDetails.titleSuccess")}
            </div>
            <div className="breaker msds-bg-clear-blue" />
            {description_success}
            <div className="user-portal__create-login-form--success">
              <Button
                label={t("buttons.backToList")}
                onClickHandler={() => {
                  navigate(`${languageUrlParam}/user-list`);
                }}
                size={ButtonSize.Medium}
                styling={ButtonStyles.Secondary}
              />
            </div>
          </LeftSideColumn>
          <RightSideColumn />
        </div>
      </div>
    </>
  );

  useEffect(() => {
    // Below is a function that gets triggered whenever this component is unmounted
    return () => {
      dispatch(resetCreateUserTemporaryProfileStatus());
      dispatch(resetUpdateExistingUserDataStatus());
    };
  }, []);

  return (
    <>
      {createUserTemporaryProfileStatus === Status.Success ? (
        <FormConfirmation content={adminAddTemporaryUserSuccess} />
      ) : (
        adminAddTemporaryUser
      )}
    </>
  );
}

export default UserDetailsPage;
