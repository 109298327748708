import { NavLink } from "react-router-dom";
import "@milestone-sys/web-design-system/main.css";
import "./navigation-footer.scss";
import maketheworldsee from "./maketheworldsee.svg";

function NavigationFooter() {
    const currentYear = new Date().getFullYear();

    return (
        <div className="container-fluid navigation-footer mt-auto">
            <div className="row">
                <div className="col-12 navigation-footer__body px-4 py-8">
                    <img src={maketheworldsee} className="mb-6" />
                    <div className="navigation-footer__content-wrapper">
                        <NavLink
                            className="navigation-footer__content msds-link--secondary"
                            to="/cookie-policy"
                        >
                            Cookie Policy
                        </NavLink>
                        <div className="navigation-footer__content">
                            Copyright © {currentYear} Milestone systems A/S. All
                            rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavigationFooter;
