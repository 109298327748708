import React from "react";

const MainNavigationDesktopLinks = () => {
    return (
        <div className="show-desktop hidefirst header-links">
            {/* <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.milestonesys.com/community/find-a-milestone-partner/"
            >
                Where to Buy
            </a>
            <span>|</span>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.milestonesys.com/about-us/"
            >
                About Us
            </a>
            <span>|</span>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.milestonesys.com/about-us/career/"
            >
                Career
            </a>
            <span>|</span> */}
        </div>
    );
};

export default MainNavigationDesktopLinks;
