import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendSupportEmail } from "lib/api";
import { SupportEmailType } from "lib/types";
import { AxiosError, AxiosResponse } from "axios";
import { Status } from "lib/enums";

export const sendSupportEmailThunk = createAsyncThunk<
  AxiosResponse<any>,
  SupportEmailType,
  { rejectValue: string }
>("supportEmai/send", async (supportEmailData, { rejectWithValue }) => {
  try {
    return await sendSupportEmail(supportEmailData);
  } catch (err) {
    let error: AxiosError<string> = err;
    return rejectWithValue(error.response.data);
  }
});

type SupportEmailState = {
  sendSupportEmailStatus: Status;
  sendSupportEmailBackendErrorMsg: string;
};

const initialState: SupportEmailState = {
  sendSupportEmailStatus: Status.Idle,
  sendSupportEmailBackendErrorMsg: "",
};

const supportEmailSlice = createSlice({
  name: "supportEmail",
  initialState,
  reducers: {
    resetSendSupportEmailStatus: (state) => {
      state.sendSupportEmailStatus = Status.Idle;
      state.sendSupportEmailBackendErrorMsg = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendSupportEmailThunk.pending, (state) => {
        state.sendSupportEmailStatus = Status.Pending;
      })
      .addCase(sendSupportEmailThunk.rejected, (state, action) => {
        state.sendSupportEmailStatus = Status.Rejected;
        state.sendSupportEmailBackendErrorMsg = action.payload;
      })
      .addCase(sendSupportEmailThunk.fulfilled, (state) => {
        state.sendSupportEmailStatus = Status.Success;
        state.sendSupportEmailBackendErrorMsg = "";
      });
  },
});

export default supportEmailSlice.reducer;

export const { resetSendSupportEmailStatus } = supportEmailSlice.actions;
