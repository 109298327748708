import { createSlice } from "@reduxjs/toolkit";
import { Status } from "lib/enums";
import { UpdateSubscriptionType } from "lib/types";
import {
  getMySubscriptionThunk as getMySubscription,
  updateMySubscriptionThunk as updateMySubscription,
} from "./actions";

type SubscriptionState = {
  isSubscribed: boolean;
  getMySubscriptionStatus: Status;
  updateMySubscriptionStatus: Status;
  backendErrorMessages: {
    getMySubscriptionErrorMsg: string;
    updateMySubscriptionErrorMsg: string;
  };
};

const initialState: SubscriptionState = {
  isSubscribed: null,
  getMySubscriptionStatus: Status.Idle,
  updateMySubscriptionStatus: Status.Idle,
  backendErrorMessages: {
    getMySubscriptionErrorMsg: "",
    updateMySubscriptionErrorMsg: "",
  },
};

const subScriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    resetUpdateSubscriptionStatus: (state) => {
      state.updateMySubscriptionStatus = Status.Idle;
      state.backendErrorMessages.updateMySubscriptionErrorMsg = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMySubscription.pending, (state) => {
        state.getMySubscriptionStatus = Status.Pending;
      })
      .addCase(getMySubscription.rejected, (state, action) => {
        state.getMySubscriptionStatus = Status.Rejected;
        state.backendErrorMessages.getMySubscriptionErrorMsg = action.payload;
      })
      .addCase(getMySubscription.fulfilled, (state, action) => {
        state.getMySubscriptionStatus = Status.Success;
        state.backendErrorMessages.getMySubscriptionErrorMsg = "";
        state.isSubscribed = action.payload.subscribed;
      })
      .addCase(updateMySubscription.pending, (state) => {
        state.updateMySubscriptionStatus = Status.Pending;
      })
      .addCase(updateMySubscription.rejected, (state) => {
        state.updateMySubscriptionStatus = Status.Rejected;
        state.backendErrorMessages.updateMySubscriptionErrorMsg =
          "My Details subscription update : An error occurred while processing your request.";
      })
      .addCase(updateMySubscription.fulfilled, (state, action) => {
        state.updateMySubscriptionStatus = Status.Success;
        state.isSubscribed = action.meta.arg.subscribed;
        state.backendErrorMessages.updateMySubscriptionErrorMsg = "";
      });
  },
});

export default subScriptionSlice.reducer;

export const { resetUpdateSubscriptionStatus } = subScriptionSlice.actions;
