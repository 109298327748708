import sprites from "@milestone-sys/web-design-system/msds-spritemap.svg";
import { selectIsAuthenticated } from "redux/user/userSlice";
import { MainNavigationProps } from "./MainNavigation";
import { useAppSelector } from "redux/hooks";

const MainNavigationMobile = (props: MainNavigationProps) => {
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    return (
        <>
            <button
                className="burger-menu-link"
                type="button"
                data-target="#MainMenu"
            >
                <div className="ms-icon">
                    <svg>
                        <use xlinkHref={`${sprites}#menu`}></use>
                    </svg>
                </div>
            </button>

            <button className="account-menu-link" type="button">
                <div className="ms-icon">
                    <svg>
                        <use xlinkHref={`${sprites}#profile`}></use>
                    </svg>
                </div>
            </button>
            <div className="signin-menu-mobil show-mobile">
                <div className="navigation-overlay">
                    <ul className="nav">
                        <li>
                            <span className="menu-button-with-dropdown--signin-menu-mobile" onClick={props.onLoginButtonClick}>
                                {isAuthenticated ? 'Log out' : 'Log in'}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default MainNavigationMobile;
