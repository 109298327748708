import MyDetailsForm from "../../components/MyDetailsForm/MyDetailsForm";
import MyDetailsSubscriptionSection from "../../components/MyDetailsSubscriptionSection/MyDetailsSubscriptionSection";
import MyDetailsResetPasswordForm from "../../components/MyDetailsResetPasswordForm/MyDetailsResetPasswordForm";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";
import { getConfigurationListsThunk } from "redux/configurationLists/actions";

const MyDetailsPage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getConfigurationListsThunk("PreferredLanguages"));
  }, []);

  const myDetails = useAppSelector((state) => state.userDetails.myDetails);  
  return (
    <div className="container msds-block-padding-medium">
      <h1 className="msds-text-header-1 mt-0 mb-10">
        {t("pages.myDetails.title")}
      </h1>
      <div className="msds-text-lead mb-4">
        {myDetails?.firstName && (myDetails.firstName + ' ')}
        {myDetails?.lastName && myDetails.lastName}       
      </div>
      <h2 className="msds-text-header-2 mt-0 mb-3">{myDetails?.companyName}</h2>
      <div className="msds-text-body-2-bold mb-6">
        {myDetails?.partnerClassification}
      </div>
      <MyDetailsForm />
      <MyDetailsSubscriptionSection />
      <MyDetailsResetPasswordForm />
    </div>
  );
};

export default MyDetailsPage;
