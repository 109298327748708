import sprites from "@milestone-sys/web-design-system/msds-spritemap.svg";
import { languageKeysToNames } from "localization/helpers";
import { useTranslation } from "react-i18next";
import { MainNavigationProps } from "./MainNavigation";
import { useAppSelector } from "redux/hooks";
import { selectIsAuthenticated } from "redux/user/userSlice";

const MainNavigationDesktop = (props: MainNavigationProps) => {
  const { i18n } = useTranslation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  return (
    <>
      <div className="menu-button-with-dropdown menu-button-with-dropdown--signin-menu show-desktop" onClick={props.onLoginButtonClick}>
        <input
          className="menu-button-with-dropdown__checkbox"
          id="state-signin-menu"
          type="checkbox"
        />
        <label
          className="menu-button-with-dropdown__button"
          htmlFor="state-signin-menu"
        >
          <span className="menu-button-with-dropdown__button-text">{isAuthenticated ? 'Log out' : 'Log In'}</span>

          <svg className="menu-button-with-dropdown__button-icon">
            <use xlinkHref={`${sprites}#profile`}></use>
          </svg>
        </label>
      </div>
      <div className="menu-button-with-dropdown menu-button-with-dropdown--language-menu show-desktop">
        <input
          className="menu-button-with-dropdown__checkbox"
          id="state-language-menu"
          type="checkbox"
        />
        {/* <label
          className="menu-button-with-dropdown__button"
          htmlFor="state-language-menu"
        >
          <svg className="menu-button-with-dropdown__button-icon">
            <use xlinkHref={`${sprites}#global`}></use>
          </svg>
          <span className="menu-button-with-dropdown__button-text">
            {languageKeysToNames[i18n.language]}
          </span>
        </label> */}
        <ul className="menu-button-with-dropdown__menu">
          <li className="menu-button-with-dropdown__menu-title">
            FULL WEBSITE TRANSLATED
          </li>
          <li className="menu-button-with-dropdown__menu-item">
            <div className="menu-button-with-dropdown__menu-item-link menu-button-with-dropdown__menu-item-link--active">
              English
            </div>
            {/* <NavLink
              className="menu-button-with-dropdown__menu-item-link menu-button-with-dropdown__menu-item-link--active"
              to="/"
            >
              English
            </NavLink> */}
          </li>
          {/* <li className="menu-button-with-dropdown__menu-item">
                        <a
                            className="menu-button-with-dropdown__menu-item-link "
                            href="/fr/"
                        >
                            français
                        </a>
                    </li>
                    <li className="menu-button-with-dropdown__menu-item">
                        <a
                            className="menu-button-with-dropdown__menu-item-link "
                            href="/es/"
                        >
                            español
                        </a>
                    </li>
                    <li className="menu-button-with-dropdown__menu-item">
                        <a
                            className="menu-button-with-dropdown__menu-item-link "
                            href="/it/"
                        >
                            italiano
                        </a>
                    </li> */}
          <li className="menu-button-with-dropdown__menu-item">
            <div className="menu-button-with-dropdown__menu-item-link">
              Deutsch
            </div>
            {/* <NavLink
              className="menu-button-with-dropdown__menu-item-link"
              to="/de"
            >
              Deutsch
            </NavLink> */}
          </li>
          {/* <li className="menu-button-with-dropdown__menu-separator"></li>
                    <li className="menu-button-with-dropdown__menu-title">
                        MOST POPULAR PAGES TRANSLATED
                    </li>
                    <li className="menu-button-with-dropdown__menu-item">
                        <a
                            className="menu-button-with-dropdown__menu-item-link "
                            href="/ru/"
                        >
                            русский
                        </a>
                    </li>
                    <li className="menu-button-with-dropdown__menu-item">
                        <a
                            className="menu-button-with-dropdown__menu-item-link "
                            href="/sv/"
                        >
                            svenska
                        </a>
                    </li>
                    <li className="menu-button-with-dropdown__menu-item">
                        <a
                            className="menu-button-with-dropdown__menu-item-link "
                            href="/no/"
                        >
                            norsk
                        </a>
                    </li>
                    <li className="menu-button-with-dropdown__menu-item">
                        <a
                            className="menu-button-with-dropdown__menu-item-link "
                            href="/da/"
                        >
                            dansk
                        </a>
                    </li>
                    <li className="menu-button-with-dropdown__menu-item">
                        <a
                            className="menu-button-with-dropdown__menu-item-link "
                            href="/fi/"
                        >
                            suomi
                        </a>
                    </li>
                    <li className="menu-button-with-dropdown__menu-item">
                        <a
                            className="menu-button-with-dropdown__menu-item-link "
                            href="/nl/"
                        >
                            Nederlands
                        </a>
                    </li>
                    <li className="menu-button-with-dropdown__menu-item">
                        <a
                            className="menu-button-with-dropdown__menu-item-link "
                            href="/ja/"
                        >
                            日本語
                        </a>
                    </li>
                    <li className="menu-button-with-dropdown__menu-item">
                        <a
                            className="menu-button-with-dropdown__menu-item-link "
                            href="/tr/"
                        >
                            Türkçe
                        </a>
                    </li>*/}
        </ul>
      </div>
      
    </>
  );
};

export default MainNavigationDesktop;
