import domHelper from "./domHelper";
import device from "./device";
import animations from "./animations";
import signInMenu from "./signInMenu";

class BurgerMenu {
    static initialize() {
        document.querySelector(".burger-menu-link").onclick =
            BurgerMenu.onClick;

        domHelper
            .toArray(document.querySelectorAll(".open-icon"))
            .forEach(function (element) {
                element.onclick = BurgerMenu.openIconOnClick;
            });
    }

    static onClick() {
        if (
            domHelper.hasClass(
                document.querySelector(".account-menu-link"),
                "close"
            )
        ) {
            signInMenu.close(BurgerMenu.open);
        } else {
            if (domHelper.hasClass(this, "close")) {
                BurgerMenu.close();
            } else {
                BurgerMenu.open();
            }
        }
    }

    static open() {
        var navigationElement = document
            .querySelector(".navigation")
            .querySelector("#MainMenu");
        document.querySelector(".navigation").classList.add("active");
        BurgerMenu.toggle(true);

        if (!domHelper.hasClass(navigationElement, "in")) {
            document.querySelector(".burger-menu-link").classList.add("close");
            animations.slideLeft(navigationElement);
        }
    }

    static close(onBurgerMenuClosed) {
        var burgerButtonElement = document.querySelector(".burger-menu-link");

        if (domHelper.hasClass(burgerButtonElement, "close")) {
            burgerButtonElement.classList.remove("close");

            animations.slideRight(
                document
                    .querySelector(".navigation")
                    .querySelector("#MainMenu"),
                function () {
                    document
                        .querySelector(".navigation")
                        .classList.remove("active");
                    BurgerMenu.toggle(false);

                    if (onBurgerMenuClosed != null) {
                        onBurgerMenuClosed();
                    }

                    BurgerMenu.closeMenuItems(
                        document
                            .querySelector(".nav.menuitems")
                            .querySelector("li"),
                        document.querySelector(".nav.menuitems"),
                        true
                    );
                }
            );
        }
    }

    static toggle(show) {
        var headerElement = document.querySelector(".header");
        var lineNavigationIsVisible = document.querySelector(".linenavigation")
            ? document.querySelector(".linenavigation").style.display ===
            "block"
            : false;

        if (!device.isLarger()) {
            var headerSection = document.querySelector("#HeaderSection");

            if (show) {
                headerSection.classList.add("smalldevices");

                if (lineNavigationIsVisible) {
                    headerElement.classList.add(
                        "smalldevices-with-linenavigation"
                    );
                }
            } else {
                headerElement.classList.remove(
                    "smalldevices-with-linenavigation"
                );
            }
        }
    }

    static closeMenuItems(parentElement, menuItemsParentElement, selfClosing) {
        var allIcons = menuItemsParentElement.querySelectorAll(".image");
        for (var i = 0; i < allIcons.length; i++) {
            allIcons[i].style.backgroundPosition = "0 0";
        }

        var menuItems = menuItemsParentElement.querySelector(
            "li.expanded .menuitems"
        );

        if (menuItems) {
            animations.slideUp(
                menuItemsParentElement.querySelector(
                    "li.expanded .menuitems.slide-down"
                ),
                function () {
                    menuItemsParentElement
                        .querySelector("li.expanded")
                        .classList.remove("expanded");

                    if (!selfClosing) {
                        parentElement.classList.add("expanded");
                    }
                }
            );
        }
    }

    static openMenuItems(parentElement, iconParentElement) {
        iconParentElement.querySelector(".image").style.backgroundPosition =
            "0 -7px";

        animations.slideDown(
            parentElement.querySelector(".menuitems"),
            function () {
                if (device.isVerySmall() || device.isSmall()) {
                    parentElement.scrollIntoView();
                }
            }
        );
    }

    static openIconOnClick() {
        var menuItemsParentElement = this.closest(".menuitems");

        if (domHelper.hasClass(this.parentNode, "expanded")) {
            BurgerMenu.closeMenuItems(
                this.parentNode,
                menuItemsParentElement,
                true
            );
        } else {
            if (
                menuItemsParentElement.querySelector(
                    "li.expanded .menuitems.slide-down"
                )
            ) {
                BurgerMenu.closeMenuItems(
                    this.parentNode,
                    menuItemsParentElement,
                    false
                );
            } else {
                this.parentNode.classList.add("expanded");
            }

            BurgerMenu.openMenuItems(this.parentNode, this);
        }
    }
}

export default BurgerMenu;
