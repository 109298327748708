import Button from "components/Button/Button";
import LeftSideColumn from "components/LeftSideColumn/LeftSideColumn";
import RightSideColumn from "components/RightSideColumn/RightSideColumn";
import UserDataForm from "components/EnterUserDataForm/EnterUserDataForm";
import { ButtonSize, ButtonStyles } from "lib/enums";
import { Trans, useTranslation } from "react-i18next";
import { useAppSelector } from "redux/hooks";
import { MILESTONE_URL, ENV } from "config";

const EnterUserData = () => {
  const { t } = useTranslation();

  const telephoneCodes = useAppSelector(
    (state) => state.configutationLists.CountryTelephoneCodes
  );

  const formDescription = (
    <>
      <p className="msds-text-gray-10 msds-text-body-1">
        <Trans i18nKey="pages.signUp.alreadyHaveAccount">
          <strong>Already have an account?</strong>
          <a href="/">Log in here</a>
        </Trans>
      </p>
      <div className="user-portal__description-text msds-text-body-1 msds-text-gray-10">
        {t("pages.signUp.purposeText")}
      </div>
    </>
  );

  const rightSideDescription = (
    <Trans
      components={{
        p: (
          <p className="user-portal__description msds-text-white msds-text-body-1" />
        ),
        anchor1: <a href={`${(ENV() === "user.milestonesys.wdev" || ENV() === "localhost") ? "https://milestonesys--msfull.sandbox.my.site.com/SupportCommunity/s/article/My-Milestone-Administrator-definition-and-user-role?language=en_US" : "https://supportcommunity.milestonesys.com/s/article/My-Milestone-Administrator-definition-and-user-role?language=en_US"}`} />,
        anchor2: (
          <a href={`${MILESTONE_URL()}/support/contact-us/sales-support/`} />
        ),
      }}
    >
      {t("pages.signUp.description")}
    </Trans>
  );

  return (
    <>
      <div className="container-fluid flex-grow-1">
        <div className="user-portal__split-container row flex-column flex-lg-row">
          <LeftSideColumn>
            <div className="msds-text-gray-10 msds-text-header-2">
              {t("pages.signUp.title")}
            </div>
            <div className="breaker msds-bg-clear-blue" />
            {formDescription}
            <UserDataForm telephoneCodes={telephoneCodes} />
          </LeftSideColumn>
          <RightSideColumn>
            <h2 className="msds-text-white msds-text-header-2 user-portal__right-container__header">
              {t("pages.signUp.rightColumnTitle")}
            </h2>
            <div className="breaker breaker--yellow msds-bg-yellow"></div>
            {rightSideDescription}
            <Button
              label={t("buttons.readMore")}
              href="https://supportcommunity.milestonesys.com/s/article/My-Milestone-Administrator-definition-and-user-role?language=en_US"
              size={ButtonSize.Medium}
              styling={ButtonStyles.Primary}
            />
          </RightSideColumn>
        </div>
      </div>
    </>
  );
};

export default EnterUserData;
