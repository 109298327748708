const UnauthorizedBanner = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="msds-block-padding-small d-flex justify-content-center">
            <div className="msds-text-header-2 mb-4">
              Sorry, but you don't have access rights to User administration.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedBanner;
