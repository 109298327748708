import classnames from "classnames/bind";
import { useEffect, useState } from "react";
import {
  MessageType,
  useValidation,
  ValidationTypes,
} from "../formHooks/useValidation";

const TextArea = ({
  id,
  label,
  placeholder,
  initialValue,
  initialMessage,
  validation,
  makeInvalid,
  maxLength,
  handleChangeData,
}: TextAreaProps) => {
  const [counter, setCounter] = useState<number>(initialValue?.length ?? 0);
  const [value, setValue] = useState<string>(initialValue ?? "");
  const { isValid, message } = useValidation(value, validation, initialMessage);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
      setCounter(initialValue.length);
    }
  }, [initialValue]);

  return (
    <div className="msds-text-area">
      <textarea
        className={classnames(
          "msds-text-area__text-input",
          (!isValid || makeInvalid) && "msds-text-area__text-input--error"
        )}
        style={{ resize: "none" }}
        id={id}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        onChange={(e) => {
          setCounter(e.target.value.length);
          setValue(e.target.value);
        }}
        onBlur={() => handleChangeData(value, isValid)}
      ></textarea>
      <label htmlFor={id} className="msds-text-area__label">
        {label}
      </label>
      <p className="msds-text-area__message">
        <span style={{ marginRight: "30px" }}>
          {isValid && message && message.info && message.info}
          {(!isValid || makeInvalid) && message && message.validation}
        </span>
        <span className="word-counter">
          <span className="word-counter__current">{counter}</span>
          <span> / {maxLength}</span>
        </span>
      </p>
    </div>
  );
};

export default TextArea;

type TextAreaProps = {
  id: string;
  label: string;
  placeholder: string;
  initialValue?: string;
  initialMessage?: MessageType;
  validation?: ValidationTypes;
  makeInvalid?: boolean;
  maxLength: number;
  handleChangeData: (newValue: string, isValid: boolean) => void;
};
