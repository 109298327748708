import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "redux/store";
import { getMyDetailsThunk } from "redux/api/userDetails/actions";
import { UserRoles } from "../../lib/enums";

type UserReducerState = {
  isAuthenticated?: boolean;
  isAdmin: boolean;
};

const initialState: UserReducerState = {
  isAuthenticated: null,
  isAdmin: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyDetailsThunk.fulfilled, (state, action) => {
      if (action.payload.userRoles.includes(UserRoles.MyMilestoneAdmin)) {
        state.isAdmin = true;
      }
    });
  },
});

export const selectIsAuthenticated = (state: RootState) =>
  state.user.isAuthenticated;

export const { setAuthenticated } = userSlice.actions;

export default userSlice.reducer;
