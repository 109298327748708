import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button/Button";
import { ButtonStyles } from "../../lib/enums";
import { postAdminDeclineUserApplicationThunk } from "redux/api/AdminDeclineUserApplication/actions";
import { useEffect } from "react";
import UnauthorizedBanner from "components/UnauthorizedBanner/UnauthorizedBanner";
import { useTranslation } from "react-i18next";
import { getLanguageUrlParam } from "localization/helpers";

const AdminDeclineUserApplicationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const languageUrlParam = getLanguageUrlParam();

  const adminDeclineUserApplicationData = useAppSelector(
    (state) =>
      state.adminDeclineUserApplication.adminDeclineUserApplicationTempData
  );

  const backendErrorMessage = useAppSelector(
    (state) =>
      state.adminDeclineUserApplication.backendErrors
        .postAdminDeclineUserApplicationErrorMsg
  );

  const isAdmin = useAppSelector((state) => state.user.isAdmin);
  const myRoles = useAppSelector(
    (state) => state.userDetails.myDetails?.userRoles
  );

  const redirectToUserList = () => {
    navigate(`${languageUrlParam}/user-list`);
  };

  useEffect(() => {
    if (myRoles != null && isAdmin) {
      dispatch(postAdminDeclineUserApplicationThunk(id));
    }
  }, [isAdmin, myRoles]);

  return (
    <div className="user-portal__user-account-decline d-flex flex-column justify-content-center msds-block-padding-medium">
      {myRoles != null && (
        <>
          {isAdmin ? (
            <>
              {!backendErrorMessage ? (
                <>
                  <p className="msds-text-gray-10 msds-text-header-2 text-center">
                    {t("pages.adminDecliNewUserApplication.message1", {
                      fullname: adminDeclineUserApplicationData.userFullName,
                    })}
                  </p>
                  <p className="msds-text-gray-10 msds-text-body-1 text-center">
                    {t("pages.adminDecliNewUserApplication.message2", {
                      fullname: adminDeclineUserApplicationData.userFullName,
                      company: adminDeclineUserApplicationData.companyName,
                    })}
                  </p>
                </>
              ) : (
                <div className="msds-input msds-input--error text-center">
                  <p className="msds-input__info-message">
                    {backendErrorMessage}
                  </p>
                </div>
              )}
              <div className="text-center">
                <Button
                  label={t("buttons.backToList")}
                  styling={ButtonStyles.Secondary}
                  onClickHandler={redirectToUserList}
                />
              </div>
            </>
          ) : (
            <UnauthorizedBanner />
          )}
        </>
      )}
    </div>
  );
};

export default AdminDeclineUserApplicationPage;
