import { createSlice } from "@reduxjs/toolkit";
import { Status } from "lib/enums";
import { UserTempDataType } from "lib/types";
import {
  getUserTemporaryProfileAdminThunk as getUserTemporaryProfileAdmin,
  postUserTemporaryProfileAdminThunk as postUserTemporaryProfileAdmin,
} from "./actions";

type AdminAddNewUserRequestState = {
  tempNewUserData: UserTempDataType;
  getUserTemporaryProfileAdminStatus: Status;
  postNewUserDataStatus: Status;
  backendErrors: {
    getNewUserTempDataErrorMsg: string;
    postNewUserDataErrorMsg: string;
  };
};

const initialState: AdminAddNewUserRequestState = {
  tempNewUserData: null,
  getUserTemporaryProfileAdminStatus: Status.Idle,
  postNewUserDataStatus: Status.Idle,
  backendErrors: {
    getNewUserTempDataErrorMsg: "",
    postNewUserDataErrorMsg: "",
  },
};

const adminAddNewUserRequestSlice = createSlice({
  name: "adminDeclineUserApplication",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserTemporaryProfileAdmin.pending, (state) => {
        state.getUserTemporaryProfileAdminStatus = Status.Pending;
      })
      .addCase(getUserTemporaryProfileAdmin.rejected, (state, action) => {
        state.getUserTemporaryProfileAdminStatus = Status.Rejected;
        state.backendErrors.getNewUserTempDataErrorMsg = action.payload;
      })
      .addCase(getUserTemporaryProfileAdmin.fulfilled, (state, action) => {
        state.getUserTemporaryProfileAdminStatus = Status.Success;
        state.backendErrors.getNewUserTempDataErrorMsg = "";
        state.tempNewUserData = action.payload;
      })
      .addCase(postUserTemporaryProfileAdmin.pending, (state) => {
        state.postNewUserDataStatus = Status.Pending;
      })
      .addCase(postUserTemporaryProfileAdmin.rejected, (state, action) => {
        state.postNewUserDataStatus = Status.Rejected;
        state.backendErrors.postNewUserDataErrorMsg = action.payload;
      })
      .addCase(postUserTemporaryProfileAdmin.fulfilled, (state, action) => {
        state.postNewUserDataStatus = Status.Success;
        state.backendErrors.postNewUserDataErrorMsg = "";
      });
  },
});

export default adminAddNewUserRequestSlice.reducer;
