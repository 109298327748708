import { useState, useEffect } from "react";
import { FormElementState } from "components/FormComponents/Form/Form";

export const useFormFieldState = (initialValue?: string | string[]) => {
  const [state, setState] = useState<FormElementState>({
    value: initialValue,
    isValid: true,
    makeInvalid: false,
  });

  useEffect(() => {
    setState({ ...state, value: initialValue });
  }, [initialValue]);

  return { state, setState };
};
