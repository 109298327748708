import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { postAdminDeclineUserApplication } from "lib/api";

export const postAdminDeclineUserApplicationThunk = createAsyncThunk<
    AxiosResponse, // response type
    string, // args type
    { rejectValue: AxiosResponse } // rejection response value type
>(
    "adminDeclineUserApplication/postAdminDeclineUserApplication",
    async (id, { rejectWithValue }) => {
        try {
            return await postAdminDeclineUserApplication(id);
        } catch (err) {
            let error: AxiosError<string> = err;
            return rejectWithValue(error.response);
        }
    }
);
