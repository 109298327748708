const Form = ({
  cssClasses,
  handleSubmit,
  children,
  requiredFields,
}: FormProps) => {
  function submitForm(e) {
    e.preventDefault();

    const isFormValid = requiredFields?.every(
      (field) => field.state.isValid && field.state.value
    );

    if (!isFormValid && requiredFields) {
      requiredFields
        .filter((field) => !field.state.value || field.state.value.length === 0)
        .forEach((field) =>
          field.setState({
            value: field.state.value,
            isValid: field.state.isValid,
            makeInvalid: true,
          })
        );
      return;
    }

    handleSubmit(e);
  }

  return (
    <form className={cssClasses && cssClasses} onSubmit={(e) => submitForm(e)}>
      {children}
    </form>
  );
};

type FormProps = {
  cssClasses?: string;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  children: React.ReactNode;
  requiredFields?: RequiredFieldsType;
};

export type RequiredFieldsType = {
  state: FormElementState;
  setState: (newState: FormElementState) => void;
}[];

export type FormElementState = {
  value: string | string[];
  isValid: boolean;
  makeInvalid?: boolean;
};

export default Form;
