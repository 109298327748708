let environment = "localhost";

const CONFIG = {
  "user.milestonesys.wdev": {
    MILESTONE_URL: "https://www.milestonesys.wdev",
    API_URL: "https://userapi.milestonesys.wdev/api",
    IDENTITY_MANAGER_CONFIG: {
      authority: "https://sso2.milestonesys.wdev",
      client_id: "milestone_user",
      redirect_uri: "https://user.milestonesys.wdev/authentication-callback",
      response_type: "code",
      scope: "openid profile email offline_access license.full",
      post_logout_redirect_uri: "https://www.milestonesys.wdev",
      accessTokenExpiringNotificationTime: 10,
      monitorSession: false,
    },
  },
  "user.milestonesys.wtst": {
    MILESTONE_URL: "https://www.milestonesys.wtst",
    API_URL: "https://userapi.milestonesys.wtst/api",
    IDENTITY_MANAGER_CONFIG: {
      authority: "https://sso2.milestonesys.wtst",
      client_id: "milestone_user",
      redirect_uri: "https://user.milestonesys.wtst/authentication-callback",
      response_type: "code",
      scope: "openid profile email offline_access license.full",
      post_logout_redirect_uri: "https://www.milestonesys.wtst",
      accessTokenExpiringNotificationTime: 10,
      monitorSession: false,
    },
  },
  "user.milestonesys.xyz": {
    MILESTONE_URL: "https://www.milestonesys.xyz",
    API_URL: "https://userapi.milestonesys.xyz/api",
    IDENTITY_MANAGER_CONFIG: {
      authority: "https://sso2.milestonesys.xyz",
      client_id: "milestone_user",
      redirect_uri: "https://user.milestonesys.xyz/authentication-callback",
      response_type: "code",
      scope: "openid profile email offline_access license.full",
      post_logout_redirect_uri: "https://www.milestonesys.xyz",
      accessTokenExpiringNotificationTime: 10,
      monitorSession: false,
    },
  },
  "user.milestonesys.com": {
    MILESTONE_URL: "https://www.milestonesys.com",
    API_URL: "https://userapi.milestonesys.com/api",
    IDENTITY_MANAGER_CONFIG: {
      authority: "https://sso2.milestonesys.com",
      client_id: "milestone_user",
      redirect_uri: "https://user.milestonesys.com/authentication-callback",
      response_type: "code",
      scope: "openid profile email offline_access license.full",
      post_logout_redirect_uri: "https://www.milestonesys.com",
      accessTokenExpiringNotificationTime: 10,
      monitorSession: false,
    },
  },
  localhost: {
    MILESTONE_URL: "https://www.milestonesys.wdev",
    API_URL: "https://userapi.milestonesys.wdev/api",
    IDENTITY_MANAGER_CONFIG: {
      authority: "https://sso2.milestonesys.wdev",
      client_id: "milestone_user",
      redirect_uri: "http://localhost:3000/authentication-callback",
      response_type: "code",
      scope: "openid profile email offline_access license.full",
      post_logout_redirect_uri: "https://www.milestonesys.wdev",
      accessTokenExpiringNotificationTime: 10,
      monitorSession: false,
    },
  },
};

export const loadConfig = () => {
  environment = window.location.host.split(":")[0];
};

export const API_URL = () => CONFIG[environment].API_URL;
export const MILESTONE_URL = () => CONFIG[environment].MILESTONE_URL;
export const ENV = () => environment;

export const IDENTITY_MANAGER_CONFIG = () =>
  CONFIG[environment].IDENTITY_MANAGER_CONFIG;

export const CAPTCHA_SITE_KEY = "6LeoEmsUAAAAAJFhGmiGjiPTEwlTj9ZDY-zu7HHZ";
