import { Trans, useTranslation } from "react-i18next";
import LeftSideColumn from "components/LeftSideColumn/LeftSideColumn";
import Button from "components/Button/Button";
import { ButtonSize, ButtonStyles } from "lib/enums";
import RightSideColumn from "components/RightSideColumn/RightSideColumn";
import { MILESTONE_URL } from "config";

const ContactSupportConfirmation = () => {
  const { t } = useTranslation();

  const description = (
    <>
      <p className="msds-text-gray-10 msds-text-body-1">
        {t(
          "pages.signUp.noMatch.descriptionContactSupportConfirmation.message1"
        )}
      </p>
      <p className="msds-text-gray-10 msds-text-body-1">
        {t(
          "pages.signUp.noMatch.descriptionContactSupportConfirmation.message2"
        )}
      </p>
      <p className="msds-text-gray-10 msds-text-body-1">
        <Trans
          components={{
            support: (
              <a 
                target="_blank"
                href={`${MILESTONE_URL()}/support/contact-us/sales-support/`}
              />
            ),
          }}
        >
          {t(
            "pages.signUp.noMatch.descriptionContactSupportConfirmation.message3"
          )}
        </Trans>
      </p>
    </>
  );

  const header = t("pages.signUp.noMatch.titleContactSupportConfirmation");

  return (
    <>
      <div className="container-fluid flex-grow-1">
        <div className="user-portal__split-container row flex-column flex-lg-row">
          <LeftSideColumn>
            <div className="user-portal__send-to-milestone-support-confirmation">
              <div className="msds-text-gray-10 msds-text-header-2">
                {header}
              </div>
              <div className="breaker msds-bg-clear-blue" />
              {description}
              <Button
                href={`${MILESTONE_URL()}/my-milestone/`}
                label={t("buttons.goToMyMilestone")}
                size={ButtonSize.Medium}
                styling={ButtonStyles.Secondary}
              />
            </div>
          </LeftSideColumn>
          <RightSideColumn />
        </div>
      </div>
    </>
  );
};

export default ContactSupportConfirmation;
