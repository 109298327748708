import "./loader-overlay.scss";
import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg";

function LoaderOverlay(props: LoaderOverlayProps) {
  const { isLoading } = props;
  return (
    <>
      {isLoading && (
        <div className="content-overlay">
          <div className="loader-overlay">
            <svg className="loader-overlay__msds-loader">
              <use href={`${spritemap}#loader-animated`} />
            </svg>
          </div>
        </div>
      )}
    </>
  );
}

export default LoaderOverlay;

type LoaderOverlayProps = {
  isLoading: boolean;
};
