import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import {
  getUserTemporaryProfileAdmin,
  postUserTemporaryProfileAdmin,
} from "lib/api";
import { UserTempDataType } from "lib/types";

export const getUserTemporaryProfileAdminThunk = createAsyncThunk<
  UserTempDataType, // response type
  string, // args type
  { rejectValue: string } // rejection response value type
>(
  "adminAddNewUserRequest/getUserTemporaryProfileAdmin",
  async (activationCode, { rejectWithValue }) => {
    try {
      return await getUserTemporaryProfileAdmin(activationCode);
    } catch (err) {
      let error: AxiosError<string> = err;
      return rejectWithValue(error.response.data);
    }
  }
);

export const postUserTemporaryProfileAdminThunk = createAsyncThunk<
  AxiosResponse, // response type
  UserRolesPayloadType, // args type
  { rejectValue: string } // rejection response value type
>(
  "adminAddNewUserRequest/postUserTemporaryProfileAdmin",
  async ({ activationCode, userRoles }, { rejectWithValue }) => {
    try {
      return await postUserTemporaryProfileAdmin(activationCode, userRoles);
    } catch (err) {
      let error: AxiosError<string> = err;
      return rejectWithValue(error.response.data);
    }
  }
);

type UserRolesPayloadType = {
  activationCode: string;
  userRoles: string[];
};
