import Button from "components/Button/Button";
import LeftSideColumn from "components/LeftSideColumn/LeftSideColumn";
import RightSideColumn from "components/RightSideColumn/RightSideColumn";
import UserTemporaryProfileForm from "components/UserTemporaryProfileForm/UserTemporaryProfileForm";
import FormConfirmation from "components/FormConfirmation/FormConfirmation";
import { ButtonSize, ButtonStyles, Status } from "lib/enums";
import { useAppSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";
import { MILESTONE_URL } from "config";

const UserTemporaryProfilePage = () => {
  const { t } = useTranslation();
  const postUserTemporaryProfileStatus = useAppSelector(
    (state) => state.userTemporaryProfile.postUserTemporaryProfileStatus
  );

  const compeleTemporaryAccountSuccess = (
    <div className="user-portal__user-temporary-profile-form-success">
      <Button
        href={`${MILESTONE_URL()}/my-milestone/`}
        label={t("buttons.goToLoginPage")}
        size={ButtonSize.Medium}
        styling={ButtonStyles.Secondary}
      />
    </div>
  );

  const header =
    postUserTemporaryProfileStatus === Status.Success
      ? t("pages.userTemporaryProfile.titleSuccess")
      : t("pages.userTemporaryProfile.title");

  return (
    <div className="user-portal">
      <div className="container-fluid flex-grow-1">
        <div className="user-portal__split-container row flex-column flex-lg-row">
          <LeftSideColumn>
            <div className="msds-text-gray-10 msds-text-header-2">{header}</div>
            <div className="breaker msds-bg-clear-blue" />
            {postUserTemporaryProfileStatus === Status.Success ? (
              <FormConfirmation content={compeleTemporaryAccountSuccess} />
            ) : (
              <UserTemporaryProfileForm />
            )}
          </LeftSideColumn>
          <RightSideColumn />
        </div>
      </div>
    </div>
  );
};

export default UserTemporaryProfilePage;
