import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FormButton from "../FormComponents/FormButton/FormButton";
import Button from "../Button/Button";
import { createUserTemporaryProfileThunk } from "redux/api/userTemporaryProfile/actions";
import { ButtonStyles, ButtonSize, Status } from "../../lib/enums";
import { useNavigate } from "react-router-dom";
import {
  BaseUserType,
  UpdateUserProfileDataType,
  CompanyUserType,
} from "lib/types";
import { postUserTemporaryProfileAdminThunk } from "redux/api/adminAddNewUserRequest/actions";
import { updateExistingUserDataThunk } from "redux/api/userDetails/actions";
import { useTranslation } from "react-i18next";
import { getLanguageUrlParam } from "localization/helpers";
import { useFormFieldState } from "components/FormComponents/formHooks/useFormFieldState";
import Form from "components/FormComponents/Form/Form";
import Input from "components/FormComponents/Input/Input";
import { ValidationTypes } from "components/FormComponents/formHooks/useValidation";
import CheckBoxes from "components/FormComponents/CheckBoxes/CheckBoxes";

const UserDetailsForm = ({
  userId,
  activationCode,
  userData,
  allRoles,
}: {
  userId?: string;
  activationCode?: string;
  userData: CompanyUserType;
  allRoles: string[];
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const languageUrlParam = getLanguageUrlParam();

  const userHasData = userData ? true : false;
  const isTemporaryUser = activationCode ? true : false;
  const isExistingUser = userHasData && !isTemporaryUser ? true : false;

  const addTemporaryUserbackendErrorMessage = useAppSelector(
    (state) =>
      state.userTemporaryProfile.backendErrors
        .createUserTemporaryProfileErrorMsg
  );

  const updateUserBackendErrorMessage = useAppSelector(
    (state) =>
      state.userDetails.backendErrorMessages.updateExistingUserDataErrorMsg
  );

  const updateExistingUserDataStatus = useAppSelector(
    (state) => state.userDetails.updateExistingUserDataStatus
  );

  const allRolesData = allRoles?.map((role) => {
    return { id: role.replace("", ""), label: role, value: role };
  });

  const firstName = useFormFieldState(userData?.firstName);
  const lastName = useFormFieldState(userData?.lastName);
  const email = useFormFieldState(userData?.email);
  const telephone = useFormFieldState(userData?.telephone);
  const mobile = useFormFieldState(userData?.mobile);
  const userRoles = useFormFieldState(userData?.userRoles);
  const noLongerWithCompany = useFormFieldState(userData?.noLongerWithCompany);

  const requiredFormFields = [firstName, lastName, email];

  const initialValue =
    noLongerWithCompany.state.value == "true" ? ["true"] : [];
  const noLongerWithCompanyCheckboxData = [
    {
      id: "no-longer-with-company",
      label: "No longer with our company",
      value: "true",
    },
  ];

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    if (isTemporaryUser) {
      dispatch(
        postUserTemporaryProfileAdminThunk({
          activationCode: activationCode,
          userRoles: userRoles.state.value as string[],
        })
      );
    } else {
      const currentBaseUserData: BaseUserType = {
        firstName: firstName.state.value as string,
        lastName: lastName.state.value as string,
        email: email.state.value as string,
        roles: userRoles.state.value as string[],
      };

      if (isExistingUser) {
        const updateCurrentUserData: UpdateUserProfileDataType = {
          ...currentBaseUserData,
          id: userId,
          phone: telephone.state.value as string,
          mobilePhone: mobile.state.value as string,
          noLongerWithCompany:
            noLongerWithCompany.state.value?.length > 0 &&
            noLongerWithCompany.state.value?.toString() == "true"
              ? true
              : false,
        };
        dispatch(updateExistingUserDataThunk(updateCurrentUserData));
        return;
      }
      dispatch(createUserTemporaryProfileThunk(currentBaseUserData));
    }
  };

  useEffect(() => {
    if (updateExistingUserDataStatus === Status.Success) {
      navigate(`${languageUrlParam}/user-list`);
    }
  }, [updateExistingUserDataStatus]);

  return (
    <>
      <div className="msds-input msds-input--error">
        <p className="msds-input__info-message">
          {addTemporaryUserbackendErrorMessage || updateUserBackendErrorMessage}
        </p>
      </div>
      <Form
        cssClasses="user-portal__edit-user-details-form"
        handleSubmit={handleSubmitForm}
        requiredFields={requiredFormFields}
      >
        <div className="msds-text-header-2 text-center pb-6">
          {isExistingUser
            ? t("pages.userDetails.subtitleEditUser")
            : t("pages.userDetails.subtitleAddUser")}
        </div>
        <Input
          id="user-details-profile-form__e2e-first-name-input"
          type="text"
          placeholder={t("forms.labels.firstName", {
            required: "*",
          })}
          label={t("forms.labels.firstName", { required: "*" })}
          initialValue={userData?.firstName}
          initialMessage={{
            validation: "Please enter your first name.",
          }}
          validation={ValidationTypes.HasValue}
          makeInvalid={firstName.state.makeInvalid}
          handleDataChange={(newValue, isValid) =>
            firstName.setState({
              value: newValue,
              isValid: isValid,
            })
          }
        />
        <Input
          id="user-details-profile-form__e2e-last-name-input"
          type="text"
          placeholder={t("forms.labels.lastName", {
            required: "*",
          })}
          label={t("forms.labels.lastName", { required: "*" })}
          initialValue={userData?.lastName}
          initialMessage={{
            validation: "Please enter your last name.",
          }}
          validation={ValidationTypes.HasValue}
          makeInvalid={lastName.state.makeInvalid}
          handleDataChange={(newValue, isValid) =>
            lastName.setState({
              value: newValue,
              isValid: isValid,
            })
          }
        />
        <Input
          id="user-temporary-profile-form__e2e-email-input"
          type="email"
          isDisabled={userData?.isMilestoneEmployee}
          placeholder={t("forms.labels.email", { required: "*" })}
          label={t("forms.labels.email", { required: "*" })}
          initialValue={userData?.email}
          initialMessage={{
            validation:
              "Please provide a valid email address from your business or organization.",
            info: (userData?.isMilestoneEmployee ? 
              t("pages.userDetails.milestoneEmployeeEmailNote") : 
              (isExistingUser
                ? "Please note that changes to the user's email will not take effect until the user confirms them, by clicking on the link in the email that will be sent to the new email address."
                : "")),
          }}
          validation={ValidationTypes.IsEmail}
          makeInvalid={email.state.makeInvalid}
          handleDataChange={(newValue, isValid) => {
            email.setState({
              value: newValue,
              isValid: isValid,
            });
          }}
        />
        {isExistingUser && (
          <>
            <Input
              id="user-details-profile-form__e2e-telephone-input"
              type="text"
              placeholder={t("forms.labels.telephone")}
              label={t("forms.labels.telephone", { required: "" })}
              initialValue={userData?.telephone}
              initialMessage={{
                validation: "Please enter a valid telephone number.",
              }}
              validation={ValidationTypes.IsNumber}
              makeInvalid={telephone.state.makeInvalid}
              handleDataChange={(newValue, isValid) => {
                telephone.setState({
                  value: newValue,
                  isValid: isValid,
                });
              }}
            />
            <Input
              id="user-details-profile-form__e2e-mobile-input"
              type="text"
              placeholder={t("forms.labels.mobile")}
              label={t("forms.labels.mobile", { required: "" })}
              initialValue={userData?.mobile}
              initialMessage={{
                validation: "Please enter a valid mobile number.",
              }}
              validation={ValidationTypes.IsNumber}
              makeInvalid={mobile.state.makeInvalid}
              handleDataChange={(newValue, isValid) => {
                mobile.setState({
                  value: newValue,
                  isValid: isValid,
                });
              }}
            />
          </>
        )}
        <CheckBoxes
          checkboxesHeader={t("pages.userDetails.rolesCheckboxesTitle")}
          initialSelected={userData?.userRoles}
          checkboxes={allRolesData}
          handleChangeData={(newSelected, isValid) =>
            userRoles.setState({
              value: newSelected,
              isValid: isValid,
            })
          }
          displayTwoColumns={true}
        />
        {isExistingUser && (
          <>
            <CheckBoxes
              checkboxesHeader={t(
                "pages.userDetails.noLongerWithCompanyCheckBoxesTitle"
              )}
              initialSelected={initialValue as string[]}
              checkboxes={noLongerWithCompanyCheckboxData}
              handleChangeData={(newSelected, isValid) =>
                noLongerWithCompany.setState({
                  value: newSelected,
                  isValid: isValid,
                })
              }
            />
          </>
        )}
        <p className="my-6">{t("forms.mandatoryFieldsInformation")}</p>
        <p className="mb-6">{t("forms.adminInformation")}</p>
        <p className="mb-6">{t("forms.rolesInformation")}</p>
        <div className="msds-btn-group msds-btn-group--center mt-10">
          <Button
            label={t("buttons.cancel")}
            size={ButtonSize.Large}
            styling={ButtonStyles.Secondary}
            onClickHandler={() => {
              navigate(`${languageUrlParam}/user-list`);
            }}
          />
          <FormButton
            id="user-details-profile-form__e2e-save-button"
            label={t("buttons.save")}
            size={ButtonSize.Large}
            styling={ButtonStyles.Secondary}
          />
        </div>
      </Form>
    </>
  );
};

export default UserDetailsForm;
