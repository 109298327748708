import { store } from "./redux/store";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import App from "./components/App/App";
import { AuthenticationProvider } from "components/Authentication/AuthenticationProvider";
import { loadConfig, IDENTITY_MANAGER_CONFIG } from "config";
import { Provider } from "react-redux";
import "./i18n";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-PZCTQN9",
};
TagManager.initialize(tagManagerArgs);

loadConfig()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
      <AuthenticationProvider config={IDENTITY_MANAGER_CONFIG()}>
        <App />
      </AuthenticationProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
