import { NavLink } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { selectIsAuthenticated } from "redux/user/userSlice";
import { useTranslation } from "react-i18next";
import "./secondary-navigation.scss";
import { getLanguageUrlParam } from "localization/helpers";
import { useWindowSize } from "components/Navigation/device";

const SecondaryNavigationMobile = () => {
  const { t } = useTranslation();

  const languageUrlParam = getLanguageUrlParam();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isAdmin = useAppSelector((state) => state.user.isAdmin);

  const [width] = useWindowSize();

  const desktopSize = 769;
  let isDesktop = false;
  isDesktop = (width >= desktopSize) ? true : false;

  return (
    <>
      {!isDesktop && (
        <>
          {!isAuthenticated && (
            <>
              <li>
                <NavLink to={`${languageUrlParam}/signup`} >
                  {t("navigation.tabs.createLogin")}
                </NavLink>
              </li>
              <li>
                <NavLink to={`${languageUrlParam}/reset-password`}>
                  {t("navigation.tabs.forgotPassword")}
                </NavLink>
              </li>
            </>
          )}
          {isAuthenticated && (
            <>
              <li>
                <NavLink to={`${languageUrlParam}/edit-user-details`}>
                  {t("navigation.tabs.myDetails")}
                </NavLink>
              </li>
              {isAdmin && (
                <li>
                  <NavLink to={`${languageUrlParam}/user-list`}>
                    {t("navigation.tabs.myUsers")}
                  </NavLink>
                </li>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default SecondaryNavigationMobile;
