import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse, AxiosError } from "axios";
import { CompanyVerificationResponseWithMatches } from "lib/types";
import {
  postCompanyVerification,
  updateCompanyWithDunsNumber,
  postCompanyVerificationWithoutId
} from "../../lib/api";

export const postCompanyVerificationThunk = createAsyncThunk<
  AxiosResponse<CompanyVerificationResponseWithMatches>,
  { contactId?: string },
  { rejectValue: AxiosError<any> }
>(
  "companyVerification/postCompanyVerification",
  async ({ contactId }, { rejectWithValue }) => {
    try {
      return contactId ? await postCompanyVerification(contactId) : await postCompanyVerificationWithoutId();
    } catch (err) {
      let error: AxiosError = err;
      return rejectWithValue(error);
    }
  }
);

export const updateCompanyWithDunsNumberThunk = createAsyncThunk<
  AxiosResponse<any>,
  { accountId: string; dunsNumber: string ; contactEmail: string},
  { rejectValue: AxiosError<any> }
>(
  "companyVerification/updateCompanyWithDunsNumber",
  async (payload, { rejectWithValue }) => {
    try {
      return await updateCompanyWithDunsNumber(payload);
    } catch (err) {
      let error: AxiosError = err;
      return rejectWithValue(error);
    }
  }
);
