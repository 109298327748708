import Button from "components/Button/Button";
import LeftSideColumn from "components/LeftSideColumn/LeftSideColumn";
import RightSideColumn from "components/RightSideColumn/RightSideColumn";
import { MILESTONE_URL } from "config";
import { ButtonSize, ButtonStyles } from "lib/enums";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const VerifyCompanyConfirmation = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const notNowcallbackUrl = searchParams?.get("callback"); 
  const header = t("pages.verifyCompany.titleSuccess");
  const description = t("pages.verifyCompany.descriptionSuccess");

  return (
    <>
      <LeftSideColumn>
        <div className="msds-text-gray-10 msds-text-header-2">{header}</div>
        <div className="breaker msds-bg-clear-blue" />
        {description}
        <div className="user-portal__create-login-form--success">
          { notNowcallbackUrl && (
            <Button
            href={notNowcallbackUrl}
            label={t("buttons.continue")}
            size={ButtonSize.Medium}
            styling={ButtonStyles.Secondary}
            />
          )}
          { !notNowcallbackUrl && (
            <Button
              href={`${MILESTONE_URL()}/my-milestone`}
              label={t("buttons.goToLoginPage")}
              size={ButtonSize.Medium}
              styling={ButtonStyles.Secondary}
            />
          )}
        </div>
      </LeftSideColumn>
      <RightSideColumn />
    </>
  );
};

export default VerifyCompanyConfirmation;
