import classNames from "classnames/bind";
import { FloatingButtonSize, FloatingButtonStyles } from "../../lib/enums";

const FloatingButton = ({
  id,
  label,
  href,
  size,
  styling,
  cssClass,
  eventCategory,
  eventLabel,
  onClickHandler,
}: FloatingButtonProps) => {
  return (
    <a
      id={id}
      className={classNames(      
        size ? size : FloatingButtonSize.Medium,
        styling ? styling : FloatingButtonStyles.Primary,
        cssClass ? cssClass : ""
      )}
      onClick={onClickHandler}
      href={href}      
      data-event-category={eventCategory}
      data-event-label={eventLabel}
      tabIndex={0}
    >
      {label}
    </a>
  );
};

type FloatingButtonProps = {
  id?: string;
  label: string;
  href?: string;
  size?: FloatingButtonSize;
  styling?: FloatingButtonStyles;
  cssClass?: string;
  onClickHandler?: () => void;
  eventCategory?: string;
  eventLabel?: string;
};

export default FloatingButton