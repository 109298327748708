import { createSlice } from "@reduxjs/toolkit";
import { Status } from "lib/enums";
import {
  getUserTemporaryProfileThunk as getUserTemporaryProfile,
  postUserTemporaryProfileThunk as postUserTemporaryProfile,
} from "./actions";
import { createUserTemporaryProfileThunk as createUserTemporaryProfile } from "./actions";
import { UserTempDataType } from "../../../lib/types";

type UserTemporaryProfileState = {
  createUserTemporaryProfileStatus: Status;
  getUserTemporaryProfileDataStatus: Status;
  postUserTemporaryProfileStatus: Status;
  UserTempData: UserTempDataType;
  backendErrors: {
    createUserTemporaryProfileErrorMsg: string;
    getUserTemporaryProfileDataErrorMsg: string;
    postUserTemporaryProfileErrorMsg: string;
  };
};

const initialState: UserTemporaryProfileState = {
  createUserTemporaryProfileStatus: Status.Idle,
  getUserTemporaryProfileDataStatus: Status.Idle,
  postUserTemporaryProfileStatus: Status.Idle,
  UserTempData: {} as UserTempDataType,
  backendErrors: {
    createUserTemporaryProfileErrorMsg: "",
    getUserTemporaryProfileDataErrorMsg: "",
    postUserTemporaryProfileErrorMsg: "",
  },
};

const userTemporaryProfileSlice = createSlice({
  name: "userTemporaryProfile",
  initialState,
  reducers: {
    resetCreateUserTemporaryProfileStatus: (state) => {
      state.createUserTemporaryProfileStatus = Status.Idle;
      state.backendErrors.createUserTemporaryProfileErrorMsg = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserTemporaryProfile.pending, (state) => {
        state.getUserTemporaryProfileDataStatus = Status.Pending;
      })
      .addCase(getUserTemporaryProfile.rejected, (state, action) => {
        if (typeof action.payload.data === "string") {
          state.backendErrors.getUserTemporaryProfileDataErrorMsg =
            action.payload.data;
        } else {
          state.backendErrors.getUserTemporaryProfileDataErrorMsg =
            "Sorry, it seems that an error has occurred.";
        }
        state.getUserTemporaryProfileDataStatus = Status.Rejected;
      })
      .addCase(getUserTemporaryProfile.fulfilled, (state, action) => {
        state.UserTempData = action.payload.data;
        if (state.backendErrors.getUserTemporaryProfileDataErrorMsg !== "")
          state.backendErrors.getUserTemporaryProfileDataErrorMsg = "";
        state.getUserTemporaryProfileDataStatus = Status.Success;
      })
      .addCase(postUserTemporaryProfile.pending, (state) => {
        state.postUserTemporaryProfileStatus = Status.Pending;
      })
      .addCase(postUserTemporaryProfile.rejected, (state, action) => {
        if (typeof action.payload === "string") {
          state.backendErrors.getUserTemporaryProfileDataErrorMsg =
            action.payload;
        } else {
          state.backendErrors.getUserTemporaryProfileDataErrorMsg =
            "Sorry, it seems that an error has occurred.";
        }
        state.postUserTemporaryProfileStatus = Status.Rejected;
      })
      .addCase(postUserTemporaryProfile.fulfilled, (state) => {
        if (state.backendErrors.postUserTemporaryProfileErrorMsg !== "")
          state.backendErrors.postUserTemporaryProfileErrorMsg = "";
        state.postUserTemporaryProfileStatus = Status.Success;
      })
      .addCase(createUserTemporaryProfile.pending, (state) => {
        state.createUserTemporaryProfileStatus = Status.Pending;
      })
      .addCase(createUserTemporaryProfile.rejected, (state, action) => {
        state.createUserTemporaryProfileStatus = Status.Rejected;
        state.backendErrors.createUserTemporaryProfileErrorMsg = action.payload;
      })
      .addCase(createUserTemporaryProfile.fulfilled, (state, action) => {
        state.createUserTemporaryProfileStatus = Status.Success;
        state.backendErrors.createUserTemporaryProfileErrorMsg = "";
      });
  },
});

export default userTemporaryProfileSlice.reducer;

export const { resetCreateUserTemporaryProfileStatus } =
  userTemporaryProfileSlice.actions;
