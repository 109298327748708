import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg";
import i18next from "i18next";

const t = i18next.t;

export const userListColumns = [
  
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: () => t("pages.myUsers.table.columnLabels.userName"),
    accessor: "userName",
  },
  {
    Header: () => t("pages.myUsers.table.columnLabels.firstName"),
    accessor: "firstName",
  },
  {
    Header: () => t("pages.myUsers.table.columnLabels.lastName"),
    accessor: "lastName",
  },
  {
    Header: () => t("pages.myUsers.table.columnLabels.phone"),
    accessor: "phone",
  },
  {
    Header: () => t("pages.myUsers.table.columnLabels.email"),
    accessor: "email",
  },
  {
    Header: () => t("pages.myUsers.table.columnLabels.date"),
    accessor: "createdDate",    
    sortType: (date1: { values: { createdDate: string; }; }, date2: { values: { createdDate: string; }; }) => {      
      const reversedDate1 = date1.values.createdDate.split("/").reverse().join("/");
      const reversedDate2 = date2.values.createdDate.split("/").reverse().join("/");     
      return new Date(reversedDate1).valueOf() - new Date(reversedDate2).valueOf();
    }
  },
];
