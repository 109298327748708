import LeftSideColumn from "components/LeftSideColumn/LeftSideColumn";
import RightSideColumn from "components/RightSideColumn/RightSideColumn";
import Button from "components/Button/Button";
import { ButtonStyles, Status } from "../../lib/enums";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useParams } from "react-router-dom";
import { activateUserAccountThunk } from "redux/userActions/actions";
import { useTranslation } from "react-i18next";
import { MILESTONE_URL } from "config";

const UserAccountActivationPage = () => {
  const { t } = useTranslation();
  const { activationCode } = useParams();
  const dispatch = useAppDispatch();

  const activateUserAccountStatus = useAppSelector(
    (state) => state.userActions.activateUserAccountStatus
  );

  const backendErrorMessage = useAppSelector(
    (state) => state.userActions.backendErrors.activateUserAccountErrorMsg
  );

  const handleActivateAccountClick = () => {
    dispatch(activateUserAccountThunk(activationCode));
  };

  const header =
    activateUserAccountStatus === Status.Success
      ? t("pages.userAccountActivation.titleSuccess")
      : t("pages.userAccountActivation.title");

  const description =
    activateUserAccountStatus === Status.Success ? (
      <p className="msds-text-gray-10 msds-text-body-1">
        {t("pages.userAccountActivation.descriptionSuccess")}
      </p>
    ) : (
      <>
        <p className="msds-text-gray-10 msds-text-body-1">
          {t("pages.userAccountActivation.description.message1")}
        </p>
        <p className="msds-text-gray-10 msds-text-body-1">
          {t("pages.userAccountActivation.description.message2")}
        </p>
      </>
    );

  return (
    <>
      <div className="container-fluid flex-grow-1">
        <div className="user-portal__split-container row flex-column flex-lg-row">
          <LeftSideColumn>
            <div className="msds-text-gray-10 msds-text-header-2">{header}</div>
            <div className="breaker msds-bg-clear-blue" />
            <div className="user-portal__user-account-activation">
              {description}
              <div className="msds-input msds-input--error">
                <p className="msds-input__info-message">
                  {backendErrorMessage}
                </p>
              </div>
              {(activateUserAccountStatus === Status.Success || activateUserAccountStatus === Status.Rejected) ? (
                <Button
                  label={t("buttons.goToLoginPage")}
                  styling={ButtonStyles.Secondary}
                  href={`${MILESTONE_URL()}/my-milestone`}
                />
              ) : (
                <Button
                  label={t("buttons.activateAccount")}
                  styling={ButtonStyles.Secondary}
                  onClickHandler={handleActivateAccountClick}
                />
              )}
            </div>
          </LeftSideColumn>
          <RightSideColumn />
        </div>
      </div>
    </>
  );
};

export default UserAccountActivationPage;
