import React from 'react'

export function SignupPopoverDescription(props) {
  return (
    <>
    <div className="msds-popover__item msds-text-gray-10">
        <div className="msds-popover__content">
              <div className='popoverTitle'>
                  <strong>{props.popoverTitle}</strong>
              </div>
              <div className="msds-popover__description mb-1 popoverDefinition">
                  <p>{props.popoverParagraph}</p>
                  <p>{props.popoverParagraph2}</p>
                  <p>{props.popoverParagraph3}</p>
                  <p>{props.popoverParagraph4}</p>
              </div>
         </div>
      </div>

      </>
  )
}
