import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { CompanyUserType, MyDetailsType, UpdatePasswordType } from "lib/types";
import {
  getMyDetails,
  updateMyDetailsData,
  getUserDetailsCompany,
  updateExistingUserData,
  updateMyPassword,
} from "../../../lib/api";
import { UpdateUserProfileDataType } from "lib/types";

// My Details
// --------------------------------------------------------------------------
export const getMyDetailsThunk = createAsyncThunk<
  CompanyUserType, // response type
  void, // args type
  { rejectValue: string } // rejection response value type
>("userDetails/getMyDetails", async (_, { rejectWithValue }) => {
  try {
    return await getMyDetails();
  } catch (err) {
    let error: AxiosError<string> = err;
    return rejectWithValue(error.response.data);
  }
});

export const updateMyDetailsDataThunk = createAsyncThunk<
  any, // response type
  MyDetailsType, // args type
  { rejectValue: string } // rejection response value type
>("userDetails/updateMyDetailsData", async (payload, { rejectWithValue }) => {
  try {
    return await updateMyDetailsData(payload);
  } catch (err) {
    let error: AxiosError<string> = err;
    return rejectWithValue(error.response.data);
  }
});

export const updateMyPasswordThunk = createAsyncThunk<
  any, // response type
  UpdatePasswordType, // args type
  { rejectValue: string } // rejection response value type
>("userDetails/updateMyPassword", async (payload, { rejectWithValue }) => {
  try {
    return await updateMyPassword(payload);
  } catch (err) {
    let error: AxiosError<string> = err;
    return rejectWithValue(error.response.data);
  }
});

// User Details
// --------------------------------------------------------------------------
export const getUserDetailsCompanyThunk = createAsyncThunk<
  CompanyUserType[], // response type
  void, // args type
  { rejectValue: string } // rejection response value type
>("userDetails/getUserDetailsCompany", async (_, { rejectWithValue }) => {
  try {
    return await getUserDetailsCompany();
  } catch (err) {
    let error: AxiosError<string> = err;
    return rejectWithValue(error.response.data);
  }
});

export const updateExistingUserDataThunk = createAsyncThunk<
  any, // response type
  UpdateUserProfileDataType, // args type
  { rejectValue: string } // rejection response value type
>(
  "userDetails/updateExistingUserData",
  async (payload, { rejectWithValue }) => {
    try {
      return await updateExistingUserData(payload);
    } catch (err) {
      let error: AxiosError<string> = err;
      return rejectWithValue(error.response.data);
    }
  }
);
