import { createSlice } from "@reduxjs/toolkit";
import { Status } from "lib/enums";
import { LicenseType, Nullable } from "lib/types";
import { getLicensesThunk as getLicenses } from "./actions";

export type LicensesState = {
  data: Nullable<LicenseType[]>;
  getLicensesStatus: Status;
  backendErrors: {
    getLicensesErrorMsg: string;
  };
};

const initialState: LicensesState = {
  data: null,
  getLicensesStatus: Status.Idle,
  backendErrors: {
    getLicensesErrorMsg: "string",
  },
};

const licensesSlice = createSlice({
  name: "licenses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLicenses.pending, (state, action) => {
        state.getLicensesStatus = Status.Pending;
      })
      .addCase(getLicenses.rejected, (state, action) => {
        state.getLicensesStatus = Status.Rejected;
        state.backendErrors.getLicensesErrorMsg = action.payload.response.data;
      })
      .addCase(getLicenses.fulfilled, (state, action) => {
        state.getLicensesStatus = Status.Success;
        state.data = action.payload.data;
        state.backendErrors.getLicensesErrorMsg = "";
      });
  },
});

export default licensesSlice.reducer;
