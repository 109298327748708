const en = {
  navigation: {
    tabs: {
      createLogin: "Create login",
      forgotPassword: "Forgot password",
      myDetails: "My details",
      myUsers: "My users",
      myLicenses: "My licenses",
    },
  },
  userRoles: {
    leadPortalUser: {
      roleKey: "Lead Portal User",
      roleDescription: ""
    },
    licenseLookupUser: {
      roleKey: "",
      roleDescription: ""
    },
    marketplaceAdmin: {roleKey: "", roleDescription: ""},
    mymilstoneUser: {roleKey: "MyMilestone User", roleDescription: "Allows the user to access the general Milestone portal."},
    mymilestoneAdmin: {roleKey: "MyMilestone Admin", roleDescription: "Allows the user to add/edit/revoke access from users under ‘My Users’ on the portal."},
    salesStatusUser: {roleKey: "Sale Status User", roleDescription: "Allows the user to view the purchases made on their account."},
    webshopUser: {roleKey: "WebShop User", roleDescription: "Allows the user to place orders and create quotes on Milestone Store.*"},
    webshopQuotesOnly: {roleKey: "WebShop Quotes Only", roleDescription: "Allows the user to create quotes only on Milestone Store.**"},
    licenseAdmin: {roleKey: "License Admin", roleDescription: "Allows the user to administer and allocate licenses to users within their account.*"},
    applicationTrackerUser: {roleKey: "Application Tracker User", roleDescription: "Allows the user to access the Application track briefcase which contains all the resources needed to build/verify/market their integrations."},
    projectRegistrationUser: {roleKey: "Project Registration User", roleDescription: "Allows the user to register projects."},
    licenseUser: {roleKey: "License User", roleDescription: "Allows the user to activate licenses that are registered to their account on CUDA/Software registration."},
    marketplaceEditor: {roleKey: "", roleDescription: ""},
    distributorUser: {roleKey: "Distributor User", roleDescription: "Allows the user to access the distributor portal."},
    distributerManager: {roleKey: "Distributor Manager", roleDescription: "Allows the user to access targets and backend information on their account."},
    resellerUser: {roleKey: "Reseller User", roleDescription: "Allows the user to access the reseller portal."},
    hardwareTrackerUser: {roleKey: "Hardware Tracker User", roleDescription: "Allows the user to access the Hardware track briefcase which contains all the resources needed to build/verify/market their integrations."},    
    deviceTrackerUser: {roleKey: "Device Tracker User", roleDescription: "Allows the user to access the Device track briefcase which contains all the resources needed to build/verify/market their integrations."},
    systemBuilderTrackerUser: {roleKey: "System Builder Tracker User", roleDescription: "Allows the user to access the System Builder track briefcase which contains all the resources needed to build/verify/market their integrations."},
  },
  pages: {
    signUp: {
      title: "Create a Milestone account",
      alreadyHaveAccount: "<0>Already have an account?</0> <1>Log in here</1>",
      distributorResellerAccount: "<0>Are you a distributor or re-seller?</0> <1>Please click this link for the right onboarding</1>",
      subtitleCompanyDataStep:
        "Company information: Please fill in information about your company, click NEXT and select your company in the next step.",
      rightColumnTitle: "Getting access to My Milestone",
      description:
        "<p>My Milestone is a portal for our customers and partners. Access to the content in it (tools and services) varies, depending on which type of customer or partner you are.​</p> <p>​If you are already a Milestone customer or partner, please contact your My Milestone account administrator to get access to your company’s account.​</p> <p>If you are not sure who your My Milestone admin is, check our <anchor1>Knowledge Base</anchor1> or <anchor2>contact Sales Support team</anchor2>.</p>",
      purposeText:
        "Please tell us about yourself and create a password for your My Milestone account.",
      confirmation: {
        titleCompanyDetected:
          "Looks like {{company}} already has a My Milestone account",
        titleNoCompanyDetected: "Activate your account",
        descriptionCompanydetected: {
          message1:
            "To get access to the relevant tools and resources, you need to be added as a user of {{company}}'s account. Your company's admin must approve you as a user, so we have sent a request to them via email.",
          message2:
            "Once the admin has approved you as a user, you will receive an email from us and can start using My Milestone.",
        },
        descriptionNoCompanyDetected: {
          message1:
            "An email containing an activation link has been sent to your email address. Please click on the link to activate your account. If you have not received the email, please check your Spam folder.​",
          message2:
            "Once you have activated your account, you can start using My Milestone.",
        },
      },
      selectCompany: {
        title: "Please select your company",
        description: {
          message1:
            "More than one company with the name you submitted was found in our system. Please pick your company from the list below and click NEXT.",
          message2:
            "If you cannot find your company on the list, please check the spelling and try again. Or contact <supportLink>Milestone Sales Support</supportLink>",
        },
        oneCompanyFoundMessage: "Select your company and click NEXT.​",
        foundCompanies: "{{count}} COMPANIES FOUND",
        noCompanySelected: "Please select a company.",
      },
      noMatch: {
        title: "No company matches",
        titleContactSupportConfirmation: "We will get back to you shortly",
        description: {
          message1:
            "Please check that the information you have entered is correct.",
        },
        descriptionContactSupportConfirmation: {
          message1:
            "Thank you! We will process your company information and get back to you within 1-2 business days.",
          message2: "While we process your company information, you can log in My Milestone as usual.",
          message3:
            "If you have any questions, please contact <support>Milestone Sales Support</support>.",
        },
        labels: {
          email: "Email",
          firstName: "First Name",
          lastName: "Last Name",
          telephone: "Phone",
          jobTitle: "Job Title",
          company: "Company name",
          address: "Address",
          zipCode: "Zip/Postal Code",
          city: "City/Town",
          country: "Country",
          state: "State",
          website: "Website",
          supportMessage: "Additional company information, e.g. Company website, DUNS number, Tax ID, Company ID etc.",
          contactCountry: "Country"
        },
        supportSendMessageSuccess: "Your email has been sent to support.",
        companyInformation: "Company Information",
        contactInformation: "Contact Information",
        message1:
          "Go back to edit the information or add additional information in the form below that will help us validate your company information. We will get back to you as soon as we have resolved the issue.",
        message2:
          "Please enter additional company information to help Milestone Support validate your company.",
      },
      overviewAndSubmission: {
        title: "Please review your information",
        description: {
          message1: "Please take a moment to review the information below.",
          message2:
            "If everything looks good, please proceed to sign up. Or go back and edit your information.",
        },
      },
      submissionErrorNoAdmin: {
        title: "Sorry, we cannot activate your account",
        description: {
          message1: "Thank you for your interest in creating a My Milestone account. Unfortunately, there is no admin connected to {{selectedCompany}} so we cannot activate your account.",
          message2: "We will contact you shortly to sort out the issue.",
          message3: "Sorry for the inconvenience.",
        },
      },
      submissionError: {
        title: "Sorry, we cannot activate your account",
        description: {
          message1: "Thank you for your interest in creating a My Milestone account. Unfortunately, an error has occurred and we could not activate your account.",
          message2: "Please <supportLink>contact support</supportLink>.",
          message3: "Reference: {{timestamp}}",
          message4: "Sorry for the inconvenience.",
        },
      },
      accountActivationInProcessError: {
        title: "Account activation in progress",
        description: {
          message1: "There is already a user or an active user request for this email."
        }
      },
      companyDescription: {
        whatIsCompany: "What do we define as a company?",
        title: "Company Definition",
        definition: {
          definition1:
            "The company name must be the legal entity name of your organization. The company name entered cannot be DBAs (Doing Business As), fictitious businesses, trade names, or branches for enrollment as a company/organization.",
          definition2:
            "Example: Company: International Business Machines Corp. (IBM should NOT be entered.)",
          definition3:
            "Please note that Milestone will not be trading with Private Individuals.",
        },
      },
      DUNSDescription: {
        whatIsDUNS: "What is a D-U-N-S number?",
        title: "D-U-N-S Number",
        definition: {
          definition1:
            "The D-U-N-S Number is a unique nine-digit number that identifies business entities on a location-specific basis. Assigned and maintained by Dun & Bradstreet (D&B), the D-U-N-S Number is widely used as a standard business identifier.",
          definition2:
            "If you know what your D-U-N-S Number is, please enter it. This will help us to check the identity and legal entity status of your organization as part of our enrollment verification process for using Milestone software or associated services and devices. ",
          definition3:
            "If you do not know your D-U-N-S number, please leave the field blank.",
        },
      },
      companyEmailGuide: "E.g. info@company-name.com",
    },
    resetPassword: {
      title: "Create or reset your password",
      description:
        "If you have forgotten your password please enter your user name below and click submit. Please note that your user name may be your email address.",
      descriptionAfterSubmission:
        "If your email address is found in our database you will receive an email shortly with a link to reset your password. Please follow the instructions in the email.",
      errorMessageBottom: "Create a login",
    },
    myDetails: {
      title: "My Details",
      stayInformedSection: {
        title: "Stay informed",
        subscribedSuccess: "Welcome, you are successfully opted-in.",
        subscribedMessageRich:
          "You are currently opted-in to receive marketing communication by emails and calls from <1>Milestone Group Companies</1>. You can unsubscribe at any time.",
        unsubscribedSuccess: "You have successfully unsubscribed.",
        unsubscribedMessageRich:
          "Do you want to hear from us? Please, opt-in if you like to receive marketing communications by electronic mails and calls from <companies>Milestone Group Companies</companies> regarding Milestone VMS and NVR products, services and activities, together with information about <partners>Milestone Partners</partners> and their Milestone related products, services and activities. You can <unsubscribe>unsubscribe</unsubscribe> at any time",
      },
      changePasswordSection: {
        title: "Change your password",
        successMessage: "Password has been updated successfully.",
      },
    },
    myUsers: {
      title: "User administration",
      description:
        "Here you can add, edit and delete users and grant access rights to users who have a My Milestone account within your company.",
      descriptionMilestoneEmployee:
        "Here you can edit and delete users and grant access rights to users who have a My Milestone account.",
      table: {
        globalFilterLabel: "Search...",
        columnLabels: {
          userName: "Username",
          firstName: "First Name",
          lastName: "Last Name",
          phone: "Phone",
          email: "Email",
          date: "Date",
        },
        pagination: {
          page: "Page",
          goToPage: "Go to page",
          show: "Show",
        },
      },
    },
    userDetails: {
      title: "User details",
      subtitleAddUser: "Add a new user",
      subtitleEditUser: "Edit user",
      description:
        "Here you can add, edit and delete users and grant access rights to users who have a My Milestone account within your company.",
      descriptionMilestoneEmployee:
        "Here you can edit and delete users and grant access rights to users who have a My Milestone account.",
      milestoneEmployeeEmailNote:
        "Please note that it is not possible to change the email address of a Milestone employee. Reach out to your local Sales Support team for assistance.​",
      titleSuccess: "Thank you for your request",
      descriptionSuccess: {
        message1:
          "An account activation link has been sent to the new user. Once the user has activated the account, it will be visible on your admin user list.",
        bestRegards: "Best regards,",
        milestoneTeam: "Milestone Systems Team",
      },
      rolesCheckboxesTitle: "User roles",
      noLongerWithCompanyCheckBoxesTitle:
        "Select in case this user is no longe part of your company",
    },
    adminAddNewUserRequest: {
      title: "User details",
      description:
        "Here you can add user and grant access to user who have a Milestone account with your company.",
      titleSuccess: "{{firstName}} {{lastName}} is created",
      descriptionSuccess:
        "{{firstName}} {{lastName}} has now been created as a user of {{companyName}}. We have notified {{firstName}} {{lastName}} via email and they can now log in to My Milestone using {{email}}.",
    },
    adminDecliNewUserApplication: {
      message1: "User request for {{fullname}} has been declined.",
      message2:
        "{{fullname}} will not be added as a My Milestone user for {{company}}.",
    },
    createNewPassword: {
      title: "Reset your password",
      titleSuccess: "Your password was changed successfully",
    },
    notFound: {
      message: "The current url does not exist...",
    },
    userAccountActivation: {
      title: "One more step",
      titleSuccess: "Your account has been activated",
      description: {
        message1:
          'Please complete your account setup by clicking on "Activate Account" below.',
        message2:
          "Once you have activated your account, you can start using My Milestone.",
      },
      descriptionSuccess:
        "Your account is now activated and you can start using My Milestone.",
    },
    userTemporaryProfile: {
      title:
        "Fill in the form below to get access to My Milestone and activate your account",
      titleSuccess: "Your account has been created successfully",
      purposeTextRich:
        "By submitting this form you accept to be contacted by Milestone or a Milestone Partner. You may at any time withdraw your consent <1>here</1>.",
    },
    userUpdateEmail: {
      title: "Update Email Confirmation",
      description:
        "Your email has been updated successfully. Please go to <1>My Details</1> to see the changes.",
    },
    myLicenses: {
      title: "My Licenses",
      description:
        "This is an overview of the licenses that you have with Milestone Systems. Here you can see the license number, product name, product version and the status of your license.",
      table: {
        columnLabels: {
          slc: "License",
          productName: "Product/Version",
          status: "Status",
          careDate: "Care Date",
        },
      },
    },
    licenseClaim: {
      title: "Register Software License Code",
      titleSuccess: "Success",
      titleError:{
        license: "The license",
        couldNotRegister:"could not be registered."        
      },
      description:
        "Below you can register your available software license code (SLC).",
      descriptionSuccess: "You have successfully registered the software license code",
      descriptionError:
        "This is license is not valid for registering. Please check if you have already registered the license. To do this, check if the license is already present in the MY LICENSE overview link above, or button below. Or else, please check with your Milestone reseller or contact Milestone Support.",
      descriptionNoLicense: "No license available",
      register: "Register software license code",
      licenseOverview:
        "You can see an overview of your licenses in the section called MY LICENSES.",
      contactSupport:
        "If you cannot register the license and it’s not showing in the list on MY LICENSES, please contact your integrator, local Milestone reseller or",
    },
    verifyCompany: {
      title: "Your company has not been validated",
      titleSuccess: "Company data has been updated.",
      descriptionWithMatches:
        "To validate it now, please select your company below and click UPDATE. If you cannot find your company in the list, click 'My company is not on the list.'",
      notNowDescription: " Click NOT NOW if you don't want to validate your company now.",
      descriptionNoMatches:
        "Please check the information we currently have on you and your company:",
      descriptionSuccess: "Thank you! We have now updated your company data.",
      // support: "Please contact <supportLink>Milestone Sales Support</supportLink> and let our support team help you verify your company information.",
      message1:
      "If any of the information is incorrect, please let us know in the message field below.",
      message2:
      "Please add any other information that can help our support team verify your company.",
      supportMessage: "Add company information here, e.g. Company website, DUNS number, Tax ID, Company ID etc.",
    },
  },
  forms: {
    labels: {
      email: "Email {{required}}",
      username: "Username {{required}}",
      firstName: "First Name {{required}}",
      lastName: "Last Name {{required}}",
      telephone: "Phone number {{required}}",
      jobTitle: "Job Title {{required}}",
      company: "Company {{required}}",
      address: "Address {{required}}",
      zipCode: "Zip/Postal Code {{required}}",
      city: "City/Town {{required}}",
      vat: "Vat {{required}}",
      website: "Website {{required}}",
      password: "Create Password {{required}}",
      confirmPassword: "Confirm Password {{required}}",
      companyId: "Company Id {{required}}",
      mobile: "Mobile {{required}}",
      userName: "User Name {{required}}",
      oldPassword: "Old Password {{required}}",
      newPassword: "New Password {{required}}",
      confirmNewPassword: "Confirm New Password {{required}}",
      companyNumber: "D-U-N-S Number {{required}}",
      companyEmail: "Company email {{required}}",
    },
    consents: {
      marketingRich:
        "Yes, I would like to receive marketing communication by electronic mail, and calls from <companies>Milestone Group Companies</companies> regarding Milestone VMS and NVR products, services and activities, together with information about Milestone Partners and their Milestone related products, services and activities. I understand, that at any time, I can <unsubscribe>unsubscribe</unsubscribe>.",
      marketing:
        "Yes, I would like to receive marketing communication by electronic mail, and calls from Milestone Group Companies regarding Milestone VMS and NVR products, services and activities, together with information about Milestone Partners and their Milestone related products, services and activities. I understand, that at any time, I can unsubscribe.",
      termsConditionsRich:
        "Yes I agree to the <terms>Terms and Conditions</terms> regarding use of any products and/or services from Milestone Systems A/S.​",
      termsConditions:
        "Yes, I agree to the Terms & Conditions regarding use of any products and/or services from Milestone Systems A/S.",
    },
    milestoneEmployeeEmailUsernameNote:
      "Please note that it is not possible to change your email address. Reach out to your local sales support team for assistance.",
    privacyNoticeRich:
      "Milestone handles your contact data in accordance with our <anchor>Privacy Policy</anchor>. Here you will also find information on your privacy rights and our retention policy.​",
    privacyNotice:
      "Milestone handles your contact data in accordance with our Privacy Policy. Here you will also find information on your privacy rights and our retention policy.",
    mandatoryFieldsInformation: "Fields marked with a * are mandatory.",
    adminInformation:
      "* MyMilestone Admin and License Admin are only granted to the primary contact unless otherwise specified by the reseller/distributor.",
    rolesInformation:
      "** Webshop User and Webshop Quotes Only are determined by the distributor based on the contacts who should place orders and quotes and who should place quotes only on the Milestone Store.",
  },
  tables: {
    globalFilterLabel: "Search...",
    pagination: {
      page: "Page",
      goToPage: "Go to page",
      show: "Show",
    },
  },
  buttons: {
    signUp: "Sign up",
    readMore: "Read more",
    goToLoginPage: "Go to login page",
    resetPassword: "Reset password",
    save: "Save",
    unsubscribeAll: "Unsubscribe all",
    contactMe: "Yes, you can contact me",
    addNewUser: "Add new user",
    cancel: "Cancel",
    backToList: "Back to user list",
    goToFrontPage: "Go to front page",
    goToMyMilestone: "Go to My Milestone",
    back: "back",
    next: "next",
    sendToMilestone: "Send to Milestone",
    backToHomepage: "Back to homepage",
    activateAccount: "Activate account",
    update: "Update",
    notNow: "not now",
    registerLicense: "register license",
    claimLicenseSuccessGoBack: "go back to my licenses",
    close: "close",
    continue: "Continue",
  },
  dialogs: {
    termsConditionsDialog: {
      title: "Terms & Conditions for My Milestone account",
      content: `
        <first>
            To access and register a Milestone product or services (“Milestone Product”) 
            you must access your My Milestone account. If you are a new user, you must 
            either create a new My Milestone account for your company or create a new user accessing 
            a company My Milestone account already existing.
        </first>
        <second>
            If you create a My Milestone account on behalf of a company that is your
            employer or with which you are otherwise engaged as an administrator user,
            you represent and warrant that (i) you have full legal authority to bind
            the company to these terms and conditions (“Term and Conditions”) , (ii)
            you have read and understood the Terms and Conditions, and (iii) you agree
            to the Terms and Conditions on behalf of the company.
        </second>
        <third>
            Further, please be aware that as an administrator user you have access to
            the business information available on your company’s My Milestone account,
            and you are responsible for the general users you add to the My Milestone
            account. You need to make sure that such general users have the rights and
            are authorised to have access to this business information.
        </third>
        <fourth>
        If you are not an administrator user and you create a general user, you
        warrant that you have the legal authorization to do so and to bind your
        company to these Terms and Conditions and any other applicable terms as
        mentioned herein.
        </fourth>
        <fifth>
        The purpose of creating the My Milestone account is for Milestone to link
        the Milestone Product to the End-User of the Milestone Product, and for
        Milestone to be able to collect information about the End-User of the
        Milestone Product for compliance purposes such as authentication of the
        End-User as a licensee of the Milestone Product, including for the purpose
        of export control, as well as to enable Milestone to enforce license
        management and provide technical support where relevant. Milestone shall
        have the right to distribute information from the My Milestone account
        within the Milestone group for the purpose of fulfilling compliance
        obligations required by applicable laws, for research and development
        purposes, for analytical purposes, and for the purpose of marketing
        related activities if the consent to such marketing is granted by you as
        per below.
        </fifth>
        <sixth>
        At the My Milestone account you will have access to information on your
        Milestone Products, such as purchased licenses and Milestone Care. You are
        granted access to My Milestone via a username and password. Usernames and
        passwords are individual to you and must be stored in a safe place.
        Milestone reserves the right to revoke login credentials under the
        suspicion of misuse.
        </sixth>
        <seventh>
        You warrant that any information provided at the My Milestone account is
        true, complete, accurate, not misleading and up-to-date and acknowledge
        that Milestone relies upon the accuracy of the information provided. You
        undertake to promptly correct any such not correct information to ensure
        the information provided at My Milestone account is always up to date.
        </seventh>
        <eigth>
            When creating a My Milestone account and/or by using a Milestone Product
            you accept to have read and be bound by:
            <list>
                <item1>
                    These Terms and Conditions
                </item1>
                <item2>
                    The applicable <eulaLink>Milestone EULA</eulaLink> for the specific Milestone software
                    release.
                </item2>
                <item3>
                    If purchased, Care Terms and Conditions.
                </item3>
                <item4>
                    Any other terms and conditions applicable to your use of Milestone
                    products and/or service.
                </item4>
            </list>
        </eigth>
        <ninth>
            If you fail to comply with these Terms and Conditions and/or your
            Milestone Product is terminated according to the applicable Milestone
            EULA, Milestone may deactivate your My Milestone account.
        </ninth>
        <tenth>
            Milestone cares about the privacy of your personal data. Please read our
            <privacy>Privacy Policy (milestonesys.com)</privacy> which describes the types of personal data Milestone collects, the
            purposes and handling of your personal data in relation to your purchase
            of a Milestone Product.
        </tenth>
        `,
    },
  },
};

export default en;
