import {
    createSlice,
    isPending,
    isRejected,
    isFulfilled,
    isAnyOf,
} from "@reduxjs/toolkit";

const getActionName = (type: string) => {
    return type.split("/")[1];
};

export type AppState = {
    pendingActions: Array<string>;
    isLoading: boolean;
    hasErrors: boolean;
    errors: Array<string>;
};

const initialState: AppState = {
    pendingActions: [],
    isLoading: false,
    hasErrors: false,
    errors: [],
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(isPending, (state, action) => {
                state.isLoading = true;
                const actionName = getActionName(action.type);
                state.pendingActions.push(actionName);
            })
            .addMatcher(isAnyOf(isRejected, isFulfilled), (state, action) => {
                const actionName = getActionName(action.type);
                if (state.pendingActions.includes(actionName)) {
                    const index = state.pendingActions.indexOf(actionName);
                    state.pendingActions.splice(index, 1);
                }
                if (state.pendingActions.length === 0) state.isLoading = false;
            });
    },
});

export default appSlice.reducer;
