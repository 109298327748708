import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { AxiosResponse, AxiosError } from "axios";
import {
  postSignupCompanyVerification,
  postSignupDataSubmission,
  checkIfUserExists,
} from "lib/api";
import { SignupMatchedCompaniesResponse } from "lib/types";

export const postSignupCompanyVerificationThunk = createAsyncThunk<
  AxiosResponse<SignupMatchedCompaniesResponse>,
  object,
  { rejectValue: AxiosError<any> }
>("signup/verifyCompany", async (companyData, { rejectWithValue }) => {
  try {
    return await postSignupCompanyVerification(companyData);
  } catch (err) {
    let error: AxiosError = err;
    return rejectWithValue(error);
  }
});

export const postSignupDataSubmissionThunk = createAsyncThunk<
  AxiosResponse<any>,
  object,
  { rejectValue: AxiosError<any> }
>("signup/submitData", async (data, { rejectWithValue }) => {
  try {
    return await postSignupDataSubmission(data);
  } catch (err) {
    let error: AxiosError = err;
    return rejectWithValue(error);
  }
});

export const checkIfUserExistsThunk = createAsyncThunk<
  AxiosResponse<any>,
  string,
  { rejectValue: AxiosError<any> }
>("signup/checkIfUserExists", async (userName: string, { rejectWithValue }) => {
  try {
    return await checkIfUserExists(userName);
  } catch (err) {
    let error: AxiosError = err;
    return rejectWithValue(error);
  }
});
