import LeftSideColumn from "components/LeftSideColumn/LeftSideColumn";
import RightSideColumn from "components/RightSideColumn/RightSideColumn";
import ResetPasswordForm from "components/ResetPasswordForm/ResetPasswordForm";
import Button from "components/Button/Button";
import FormConfirmation from "components/FormConfirmation/FormConfirmation";
import { useTranslation } from "react-i18next";
import { ButtonStyles, ButtonSize } from "../../lib/enums";
import { useAppSelector } from "redux/hooks";
import { Status } from "lib/enums";
import { MILESTONE_URL } from "config";

const ResetPasswordPage = () => {
  const { t } = useTranslation();

  let resetPasswordStatus = useAppSelector(
    (state) => state.userActions.resetPasswordStatus
  );

  const resetPasswordSuccess = (
    <div className="user-portal__reset-password-form-success">
      <Button
        href={`${MILESTONE_URL()}/my-milestone/`}
        label={t("buttons.goToLoginPage")}
        size={ButtonSize.Medium}
        styling={ButtonStyles.Secondary}
      />
    </div>
  );

  const header = t("pages.resetPassword.title");

  const description =
    resetPasswordStatus === Status.Success
      ? t("pages.resetPassword.descriptionAfterSubmission")
      : t("pages.resetPassword.description");

  const resetPasswordFormDataLayer = {
    dataLayer: {
      event: "Form_submission",
      eventCategory: "User Portal",
      eventLabel: "Reset password Form",
    },
  };

  return (
    <>
      <div className="container-fluid flex-grow-1">
        <div className="user-portal__split-container row flex-column flex-lg-row">
          <LeftSideColumn>
            <div className="msds-text-gray-10 msds-text-header-2">{header}</div>
            <div className="breaker msds-bg-clear-blue" />
            <p className="msds-text-gray-10 msds-text-body-1">{description}</p>
            {resetPasswordStatus === Status.Success ? (
              <FormConfirmation
                gtmDataLayer={resetPasswordFormDataLayer}
                content={resetPasswordSuccess}
              />
            ) : (
              <ResetPasswordForm />
            )}
          </LeftSideColumn>
          <RightSideColumn />
        </div>
      </div>
    </>
  );
};

export default ResetPasswordPage;
