import { useContext, useEffect } from "react";
import { useAppSelector } from "redux/hooks";
import { selectIsAuthenticated } from "redux/user/userSlice";
import { AuthenticationContext } from "./AuthenticationProvider";
import { RedirectionType } from "./types";
import { redirectToIDP } from "./authenticationUtilities";

//Provides authorization as requirement to visit a page passed in props as "children" page component.
//Authorization is simple: if you are authenticated you are authorized.
//TODO User role based authorization
export const RequireAuthorization = ({ children }) => {
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const authenticationContextValue = useContext(AuthenticationContext);

    useEffect(() => {
        if (!isAuthenticated) {
            redirectToIDP(authenticationContextValue.authConfig, RedirectionType.Signin, true)
        }
    }, [authenticationContextValue.authConfig, isAuthenticated])

    if (isAuthenticated) {
        return children
    }
    else {
        return <></>
    }
}

