import LeftSideColumn from "components/LeftSideColumn/LeftSideColumn";
import RightSideColumn from "components/RightSideColumn/RightSideColumn";
import CreateNewPasswordForm from "components/CreateNewPasswordForm/CreateNewPasswordForm";
import FormConfirmation from "components/FormConfirmation/FormConfirmation";
import Button from "components/Button/Button";
import { ButtonStyles, ButtonSize } from "../../lib/enums";
import { useAppSelector } from "redux/hooks";
import { Status } from "lib/enums";
import { useTranslation } from "react-i18next";
import { MILESTONE_URL } from "config";

const CreatePasswordPage = () => {
  const { t } = useTranslation();
  const postCreatePasswordStatus = useAppSelector(
    (state) => state.userActions.postCreateNewPasswordStatus
  );

  const createNewPasswordConfirmation = (
    <div className="user-portal__create-new-password-form-success">
      <Button
        href={`${MILESTONE_URL()}/my-milestone/`}
        label={t("buttons.goToLoginPage")}
        size={ButtonSize.Medium}
        styling={ButtonStyles.Secondary}
      />
    </div>
  );

  const header =
    postCreatePasswordStatus === Status.Success
      ? t("pages.createNewPassword.titleSuccess")
      : t("pages.createNewPassword.title");

  const createNewPasswordFormDataLayer = {
    dataLayer: {
      event: "Form_submission",
      eventCategory: "User Portal",
      eventLabel: "Create New Password Form",
    },
  };

  return (
    <div className="user-portal">
      <div className="container-fluid flex-grow-1">
        <div className="user-portal__split-container row flex-column flex-lg-row">
          <LeftSideColumn>
            <div className="msds-text-gray-10 msds-text-header-2">{header}</div>
            <div className="breaker msds-bg-clear-blue" />
            {postCreatePasswordStatus === Status.Success ? (
              <FormConfirmation
                gtmDataLayer={createNewPasswordFormDataLayer}
                content={createNewPasswordConfirmation}
              />
            ) : (
              <CreateNewPasswordForm />
            )}
          </LeftSideColumn>
          <RightSideColumn></RightSideColumn>
        </div>
      </div>
    </div>
  );
};

export default CreatePasswordPage;
