import { createSlice } from "@reduxjs/toolkit";
import {
    resetPasswordThunk as resetPassword,
    createLoginThunk as createLogin,
    getCreateNewPasswordUserEmailThunk as getCreateNewPasswordUserEmail,
    postCreateNewPasswordThunk as postCreateNewPassword,
    activateUserAccountThunk as activateUserAccount,
} from "./actions";
import { MatchedCompaniesType } from "lib/types";
import { Status } from "../../lib/enums";

type UserActionsState = {
    resetPasswordStatus: Status;
    postCreateNewPasswordStatus: Status;
    getCreateNewPasswordStatus: Status;
    activateUserAccountStatus: Status;
    createNewPasswordEmail: string;
    matchedCompanies: MatchedCompaniesType | null;
    backendErrors: {
        resetPasswordErrorMsg: string;
        postCreateNewPasswordErrorMsg: string;
        getCreateNewPasswordUserEmailErrorMsg: string;
        activateUserAccountErrorMsg: string;
    };
};

const initialState: UserActionsState = {
    resetPasswordStatus: Status.Idle,
    postCreateNewPasswordStatus: Status.Idle,
    getCreateNewPasswordStatus: Status.Idle,
    activateUserAccountStatus: Status.Idle,
    createNewPasswordEmail: "",
    matchedCompanies: null,
    backendErrors: {
        resetPasswordErrorMsg: "",
        postCreateNewPasswordErrorMsg: "",
        getCreateNewPasswordUserEmailErrorMsg: "",
        activateUserAccountErrorMsg: "",
    },
};

const userActionsSlice = createSlice({
    name: "userActions",
    initialState,
    reducers: {
        resetMatchedCompanies: (state) => {
            state.matchedCompanies = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(resetPassword.pending, (state) => {
                state.resetPasswordStatus = Status.Pending;
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.backendErrors.resetPasswordErrorMsg = action.payload;
                if (typeof action.payload === "string") {
                    state.backendErrors.resetPasswordErrorMsg = action.payload;
                } else {
                    state.backendErrors.resetPasswordErrorMsg =
                        "Sorry, it seems that an error has occurred.";
                }
                state.resetPasswordStatus = Status.Rejected;
            })
            .addCase(resetPassword.fulfilled, (state) => {
                if (state.backendErrors.resetPasswordErrorMsg !== "")
                    state.backendErrors.resetPasswordErrorMsg = "";
                state.resetPasswordStatus = Status.Success;
            })
            .addCase(getCreateNewPasswordUserEmail.pending, (state) => {
                state.getCreateNewPasswordStatus = Status.Pending;
            })
            .addCase(
                getCreateNewPasswordUserEmail.rejected,
                (state, action) => {
                    state.backendErrors.getCreateNewPasswordUserEmailErrorMsg =
                        action.payload.data;
                    state.getCreateNewPasswordStatus = Status.Rejected;
                }
            )
            .addCase(
                getCreateNewPasswordUserEmail.fulfilled,
                (state, action) => {
                    state.createNewPasswordEmail = action.payload["username"];
                    if (
                        state.backendErrors
                            .getCreateNewPasswordUserEmailErrorMsg !== ""
                    )
                        state.backendErrors.getCreateNewPasswordUserEmailErrorMsg =
                            "";
                    state.getCreateNewPasswordStatus = Status.Success;
                }
            )
            .addCase(postCreateNewPassword.pending, (state) => {
                state.postCreateNewPasswordStatus = Status.Pending;
            })
            .addCase(postCreateNewPassword.rejected, (state, action) => {
                state.backendErrors.postCreateNewPasswordErrorMsg =
                    action.payload;
                state.postCreateNewPasswordStatus = Status.Rejected;
            })
            .addCase(postCreateNewPassword.fulfilled, (state) => {
                if (state.backendErrors.postCreateNewPasswordErrorMsg !== "")
                    state.backendErrors.postCreateNewPasswordErrorMsg = "";
                state.postCreateNewPasswordStatus = Status.Success;
            })
            .addCase(activateUserAccount.pending, (state) => {
                state.activateUserAccountStatus = Status.Pending;
            })
            .addCase(activateUserAccount.rejected, (state, action) => {
                state.backendErrors.activateUserAccountErrorMsg =
                    action.payload;
                state.activateUserAccountStatus = Status.Rejected;
            })
            .addCase(activateUserAccount.fulfilled, (state) => {
                if (state.backendErrors.activateUserAccountErrorMsg !== "")
                    state.backendErrors.activateUserAccountErrorMsg = "";
                state.activateUserAccountStatus = Status.Success;
            });
    },
});

export default userActionsSlice.reducer;
export const { resetMatchedCompanies } = userActionsSlice.actions;
