import { ReactNode } from "react";
import TagManager from "react-gtm-module";

const FormConfirmation = ({ gtmDataLayer, content }: FormConfirmationProps) => {
  if (gtmDataLayer) TagManager.dataLayer(gtmDataLayer);
  return <>{content}</>;
};

export default FormConfirmation;

type FormConfirmationProps = {
  content: ReactNode;
  gtmDataLayer?: GTMdataLayer;
};

type GTMdataLayer = {
  dataLayer: {
    event: string;
    eventCategory: string;
    eventLabel: string;
  };
};
