import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Button from "../../components/Button/Button";
import { ButtonStyles, ButtonSize } from "../../lib/enums";
import { getLanguageUrlParam } from "localization/helpers";

function CookiePolicyPage() {    
    const navigate = useNavigate();
    const backEventClick = () => {
        navigate(-1);
    };   
    const languageUrlParam = getLanguageUrlParam();

    // Add the Cookiebot declaration script
    useEffect(() => {       
        const script = document.createElement("script");
        script.id = "CookieDeclaration"
        script.src = "https://consent.cookiebot.com/71f1dae0-4e8b-4339-85e2-b285baec57ce/cd.js";
        script.setAttribute("data-culture", "en");
        script.async = true;
        
        const cookiebotContainer = document.getElementById("cookie-declaration-container");
        cookiebotContainer.appendChild(script);
        return () => {
            cookiebotContainer.removeChild(script);
        };
    });

    return (          
        <div className="container msds-block-padding-medium">
            <div className="row">
                <div className="col-12">
                    <div className="d-flex flex-row justify-content-between align-items-start">
                        <h1 className="msds-text-header-1 msds-text-clear-blue mb-10">Cookie Policy</h1>
                        <Button
                            id="user-portal__e2e-cookie-footer-link"
                            label="Back"
                            onClickHandler={backEventClick}
                            size={ButtonSize.Large}
                            styling={ButtonStyles.Secondary}
                            cssClass="mb-4 mt-0"
                        />
                    </div>
                    <div id="cookie-declaration-container"></div>
                </div>                    
            </div>
        </div>    
    );
}

export default CookiePolicyPage;