import Button from "components/Button/Button";
import { ButtonSize, ButtonStyles } from "lib/enums";
import { getLanguageUrlParam } from "localization/helpers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const languageUrlParam = getLanguageUrlParam();

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="msds-block-padding-small d-flex flex-column justify-content-center align-items-center">
            <div className="msds-text-header-2 mb-4">
              {t("pages.notFound.message")}
            </div>
            <Button
              label={t("buttons.backToHomepage")}
              size={ButtonSize.Medium}
              styling={ButtonStyles.Secondary}
              onClickHandler={() => {
                navigate(`${languageUrlParam}`);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
