import { createSlice } from "@reduxjs/toolkit";
import { SignupSteps, Status } from "lib/enums";
import {
  SignupCompanyData,
  SignupMatchedCompaniesResponse,
  SignupMatchedCompany,
  SignupSendToSupportData,
  SignupUserData,
} from "lib/types";
import {
  postSignupCompanyVerificationThunk as postSignupCompanyVerification,
  postSignupDataSubmissionThunk as postSignupDataSubmission,
  checkIfUserExistsThunk as checkIfUserExists,
} from "./actions";

export type SignupState = {
  step: SignupSteps;
  enteredCompanyData: SignupCompanyData;
  enteredUserData: SignupUserData;
  sendToSupportData: SignupSendToSupportData;
  matchedCompanies: SignupMatchedCompaniesResponse;
  selectedCompany: SignupMatchedCompany;
  companyAccountDetected: string;
  postSignupCompanyVerificationStatus: Status;
  postSignupDataSubmissionStatus: Status;
  submitDataStatus: Status;
  checkIfUserExistsStatus: Status;
  backendErrors: {
    verifyCompanyBackendErrorMessage: string;
    submitDataBackendErrorMessage: string;    
    submissionStatusCode: number;
    timestamp: string;
  };
};

const formatDateToUTCString = (date) => {
  const pad = (number) => (number < 10 ? `0${number}` : number);
  return (
    pad(date.getUTCDate()) +
    pad(date.getUTCMonth() + 1) +
    date.getUTCFullYear() +
    pad(date.getUTCHours()) +
    pad(date.getUTCMinutes()) +
    pad(date.getUTCSeconds()) +
    'UTC'
  );
};

const initialState: SignupState = {
  step: SignupSteps.EnterCompanyData,
  enteredCompanyData: null,
  enteredUserData: null,
  sendToSupportData: null,
  matchedCompanies: null,
  selectedCompany: null,
  companyAccountDetected: null,
  postSignupCompanyVerificationStatus: Status.Idle,
  postSignupDataSubmissionStatus: Status.Idle,
  submitDataStatus: Status.Idle,
  checkIfUserExistsStatus: Status.Idle,
  backendErrors: {
    verifyCompanyBackendErrorMessage: "",
    submitDataBackendErrorMessage: "",
    submissionStatusCode: 0,
    timestamp: ""
  },
};

const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    goToStep: (state, action) => {
      state.step = action.payload;
    },
    selectCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    saveUserData: (state, action) => {
      state.enteredUserData = action.payload;
    },
    resetbackendErrors: (state) => {
      state.backendErrors.submitDataBackendErrorMessage = "";
      state.backendErrors.verifyCompanyBackendErrorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postSignupCompanyVerification.pending, (state) => {
        state.postSignupCompanyVerificationStatus = Status.Pending;
      })
      .addCase(postSignupCompanyVerification.rejected, (state, action) => {
        state.postSignupCompanyVerificationStatus = Status.Rejected;
        state.backendErrors.verifyCompanyBackendErrorMessage =
          action.payload.response.data;
      })
      .addCase(postSignupCompanyVerification.fulfilled, (state, action) => {
        const matchedCompanies = action.payload.data;
        state.postSignupCompanyVerificationStatus = Status.Success;
        state.enteredCompanyData = action.meta.arg as SignupCompanyData;
        state.matchedCompanies = matchedCompanies;
        if (matchedCompanies.length > 0) state.step = SignupSteps.SelectCompany;
        else state.step = SignupSteps.ContactSupport;
      })
      .addCase(postSignupDataSubmission.pending, (state) => {
        state.postSignupDataSubmissionStatus = Status.Pending;
      })
      .addCase(postSignupDataSubmission.rejected, (state, action) => {
        state.postSignupDataSubmissionStatus = Status.Rejected;                  
        state.backendErrors.submitDataBackendErrorMessage = action.payload.response.data;
        state.backendErrors.submissionStatusCode = action.payload.response.status;
        state.backendErrors.timestamp = formatDateToUTCString(new Date());
      })
      .addCase(postSignupDataSubmission.fulfilled, (state, action) => {
        state.postSignupDataSubmissionStatus = Status.Success;
        state.step = SignupSteps.SignupConfirmation;
        state.companyAccountDetected = action.payload.data.company;
      })
      .addCase(checkIfUserExists.pending, (state) => {
        state.checkIfUserExistsStatus = Status.Pending;
      })
      .addCase(checkIfUserExists.rejected, (state, action) => {
        state.checkIfUserExistsStatus = Status.Rejected;
      })
      .addCase(checkIfUserExists.fulfilled, (state) => {
        state.checkIfUserExistsStatus = Status.Success;
      });
  },
});

export const { goToStep, selectCompany, saveUserData, resetbackendErrors } =
  signupSlice.actions;

export default signupSlice.reducer;
