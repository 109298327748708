import { createSlice } from "@reduxjs/toolkit";
import { Status } from "lib/enums";
import { LicenseClaimType } from "lib/types";
import { postLicenseClaimThunk as postLicenseClaim } from "./actions";

export type LicenseClaimState = {
    data: LicenseClaimType;
    postLicenseClaimStatus: Status;
    backendErrors: {
        postLicenseClaimErrorMsg: string;
    };
};

const initialState: LicenseClaimState = {
    data: null,
    postLicenseClaimStatus: Status.Idle,
    backendErrors: {
        postLicenseClaimErrorMsg: "",
    },
};

const licenseClaimSlice = createSlice({
    name: "licenseClaim",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postLicenseClaim.pending, (state) => {
                state.postLicenseClaimStatus = Status.Pending;
            })
            .addCase(postLicenseClaim.rejected, (state, action) => {
                state.postLicenseClaimStatus = Status.Rejected;
                state.backendErrors.postLicenseClaimErrorMsg =
                    action.payload.response.data;
            })
            .addCase(postLicenseClaim.fulfilled, (state, action) => {
                state.postLicenseClaimStatus = Status.Success;
                state.data = action.payload.data;
                state.backendErrors.postLicenseClaimErrorMsg = "";
            });
    },
});

export default licenseClaimSlice.reducer;
