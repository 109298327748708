import Button from "components/Button/Button";
import FormButton from "components/FormComponents/FormButton/FormButton";
import { useFormFieldState } from "components/FormComponents/formHooks/useFormFieldState";
import TextArea from "components/FormComponents/TextArea/TextArea";
import Form from "components/FormComponents/Form/Form";
import { ButtonStyles, FloatingButtonStyles } from "lib/enums";
import { SupportEmailType } from "lib/types";
import { useTranslation } from "react-i18next";
import { sendSupportEmailThunk } from "redux/api/supportEmail/supportEmailSlice";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { useSearchParams } from "react-router-dom";
import FloatingButton from "components/FloatingButton/FloatingButton";


const LoginSendSupportEmailForm = ({ userData, sendEmailStatus, goBackButtonHandler, matchesExist }) => {  
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userMessage = useFormFieldState();
  const backendErrorMessage = useAppSelector(
    (state) => state.supportEmail.sendSupportEmailBackendErrorMsg
  );
  const USER_INPUT_DEFAULT_VALUE = 'Not provided';

  const [searchParams] = useSearchParams();
  const notNowcallbackUrl = searchParams.get("callback");

  const sendEmail = () => {
    const emailData: SupportEmailType = {
      companyName: userData.companyName,
      contactEmail: userData.contactEmail,
      contactFirstName: userData.contactFirstName,
      contactLastName: userData.contactLastName,
      message: userMessage.state.value as string,
      country: userData.contactCountry ? userData.contactCountry : USER_INPUT_DEFAULT_VALUE,
      telephone: userData.contactPhone ? userData.contactPhone : USER_INPUT_DEFAULT_VALUE,
      telephoneKey: userData.telephoneKey ? userData.telephoneKey : USER_INPUT_DEFAULT_VALUE,
      address: userData.companyAddress ? userData.companyAddress : USER_INPUT_DEFAULT_VALUE,
      zipCode: userData.companyPostalCode ? userData.companyPostalCode : USER_INPUT_DEFAULT_VALUE,
      city: userData.companyCity ? userData.companyCity : USER_INPUT_DEFAULT_VALUE,
      source: "Existing Customer Validation"
    };   
    dispatch(sendSupportEmailThunk(emailData));
  };

  return (
    <>
    <Form handleSubmit={sendEmail}>
      <TextArea
        id="signup-contact-support-user-message"
        label={t("pages.verifyCompany.supportMessage")}
        placeholder={t("pages.verifyCompany.supportMessage")}
        maxLength={500}
        handleChangeData={(newValue, isValid) =>
          userMessage.setState({ value: newValue, isValid: isValid })
        }
      />
      {backendErrorMessage && (
        <p className="msds-text-danger-red-1">{backendErrorMessage}</p>
      )}
      <section className="no-matched-company-page__actions">
        {matchesExist &&
          <Button
              label={t("buttons.back")}
              styling={ButtonStyles.Secondary}            
              onClickHandler={() => goBackButtonHandler(true)}
            />
        }
        <FormButton
          id="signup-contact-support-submit-button"
          label={t("buttons.sendToMilestone")}
          styling={ButtonStyles.Tertiary}                   
        />
        {!matchesExist && 
          <FloatingButton
            id="verify-company-page-form__e2e-not-now-button"                  
            href={notNowcallbackUrl}
            label={t("buttons.notNow")}
            styling={FloatingButtonStyles.Primary }
            cssClass = "mt-20"                                    
          />  
        } 
      </section>
    </Form>
    </>
  );
};

export default LoginSendSupportEmailForm;
