import { Outlet } from "react-router-dom";
import MainNavigation from "components/Navigation/MainNavigation";
import SecondaryNavigation from "components/SecondaryNavigation/SecondaryNavigation";
import NavigationFooter from "components/NavigationFooter/NavigationFooter";
import { useHideNavBaseOnLocation } from "lib/hooks"

export const Layout = () => {
  const isNavigationHidden = useHideNavBaseOnLocation()

  return (
    <>
      {!isNavigationHidden && <SecondaryNavigation />}
      <div className="user-portal">
        {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined in Routing.tsx. */}
        <Outlet />
      </div>
      <NavigationFooter />
    </>
  );
};

export default Layout;
