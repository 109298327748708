import classNames from "classnames";
import { ReactNode } from "react";
import "./dialog.scss";

const Dialog = ({ isOpen, title, content, actions }: DialogProps) => {
  return (
    <div
      className={classNames({
        dialog__overlay: true,
        "dialog__overlay--hidden": !isOpen,
      })}
    >
      <div className="dialog__container">
        <header className="dialog__header msds-text-header-2">
          <p>{title}</p>
        </header>
        <main className="dialog__content msds-text-body-1">{content}</main>
        <footer className="dialog__actions">{actions}</footer>
      </div>
    </div>
  );
};

export default Dialog;

type DialogProps = {
  isOpen: boolean;
  title: string;
  content: ReactNode;
  actions: ReactNode;
};
