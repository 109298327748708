import { useEffect, useLayoutEffect, useState } from "react";

class Device {
    static isLarge() {
        return document.querySelector('body').clientWidth > 992;
    }

    static isMedium() {
        return document.querySelector('body').clientWidth > 768 && document.querySelector('body').clientWidth < 992;
    }

    static isLarger() {
        return document.querySelector('body').clientWidth > 768;
    }

    static isSmall() {
        return document.querySelector('body').clientWidth > 479 && document.querySelector('body').clientWidth < 769;
    }

    static isVerySmall() {
        return document.querySelector('body').clientWidth < 480;
    }

    static isAndroid() {
        return navigator.userAgent.toLowerCase().indexOf("android") > -1;
    }

    static isCurrentClientWidth() {
        return document.body.clientWidth;
    }

}

export default Device;

export function useWindowSize() {
    const [size, setSize] = useState([0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}
