import Button from "components/Button/Button";
import { ButtonStyles, SignupSteps } from "lib/enums";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { postSignupDataSubmissionThunk } from "redux/signup/actions";
import { goToStep, resetbackendErrors } from "redux/signup/signupSlice";
import SignUpError from "../6-SignUpError/SignUpError";
import "../../user-company-information-page.scss";
import './overviewAndSubmissionStep.scss';

const OverviewAndSubmissionStep = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const enteredUserData = useAppSelector(
    (state) => state.signup.enteredUserData
  );
  const selectedCompany = useAppSelector(
    (state) => state.signup.selectedCompany
  );

  const backendErrorMessage = useAppSelector(
    (state) => state.signup.backendErrors.submitDataBackendErrorMessage
    );
    const description = (
    <>
      <p>{t("pages.signUp.overviewAndSubmission.description.message1")}</p>
      <p>{t("pages.signUp.overviewAndSubmission.description.message2")}</p>
    </>
  );

  const specificErrorMessage = useAppSelector(
    (state) => state.signup.backendErrors.submitDataBackendErrorMessage    
  );

  const submissionStatusCode = useAppSelector(
    (state) => state.signup.backendErrors.submissionStatusCode    
  );

  const timestamp = useAppSelector(
    (state) => state.signup.backendErrors.timestamp    
  );
  
  const companyInformationFields = [
    {
      label: `${t("pages.signUp.noMatch.labels.company")}:`,
      field: "name",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.address")}:`,
      field: "address",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.zipCode")}:`,
      field: "postalCode",
    },
    { label: `${t("pages.signUp.noMatch.labels.city")}:`, field: "locality" },
    {
      label: `${t("pages.signUp.noMatch.labels.country")}:`,
      field: "country",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.state")}:`,
      field: "state",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.website")}:`,
      field: "website",
    },
  ];

  const contactInformationFields = [
    { label: `${t("pages.signUp.noMatch.labels.email")}:`, field: "email" },
    {
      label: `${t("pages.signUp.noMatch.labels.firstName")}:`,
      field: "firstName",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.lastName")}:`,
      field: "lastName",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.telephone")}:`,
      field: "telephone",
    },
    { label: `${t("pages.signUp.noMatch.labels.jobTitle")}:`, field: "title" },
  ];

  useEffect(() => {    
    return () => {
      dispatch(resetbackendErrors());
    };
  }, []);

  return (
    <div className="container overview-submission-step__container">
      {backendErrorMessage ? 
        <SignUpError companyName={selectedCompany.name} submissionStatusCode={submissionStatusCode} specificErrorMessage={specificErrorMessage} timestamp={timestamp} /> : <div className="user-information-page">
        <header className="msds-text-gray-10 msds-text-header-2">
          {t("pages.signUp.overviewAndSubmission.title")}
        </header>
        <div className="breaker msds-bg-clear-blue" />
        <section className="user-information-page__description">
          {description}
        </section>
        <section className="user-information-page__content">
          <div className="user-information-page__data-section">
            <div className="msds-text-header-3-light">
              {t("pages.signUp.noMatch.companyInformation")}:
            </div>
            {selectedCompany &&
              companyInformationFields.map((data, index) => {
                return (
                  <div key={index} className="user-information-page__data-item">
                    <p className="msds-text-body-2-bold">{data.label}</p>
                    <p className="msds-text-body-1">
                      {selectedCompany[data.field]}
                    </p>
                  </div>
                );
              })}
          </div>
          <div className="user-information-page__data-section">
            <div className="msds-text-header-3-light">
              {t("pages.signUp.noMatch.contactInformation")}:
            </div>
            {enteredUserData &&
              contactInformationFields.map((data, index) => {
                return (
                  <div key={index} className="user-information-page__data-item">
                    <p key={index} className="msds-text-body-2-bold">
                      {data.label}
                    </p>
                    <span className="msds-text-body-1">
                      {enteredUserData[data.field]}
                    </span>
                  </div>
                );
              })}
          </div>
        </section>
        <section className="user-information-page__actions">
          <Button
            label={t("buttons.back")}
            styling={ButtonStyles.Secondary}
            onClickHandler={() => {
              dispatch(goToStep(SignupSteps.EnterUserData));
            }}
          />
          <Button
            label={t("buttons.signUp")}
            styling={ButtonStyles.Tertiary}
            eventCategory="User Portal"
            eventLabel="Sign-up Form"
            onClickHandler={() => {
              dispatch(
                postSignupDataSubmissionThunk({
                  email: enteredUserData.email,
                  firstName: enteredUserData.firstName,
                  lastName: enteredUserData.lastName,
                  title: enteredUserData.title,
                  telephone: enteredUserData.telephone,
                  company: selectedCompany.name,
                  address: selectedCompany.address,
                  zip: selectedCompany.postalCode,
                  city: selectedCompany.locality,
                  password: enteredUserData.password,
                  confirmPassword: enteredUserData.confirmPassword,
                  website: selectedCompany.website,
                  isConsentCheckboxSelected:
                    enteredUserData.tcConsent.length > 0
                      ? true
                      : false,
                  consent:
                    enteredUserData.consent == undefined ? "FALSE" : "TRUE",
                  consentText: enteredUserData.consentText,
                  tcConsent:
                    enteredUserData.tcConsent.length > 0
                      ? "TRUE"
                      : "FALSE",
                  tcConsentText: enteredUserData.tcConsentText,                  
                  countryTelephoneCodeKeySelected:
                    enteredUserData.telephoneCode,
                  privacyNoticeText: t("forms.privacyNotice"),                 
                  purposeText: t("pages.signUp.purposeText"),
                  country: selectedCompany.country,
                  state: selectedCompany.state,
                  companyNumber: selectedCompany.companyNumber,
                })
              );
            }}
          />
        </section>
      </div>}

    </div>
  );
};

export default OverviewAndSubmissionStep;
