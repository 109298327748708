import Button from "components/Button/Button";
import Dialog from "components/Dialog/Dialog";
import { MILESTONE_URL } from "config";
import i18n from "i18n";
import { ButtonStyles } from "lib/enums";
import { Trans, useTranslation } from "react-i18next";

const TermsConditionsDialog = ({
  isOpen,
  handleCloseDialog,
}: {
  isOpen: boolean;
  handleCloseDialog: () => void;
}) => {
  const { t } = useTranslation();

  const paragraphs = (
    <Trans
      components={{
        first: <p />,
        second: <p />,
        third: <p />,
        fourth: <p />,
        fifth: <p />,
        sixth: <p />,
        seventh: <p />,
        eigth: <p />,
        list: <ol />,
        item1: <li />,
        item2: <li />,
        eulaLink: (
          <a
            target="_blank"
            href="https://doc.milestonesys.com/latest/en-US/portal/htm/chapter-page-eula.htm"
          />
        ),
        item3: <li />,
        item4: <li />,
        ninth: <p />,
        tenth: <p />,
        privacy: <a href={`${MILESTONE_URL()}/privacy-policy/`} />,
      }}
    >
      {i18n.t("dialogs.termsConditionsDialog.content")}
    </Trans>
  );

  const actions = (
    <>
      <Button
        label={t("buttons.close")}
        styling={ButtonStyles.Secondary}
        onClickHandler={handleCloseDialog}
      />
    </>
  );

  return (
    <Dialog
      title={t("dialogs.termsConditionsDialog.title")}
      isOpen={isOpen}
      content={paragraphs}
      actions={actions}
    />
  );
};

export default TermsConditionsDialog;
