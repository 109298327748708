import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "components/Button/Button";
import { ButtonStyles, Status } from "../../lib/enums";
import { ReactComponent as CheckmarkIcon } from "@milestone-sys/web-design-system/svg/rich/checkmark.svg";
import { ReactComponent as AlertmarkIcon } from "@milestone-sys/web-design-system/svg/rich/warning.svg";
import { postLicenseClaimThunk } from "redux/licenseClaim/actions";
import { getMyDetailsThunk } from "redux/api/userDetails/actions";
import "./license-claim-page.scss";
import { MILESTONE_URL } from "config";
import { clearSessionRegisterLicenseData, getRegisterLicenseFlowDataFromSessionStorage } from "lib/helpers";

const ClaimLicensePage = () => {

  let isCompanyVerified = null;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { slc, token } = useParams();
  const licenseClaimStatus = useAppSelector( state => state.licenseClaim.postLicenseClaimStatus );
  const backendErrorMsg = useAppSelector( state => state.licenseClaim.backendErrors.postLicenseClaimErrorMsg );
  isCompanyVerified = useAppSelector( state => state.userDetails.myDetails?.isCompanyVerified)
  const licenseClaim = {
    slc: slc,
    token: token
  };
  const userDetailsStatus = useAppSelector( (state) => state.userDetails.getMyDetailsStatus)
  const handleClaimLicenseClick = () => {
    dispatch(postLicenseClaimThunk(licenseClaim));
    clearSessionRegisterLicenseData()
  };
  const handleBackToLicensesClick = () => {
    navigate("/licenses/");
  };

  useEffect(() => {
    dispatch( getMyDetailsThunk() )    
  },[dispatch, getMyDetailsThunk]);
  
  const navigateToCompanyVerificationPage = () => {        
        navigate(`/verify-company`);
  }

  useEffect(() => {
    if (userDetailsStatus === Status.Success && !isCompanyVerified) {
      navigateToCompanyVerificationPage();      
    }
  },[isCompanyVerified, navigateToCompanyVerificationPage, userDetailsStatus])
console.log(isCompanyVerified)
  return (
    <div className="container msds-block-padding-medium text-center">
      {licenseClaimStatus === Status.Idle && (
        <>
          <h1 className="msds-text-header-1-bold mt-0 mb-8 msds-text-dark-blue">
            {t("pages.licenseClaim.title")}
          </h1>
          <div className="msds-text-body-0 mb-8">
            {t("pages.licenseClaim.description")}
          </div>
          <div className="msds-text-lead-bold msds-text-gray-10 mb-8">
            { slc != null &&        
              `${slc}`            
            } 
            {
              slc === null &&
              t("pages.licenseClaim.descriptionNoLicense")
            }
          </div>
          <div className="user-portal__licence-claim-buttons d-flex flex-column flex-md-row mt-0 mb-3">                     
            {slc != null &&   
              <Button
              label={t("buttons.registerLicense")}
              styling={ButtonStyles.Tertiary}
              onClickHandler={handleClaimLicenseClick}
              />
            }            
          </div>
        </>
      )}    
      {licenseClaimStatus === Status.Rejected && (
        <>                    
          <AlertmarkIcon className="user-portal__claim-license-success mb-3" />
          <h1 className="msds-text-lead-bold mt-0 mb-8 msds-text-gray-10">
            {t("pages.licenseClaim.titleError.license")} {slc} {t("pages.licenseClaim.titleError.couldNotRegister")}
          </h1>
          <div className="msds-text-header-3 msds-text-gray-10 mb-4">
            {backendErrorMsg}          
          </div>       
          <div className="msds-text-body-2 msds-text-gray-10">
            {t("pages.licenseClaim.contactSupport")}{" "}
            <a href={`${MILESTONE_URL()}/support/`}>Milestone Support</a>
          </div>
          <Button
            label={t("buttons.claimLicenseSuccessGoBack")}
            styling={ButtonStyles.Tertiary}
            onClickHandler={handleBackToLicensesClick}
          />
        </>
      )}      
      {licenseClaimStatus === Status.Success && (
        <>          
          <CheckmarkIcon className="user-portal__claim-license-success mb-3" />
          <div className="msds-text-lead-bold msds-text-gray-10 mb-4">
            {t("pages.licenseClaim.descriptionSuccess")}  
            {" "}
            {slc}
          </div>                  
          <Button
            label={t("buttons.claimLicenseSuccessGoBack")}
            styling={ButtonStyles.Tertiary}
            onClickHandler={handleBackToLicensesClick}
          />
        </>
      )}
    </div>
  );
};

export default ClaimLicensePage;
