import { configureStore } from "@reduxjs/toolkit";
import userActionsReducer from "./userActions/userActionsSlice";
import configurationListsReducer from "./configurationLists/configurationListsSlice";
import userReducer from "./user/userSlice";
import userDetailsReducer from "./api/userDetails/userDetailsSlice";
import subscriptionReducer from "./api/subscription/subscriptionSlice";
import userUpdatedEmailConfirmationReducer from "./api/userUpdatedEmailConfirmation/userUpdatedEmailConfirmationSlice";
import userRolesReducer from "./userRoles/userRolesSlice";
import userTemporaryProfileReducer from "./api/userTemporaryProfile/userTemporaryProfileSlice";
import adminDeclineUserApplicationReducer from "./api/AdminDeclineUserApplication/adminDeclineUserApplicationSlice";
import adminAddNewUserRequestReducer from "./api/adminAddNewUserRequest/adminAddNewUserRequestSlice";
import supportEmailReducer from "./api/supportEmail/supportEmailSlice";
import companyVerificationReducer from "./companyVerification/companyVerificationSlice";
import licensesReducer from "./licenses/licensesSlice";
import licenseClaimReducer from "./licenseClaim/licenseClaimSlice";
import appReducer from "./app/appSlice";
import signupReducer from "./signup/signupSlice";

export const store = configureStore({
  reducer: {
    userActions: userActionsReducer,
    configutationLists: configurationListsReducer,
    user: userReducer,
    userDetails: userDetailsReducer,
    subscription: subscriptionReducer,
    userRoles: userRolesReducer,
    userUpdatedEmailConfirmation: userUpdatedEmailConfirmationReducer,
    userTemporaryProfile: userTemporaryProfileReducer,
    adminDeclineUserApplication: adminDeclineUserApplicationReducer,
    adminAddNewUserRequest: adminAddNewUserRequestReducer,
    supportEmail: supportEmailReducer,
    companyVerification: companyVerificationReducer,
    licenses: licensesReducer,
    licenseClaim: licenseClaimReducer,
    app: appReducer,
    signup: signupReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
