import { NavLink } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { selectIsAuthenticated } from "redux/user/userSlice";
import { useTranslation } from "react-i18next";
import "./secondary-navigation.scss";
import { getLanguageUrlParam } from "localization/helpers";
import { useWindowSize } from "components/Navigation/device";

const SecondaryNavigation = () => {
  const { t } = useTranslation();

  const languageUrlParam = getLanguageUrlParam();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isAdmin = useAppSelector((state) => state.user.isAdmin);

  const [width] = useWindowSize();

  const desktopSize = 769;
  let isDesktop = false;
  isDesktop = (width >= desktopSize) ? true : false;

  return (

    <>
      {isDesktop && (
        <div id="nav" className="navigation">
          {!isAuthenticated && (
            <>
              <NavLink className="navigation__links" to={`${languageUrlParam}/signup`}>
                {t("navigation.tabs.createLogin")}
              </NavLink>
              <NavLink className="navigation__links" to={`${languageUrlParam}/reset-password`}>
                {t("navigation.tabs.forgotPassword")}
              </NavLink>
            </>
          )}
          {isAuthenticated && (
            <>
              <NavLink className="navigation__links" to={`${languageUrlParam}/edit-user-details`}>
                {t("navigation.tabs.myDetails")}
              </NavLink>
              {isAdmin && (
                <NavLink className="navigation__links" to={`${languageUrlParam}/user-list`}>
                  {t("navigation.tabs.myUsers")}
                </NavLink>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SecondaryNavigation;
