import React, { useEffect, useCallback } from "react";
import { selectIsAuthenticated } from "redux/user/userSlice";
import { useAppSelector } from "redux/hooks";
import { AuthenticationContextType, AuthenticationProviderProps, RedirectionType } from "./types";
import { useAuthentication, redirectToIDP, addUserLoadedEvent } from "./authenticationUtilities";
import { Route, Routes } from "react-router-dom";
import AuthenticationCallbackPage from "./AuthenticationCallbackPage";

const LOGOUT_TIMEOUT_IN_MINUTES = 15;
let logoutTimeoutId = null;

export const AuthenticationContext: React.Context<AuthenticationContextType> = React.createContext({
    handleLoginButtonClick: () => { },
    authConfig: {},
    authCallbackConfig: {}
});

export const AuthenticationProvider = (props: AuthenticationProviderProps) => {
    const { config, children } = props;
    const setAuthorization = useAuthentication();
    const isAuthenticated = useAppSelector(selectIsAuthenticated);

    //If already already authenticated, redirect to IDP (SSO) sign out page. If not authenticated, redirect to sign in page.
    const handleLoginButtonClick = () => redirectToIDP(config, isAuthenticated ? RedirectionType.Signout : RedirectionType.Signin)

    const initializeAuthentication = useCallback(() => {
        //Hook into user loaded event. In case of refresh token request completion access token needs to be updated so setAuthorization function is set to run.
        addUserLoadedEvent(config, () => setAuthorization(config));

        //Tries to load user data from session storage. If successful sets access token and authentication status.
        //Do not set authorization state if current page is /authentication-callback. At this stage authentication is finalized so authorization state would change anyway.
        if (!window.location.href.includes(config.redirect_uri)) {
            setAuthorization(config)
        }
    }, [config, setAuthorization]);

    //If authentication provider has been not initilized - do it.
    useEffect(() => {
        if (isAuthenticated === null) {
            initializeAuthentication();
        }
    }, [initializeAuthentication, isAuthenticated]);

    //Redirect to IDP (SSO) sign out page after certain time of user inactivity.
    useEffect(() => {
        function logoutAfterTimeout() {
            logoutTimeoutId && clearTimeout(logoutTimeoutId);
            logoutTimeoutId = setTimeout(() => redirectToIDP(config, RedirectionType.Signout), LOGOUT_TIMEOUT_IN_MINUTES * 60 * 1000);
        }

        window.addEventListener("click", logoutAfterTimeout);
        window.addEventListener("mousemove", logoutAfterTimeout);
        window.addEventListener("keypress", logoutAfterTimeout);
    }, [config]);

    const authContextValue: AuthenticationContextType = {
        handleLoginButtonClick,
        authConfig: config,
        authCallbackConfig: { ...config, response_mode: "query" }
    }

    return (
        <AuthenticationContext.Provider value={authContextValue}>
            {isAuthenticated !== null && children}
            {isAuthenticated === null &&
                <Routes>
                    <Route
                        path={new URL(config.redirect_uri).pathname}
                        element={<AuthenticationCallbackPage />}
                    />
                </Routes>}
        </AuthenticationContext.Provider>
    );
};
