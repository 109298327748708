import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../components/Table/Table";
import Button from "../../components/Button/Button";
import { ButtonStyles, ButtonSize } from "../../lib/enums";
import { useAppSelector } from "redux/hooks";
import { toShortDate, encrypt } from "lib/helpers";
import UnauthorizedBanner from "components/UnauthorizedBanner/UnauthorizedBanner";
import { useTranslation } from "react-i18next";
import { getLanguageUrlParam } from "localization/helpers";
import { userListColumns } from "./userListColumns";

function UserListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const languageUrlParam = getLanguageUrlParam();

  const companyUsers = useAppSelector(
    (state) => state.userDetails.companyUsersById
  );
  
  const myDetails = useAppSelector((state) => state.userDetails.myDetails);

  const isAdmin = useAppSelector((state) => state.user.isAdmin);

  const [initialData, setInitialData] = useState([]);

  const setUserId = (userId) => {
    if (userId) {
      return encrypt(userId, "CDS:User-List-Details__User-Id", true);
    }
    return "";
  };

  useEffect(() => {
    let newTableData = [];
    Object.values(companyUsers).forEach((companyUser) => {
      if (companyUser.noLongerWithCompany !== "true") {
        newTableData.push({
          id: setUserId(companyUser.id),
          userName: companyUser.username,
          firstName: companyUser.firstName,
          lastName: companyUser.lastName,
          phone: companyUser.telephone,
          email: companyUser.email,
          createdDate: toShortDate(companyUser.createdDate),
          subRows: [{ userName: companyUser.userRoles }],
        });
      }
    });
    setInitialData(newTableData);
  }, [companyUsers]);

  return (
    <div className="container-fluid msds-block-padding-medium">
      <div className="row">
        <div className="col-12">
          <div className="text-center mb-10">
            <h1 className="msds-text-header-1 mt-0">
              {t("pages.myUsers.title")}
            </h1>
            <p>
              {myDetails?.isMilestoneEmployee
                ? t("pages.myUsers.descriptionMilestoneEmployee")
                : t("pages.myUsers.description")}
            </p>
          </div>
          {isAdmin ? (
            <>
              {!myDetails?.isMilestoneEmployee && (
                <div className="text-center text-md-right">
                  <Button
                    id={ "user-portal__e2e-button-" + t("buttons.addNewUser").toLowerCase().replace(/\s+/g, '-')}
                    label={t("buttons.addNewUser")}
                    onClickHandler={() => {
                      navigate(`${languageUrlParam}/user-list-details`);
                    }}
                    size={ButtonSize.Medium}
                    styling={ButtonStyles.Tertiary}
                  />
                </div>
              )}
              {initialData.length > 0 && (
                <Table
                  initialData={initialData}
                  rowClickBaseUrl={"/user-list-details/"}
                  tableColumns={userListColumns}
                />
              )}
            </>
          ) : (
            <UnauthorizedBanner />
          )}
        </div>
      </div>
    </div>
  );
}

export default UserListPage;
