import Table from "components/Table/Table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { licenseColumns } from "./licenseColumns";
import { useAppSelector } from "redux/hooks";

const LicensePage = () => {
  const { t } = useTranslation();
  const licensesData = useAppSelector((state) => state.licenses.data);
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    if (licensesData?.length > 0) {
      setInitialData(
        licensesData.map((data) => {
          return {
            id: data.softwareLicenseCode,
            softwareLicenseCode: data.softwareLicenseCode,
            productName: data.productName,
            lastOnlineActivationDate: data.lastOnlineActivationDate ? "Activated" : "Not activated",
          };
        })
      );
    }
    if (licensesData?.length === 0) {
      setInitialData([
        {
          id: "",
          softwareLicenseCode: "No license",
          productName: "No product/version",
          lastOnlineActivationDate: "No Status",
        },
      ]);
    }
  }, [licensesData]);

  return (
    <div className="container msds-block-padding-medium">
      <div className="row">
        <div className="col-12">
          <div className="text-center mb-10">
            <h1 className="msds-text-header-1 mt-0">
              {t("pages.myLicenses.title")}
            </h1>
            <p>{t("pages.myLicenses.description")}</p>
          </div>
          <>
            {initialData && (
              <Table
                initialData={initialData}
                rowClickBaseUrl={""}
                tableColumns={licenseColumns}
              />
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default LicensePage;
