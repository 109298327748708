import FormButton from "components/FormComponents/FormButton/FormButton";
import { useSearchParams } from "react-router-dom";
import { ButtonStyles, FloatingButtonStyles, Status } from "lib/enums";
import { MatchedCompaniesType } from "lib/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { updateCompanyWithDunsNumberThunk } from "redux/companyVerification/actions";
import "./matches-found.scss";
import classNames from "classnames/bind";
import { clearSessionRegisterLicenseData, getRegisterLicenseFlowDataFromSessionStorage, toggleRegisterLicenseInSessionStorage } from "lib/helpers";
import FloatingButton from "components/FloatingButton/FloatingButton";
import { Error } from "../Error/Error";

const MatchesFound = ({
  accountId,
  contactEmail, 
  matchCandidates,
  handleIsMyCompanyOnTheList,
}: {
  accountId: string;
  contactEmail: string;
  matchCandidates: MatchedCompaniesType;
  handleIsMyCompanyOnTheList: React.Dispatch<React.SetStateAction<boolean>>;  
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedCompanyNumber, setSelectedCompanyNumber] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  let isRegisterLicenseFlow = false;
  const registerLicenseFlowData = getRegisterLicenseFlowDataFromSessionStorage()  
  const checkRegisterLicenseFlowTimestamp = () => {
    const currentTime = Date.now();
    const timeDiff = currentTime - registerLicenseFlowData.createdAt;
    const timeDiffConvertedToMinutes= Math.floor((timeDiff / 1000) / 60);
  
    if (timeDiffConvertedToMinutes >= 10) {
      clearSessionRegisterLicenseData();    
    }    
  }
  
  if(registerLicenseFlowData && registerLicenseFlowData.isRegisterLicenseFlow){
    checkRegisterLicenseFlowTimestamp()
    isRegisterLicenseFlow = registerLicenseFlowData.isRegisterLicenseFlow
  }

  const notNowcallbackUrl = searchParams.get("callback");
  const dunsNumberRejected = useAppSelector((state) => state.companyVerification.dunsNumberAlreadyRegistered) 
  const shouldShowNowButton = !isRegisterLicenseFlow
  const backendErrorMessage = useAppSelector(
    (state) =>
      state.companyVerification.backendErrors
        .updateCompanyWithDunsNumberStatusErrorMsg
  );  
  
  return (
    <>
      {dunsNumberRejected && <Error />}
      {dunsNumberRejected || <div className="container">       
        <div className="company-list">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="msds-text-gray-10 msds-text-header-2">
                {t("pages.verifyCompany.title")}
              </div>
              <div className="breaker msds-bg-clear-blue" />            
              <div className="col-md-6 col-sm-8 p-0">
                <p>{t("pages.verifyCompany.descriptionWithMatches")}</p>
                <p>{t("pages.verifyCompany.notNowDescription")}</p>
              </div>
              <div className="company-list__count msds-text-header-5 msds-text-gray-10">
                {t("pages.signUp.selectCompany.foundCompanies", {
                  count: (matchCandidates as MatchedCompaniesType).length,
                })}
              </div>
              <div
                className={classNames(
                  "company-list__selections col-8 p-0",
                  matchCandidates.length > 4 && "company-list__selections--long"
                )}
              >
                {Object.keys(matchCandidates).length > 0 &&
                  (matchCandidates as MatchedCompaniesType).map((company) => {
                    return (
                      <div
                        key={company.companyNumber}
                        className="company-list__item"
                      >
                        <div className="msds-radio-button">
                          <input
                            className="msds-radio-button__input"
                            id={company.companyNumber}
                            type="radio"
                            value={company.companyNumber}
                            name="company-list-group"
                            onChange={(e) => {
                              setSelectedCompanyNumber(e.target.value);
                            }}
                          />
                          <label
                            className="msds-radio-button__label msds-text-body-1 msds-text-gray-10"
                            htmlFor={company.companyNumber}
                          >
                            {company.name +
                              ", " +
                              company.address +
                              ", " +
                              company.postalCode +
                              ", " +
                              company.locality}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <button
                className="company-list__not-on-list-button msds-text-body-1"
                onClick={() => {
                  handleIsMyCompanyOnTheList(false);
                }}
              >
                {matchCandidates.length === 1
                  ? "This is not my company"
                  : "My company is not on the list"}
              </button>
              {backendErrorMessage && (
                <p className="msds-text-danger-red-1">{backendErrorMessage}</p>
              )}
              <div className="company-list__actions">                           
                <FormButton
                  id="verify-company-page-form__e2e-update-button"
                    label={t("buttons.update")}
                    styling={selectedCompanyNumber ? ButtonStyles.Tertiary : ButtonStyles.Gray}
                    disabled={(!selectedCompanyNumber || selectedCompanyNumber.length == 0)?true:false}
                    onClickHandler={() => {                    
                      dispatch(
                        updateCompanyWithDunsNumberThunk({
                          accountId: accountId,
                          dunsNumber: selectedCompanyNumber,
                          contactEmail: contactEmail,
                        })                      
                      );
                    }}
                />  
                {shouldShowNowButton && <FloatingButton
                    id="verify-company-page-form__e2e-not-now-button"                  
                    href={notNowcallbackUrl}
                    label={t("buttons.notNow")}
                    styling={FloatingButtonStyles.Primary }
                    cssClass = "mt-20"                                    
                  />  
                }               
              </div>
            </div>
          </div>
        </div>
      </div>}     
    </>
  );
};

export default MatchesFound;
