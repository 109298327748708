import { ReactNode } from "react";
import "./left-side-column.scss";

function LeftSideColumn({ children }: LeftSideColumnProps) {
  return (
    <div className="user-portal__split-container-left-side-container col-10 offset-1 col-lg-4 offset-lg-1 mb-8 mb-lg-10">
      {children}
    </div>  
  );
}

type LeftSideColumnProps = {
  children: ReactNode;
};

export default LeftSideColumn;
