import { Trans, useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import "../../user-company-information-page.scss";
import SignupSendSupportEmailForm from "components/SignupSendSupportEmailForm/SignupSendSupportEmailForm";
import cloneDeep from "lodash.clonedeep";

const ContactSupport = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const enteredCompanyData = useAppSelector(
    (state) => state.signup.enteredCompanyData
  );

  const telephoneCodes = useAppSelector(
    (state) => state.configutationLists.CountryTelephoneCodes
  );

  const regioncountries = useAppSelector(
    (state) => state.configutationLists.RegionCountries
  );

  let preselectedCountry = regioncountries.find(item => item.value === enteredCompanyData?.countryKeySelected);
  const updatedpreselectedCountry = preselectedCountry.label;
  let formatedEnteredCompanyData = cloneDeep(enteredCompanyData);
  formatedEnteredCompanyData.countryKeySelected = updatedpreselectedCountry;

  const description = (
    <>
      <p>{t("pages.signUp.noMatch.description.message1")}</p>
    </>
  );

  const companyInformationFields = [
    {
      label: `${t("pages.signUp.noMatch.labels.company")}:`,
      field: "company",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.zipCode")}:`,
      field: "zip",
    },
    {
      label: `${t("pages.signUp.noMatch.labels.country")}:`,
      field: "countryKeySelected",
    },
  ];

  return (
    <div className="container">
      <div className="user-information-page">
        <header className="msds-text-gray-10 msds-text-header-2 mt-30">
          {t("pages.signUp.noMatch.title")}
        </header>
        <div className="breaker msds-bg-clear-blue" />
        <section className="user-information-page__description">
          {description}
        </section>
        <section className="user-information-page__content">
          <div className="user-information-page__data-section">
            <div className="msds-text-header-3-light">
              {t("pages.signUp.noMatch.companyInformation")}:
            </div>
            {companyInformationFields.map((data, index) => {
              return (
                <div key={index} className="user-information-page__data-item">
                  <p className="msds-text-body-2-bold">{data.label}</p>
                  <p className="msds-text-body-1">
                    {formatedEnteredCompanyData[data.field]}
                  </p>
                </div>
              );
            })}
            <p>
              <Trans i18nKey={"pages.signUp.noMatch.message1"}>
                Go back to edit the information or add additional information in the form below that will help us validate your company information. We will get back to you as soon as we have resolved the issue.
              </Trans>
            </p>
          </div>
          <div className="user-information-page__data-section">
            <SignupSendSupportEmailForm telephoneCodes={telephoneCodes} regioncountries={regioncountries} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default ContactSupport;
