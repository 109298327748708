class Animations
{
    static appendClassFirst(element, cls) {
        if (element == null)
            return;
        element.className = (cls + " " + element.className).trim();
        //element.classList.add(cls);
    }

    static fadeIn(element, onFinished) {
        //element.classList.add('fade-in');
        this.appendClassFirst(element, 'fade-in');
        element.classList.remove('fade-out');

        if (onFinished != null) {
            onFinished();
        }
    }

    static fadeOut(element, onFinished) {
        //element.classList.add('fade-out');
        this.appendClassFirst(element, 'fade-out');
        element.classList.remove('fade-in');

        if (onFinished != null) {
            onFinished();
        }
    }

    static slideDown(element, onFinished) {
        element.classList.remove('slide-up');
        //element.classList.add('slide-down');
        this.appendClassFirst(element, 'slide-down');

        if (onFinished != null) {
            onFinished();
        }
    }

    static slideUp(element, onFinished) {
        //element.classList.add('slide-up');
        this.appendClassFirst(element, 'slide-up');
        element.classList.remove('slide-down');

        if (onFinished != null) {
            onFinished();
        }
    }

    static slideRight(element, onFinished) {
        element.classList.remove('slide-left');
        //element.classList.add('slide-right');

        /* Removed due to display none !important causes bugs when slide-right is not cleaned up after the animation
         * Before adding the animation we need to fix cleanup after it.
         * this.appendClassFirst(element, 'slide-right');
         */

        if (onFinished != null) {
            onFinished();
        }
    }

    static slideLeft(element, onFinished) {
        element.classList.remove('slide-right');
        //element.classList.add('slide-left');
        this.appendClassFirst(element, 'slide-left');

        if (onFinished != null) {
            onFinished();
        }
    }
}

export default Animations;