import i18next from "i18next";

const languageCodes = ["en", "de"];

const languageNamesToKeys = {
  English: "en",
  Deutsch: "de",
};

export const languageKeysToNames = {
  en: "English",
  de: "Deutsch",
};

export const getLanguageUrlParam = () => {
  return i18next.language === "en" ? "" : `/${i18next.language}`;
};

export const setLanguageUrlParamOnLoad = (
  currentPath = window.location.pathname
) => {
  const language = currentPath.split("/")[1];
  if (Object.values(languageNamesToKeys).includes(language)) {
    i18next.changeLanguage(language);
  }
};

export const changeLanguageAndUrl = (event, i18n, navigate) => {
  //change language
  const newLanguageName = event.target.innerText;
  const newLanguage = languageNamesToKeys[newLanguageName];
  i18n.changeLanguage(newLanguage);

  //change url accordingly
  const pathNameArray = window.location.pathname.split("/");
  const firstPathNameParam = pathNameArray[1];
  if (languageCodes.includes(firstPathNameParam)) {
    if (newLanguage === "en")
      pathNameArray.length === 2
        ? (pathNameArray[1] = "")
        : pathNameArray.splice(1, 1);
    else pathNameArray[1] = newLanguage;
  } else {
    if (newLanguage !== "en")
      pathNameArray[1] === ""
        ? (pathNameArray[1] = newLanguage)
        : pathNameArray.splice(1, 0, newLanguage);
  }
  navigate(pathNameArray.join("/"));
};
