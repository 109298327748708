import { createSlice } from "@reduxjs/toolkit";
import { Status } from "lib/enums";
import { postAdminDeclineUserApplicationThunk as postAdminDeclineUserApplication } from "./actions";

export type AdminDeclineUserApplicationData = {
  companyName: string;
  userFullName: string;
};

type AdminDeclineUserProfileApplicationState = {
  postAdminDeclineUserApplicationStatus: Status;
  adminDeclineUserApplicationTempData: AdminDeclineUserApplicationData;
  backendErrors: {
    postAdminDeclineUserApplicationErrorMsg: string;
  };
};

const initialState: AdminDeclineUserProfileApplicationState = {
  postAdminDeclineUserApplicationStatus: Status.Idle,
  adminDeclineUserApplicationTempData: {} as AdminDeclineUserApplicationData,
  backendErrors: {
    postAdminDeclineUserApplicationErrorMsg: "",
  },
};

const adminDeclineUserApplicationSlice = createSlice({
  name: "adminDeclineUserApplication",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postAdminDeclineUserApplication.pending, (state) => {
        state.postAdminDeclineUserApplicationStatus = Status.Pending;
      })
      .addCase(postAdminDeclineUserApplication.rejected, (state, action) => {
        state.backendErrors.postAdminDeclineUserApplicationErrorMsg =
          action.payload.data;
        state.postAdminDeclineUserApplicationStatus = Status.Rejected;
      })
      .addCase(postAdminDeclineUserApplication.fulfilled, (state, action) => {
        state.adminDeclineUserApplicationTempData = action.payload.data;
        if (state.backendErrors.postAdminDeclineUserApplicationErrorMsg !== "")
          state.backendErrors.postAdminDeclineUserApplicationErrorMsg = "";
        state.postAdminDeclineUserApplicationStatus = Status.Success;
      });
  },
});

export default adminDeclineUserApplicationSlice.reducer;
