import classnames from "classnames/bind";
import { ButtonSize, ButtonStyles } from "lib/enums";

const FormButton = ({
  id,
  label,
  size,
  styling,
  disabled,
  eventCategory,
  eventLabel,
  onClickHandler
}: FormButtonProps) => {
  return (
    <button
      formNoValidate
      id={id && id}
      type="submit"
      disabled={disabled ?? false}
      className={classnames(
        "msds-btn",
        styling ? styling : ButtonStyles.Secondary,
        size ? size : ButtonSize.Medium
      )}
      onClick={onClickHandler}
      data-event-category={eventCategory}
      data-event-label={eventLabel}
    >
      {label}
    </button>
  );
};

export default FormButton;

type FormButtonProps = {
  id: string;
  label: string;
  size?: ButtonSize;
  styling?: ButtonStyles;
  disabled?: boolean;
  eventCategory?: string;
  eventLabel?: string;
  onClickHandler?: () => void;
};
