import spritemap from "@milestone-sys/web-design-system/msds-spritemap.svg";
import classnames from "classnames/bind";
import { useEffect, useState } from "react";
import { useValidation, ValidationTypes } from "../formHooks/useValidation";

const Select = ({
  id,
  options,
  defaultOption,
  initialValue,
  initialMessage,
  validation,
  makeInvalid,
  handleDataChange,
}: SelectProps) => {
  const [value, setValue] = useState<string>(initialValue ?? "");
  const { isValid, message } = useValidation(value, validation, initialMessage);

  let optionElements = options.map((opt) => {
    return (
      <option key={opt.label} value={opt.value}>
        {opt.label}
      </option>
    );
  });

  useEffect(() => {
    if (initialValue) setValue(initialValue);
  }, [initialValue]);

  return (
    <div
      className={classnames(
        "msds-select-input",
        (isValid === false || makeInvalid) && "msds-input--error"
      )}
    >
      <select
        id={id}
        value={value}
        autoComplete="no"
        className={`msds-select-input__select + ${value != defaultOption.value && "msds-select-input__selected-option"}`}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={() => handleDataChange(value, isValid)}
      >
        <option value={defaultOption.value} disabled>
          {defaultOption.label}
        </option>
        {optionElements}
      </select>
      <div className="msds-select-input__icon">
        <svg>
          <use href={`${spritemap}#right-arrow`} />
        </svg>
      </div>
      <p className="msds-input__info-message">
        {isValid === true && !makeInvalid && message.info && message.info}
        {(isValid === false || makeInvalid) && message && message.validation}
      </p>
    </div>
  );
};

export default Select;

type SelectProps = {
  id: string;
  options: { label: string; value: string }[];
  defaultOption: { label: string; value: string };
  initialValue?: string;
  initialMessage?: { info?: string; validation: string };
  validation?: ValidationTypes;
  makeInvalid?: boolean;
  handleDataChange: (newValue: any, isValid: boolean) => void;
};
