import device from "./device";
import domHelper from "./domHelper";
import animations from "./animations";
import burgerMenu from "./burgerMenu";
import signInMenu from "./signInMenu";

class SignInMenu {
    static initialize() {
        document.querySelector(".account-menu-link").onclick =
            SignInMenu.onClick;
    }

    static onClick() {
        if (domHelper.hasClass(this, "close")) {
            SignInMenu.close();
        } else {
            SignInMenu.open();
        }
    }

    static open(onSignMenuOpened) {
        if (
            domHelper.hasClass(
                document.querySelector(".burger-menu-link"),
                "close"
            )
        ) {
            burgerMenu.close(signInMenu.open);
        } else {
            document.querySelector(".account-menu-link").classList.add("close");
            document
                .querySelector(".signin-menu-mobil")
                .classList.add("active");

            animations.slideLeft(
                document.querySelector(
                    ".signin-menu-mobil .navigation-overlay"
                ),
                function () {
                    document
                        .querySelector(".signin-menu-mobil .navigation-overlay")
                        .classList.add("active");
                }
            );
        }
    }

    static close(onSignMenuClosed) {
        var accountMenuLink = document.querySelector(".account-menu-link");

        if (domHelper.hasClass(accountMenuLink, "close")) {
            animations.slideRight(
                document.querySelector(
                    ".signin-menu-mobil .navigation-overlay"
                ),
                function () {
                    document
                        .querySelector(".signin-menu-mobil")
                        .classList.remove("active");
                    document
                        .querySelector(".signin-menu-mobil .navigation-overlay")
                        .classList.remove("active");
                    burgerMenu.toggle(false);

                    if (onSignMenuClosed) {
                        onSignMenuClosed();
                    }
                }
            );

            document
                .querySelector(".account-menu-link")
                .classList.remove("close");
        }
    }

    static closeSignInMenuForLargerDevice(e) {
        const domSigninMenu = document.querySelector(
            ".menu-button-with-dropdown--signin-menu"
        );
        if (domSigninMenu) {
            this.closeForLargerDevice(domSigninMenu, e);
        }
    }

    static closeForLargerDevice(domContext, e) {
        var domCheckbox = domContext.querySelector(
            ".menu-button-with-dropdown__checkbox"
        );

        if (!device.isVerySmall() && domCheckbox.checked) {
            if (e === null) {
                domCheckbox.checked = false;
                return;
            }

            var domButton = domContext.querySelector(
                ".menu-button-with-dropdown__button"
            );
            if (
                domButton === e.target ||
                domHelper.isDescendant(domButton, e.target)
            ) {
                return;
            }

            var signInSectionLinks = domContext.querySelector(
                ".menu-button-with-dropdown__menu-item"
            ); // This only finds the first one?
            if (
                signInSectionLinks !== e.target ||
                !domHelper.isDescendant(signInSectionLinks, e.target)
            ) {
                domCheckbox.checked = false;
            }
        }
    }
}

export default SignInMenu;
