import utils from "@milestone-sys/utils";
import { PASSWORD_PATTERN } from "@milestone-sys/utils/lib/validation/validate.const";

import {
  alternativeEmailValidationMessages,
  getEmailDomainName,
} from "lib/helpers";
import { useEffect, useState } from "react";

export const useValidation = (
  currentValue: string | string[],
  type = ValidationTypes.NoValidation,
  initialMessage: MessageType = { validation: "" },
  data?: { [key: string]: any }
) => {
  const [message, setMessage] = useState<MessageType>(initialMessage);
  const [isValid, setIsValid] = useState<boolean>(null);

  useEffect(() => {
    if (isValid == null) {
      setIsValid(true);
      return;
    }

    switch (type) {
      case ValidationTypes.NoValidation:
        break;
      case ValidationTypes.HasValue:
        setIsValid(hasValue(currentValue as string));
        break;
      case ValidationTypes.IsEmail:
        setIsValid(isEmail(currentValue as string));
        break;
      case ValidationTypes.IsStrictEmail:
        setIsValid(isStrictEmail(currentValue as string, message, setMessage));
        break;
      case ValidationTypes.IsNumber:
        setIsValid(isNumber(currentValue as string));
        break;
      case ValidationTypes.IsPassword:
        setIsValid(isPassword(currentValue as string));
        break;
      case ValidationTypes.IsConfirmPassword:
        setIsValid(isConfirmPassword(currentValue as string, data?.password));
        break;
      case ValidationTypes.IsCheckboxSelected:
        setIsValid(isCheckboxSelected(currentValue as string[]));
        break;
      case ValidationTypes.IsDunsNumber:
        setIsValid(isDunsNumber(currentValue as string));
        break;
      default:
        break;
    }
  }, [currentValue]);

  return { isValid, message };
};

const hasValue = (value: string) => {
  return value.trim() != "";
};

const isStrictEmail = (
  email: string,
  message: MessageType,
  setMessage: React.Dispatch<React.SetStateAction<MessageType>>
) => {
  const isEmail = !utils.validate.isNotEmail(email);
  if (isEmail && utils.validate.isEmailExcluded(email)) {
    const emailDomainName = getEmailDomainName(email);
    setMessage({
      ...message,
      validation: alternativeEmailValidationMessages[emailDomainName],
    });
    return false;
  }
  return isEmail;
};

const isEmail = (email: string) => {
  const isEmail = !utils.validate.isNotValidEmail(email);
  return isEmail;
};

const isNumber = (value: string) => {
  return !utils.validate.isNotNumber(value);
};

const isPassword = (value: string) => {
  return PASSWORD_PATTERN.test(value.trim());
};

const isConfirmPassword = (value: string, password: string) => {
  return value === password;
};

const isCheckboxSelected = (selected: string[]) => {
  return selected.length > 0;
};

const isDunsNumber = (value: string) => {
  // DUNS Number are optional therefore we only perform the validation if there is a value
  if (value) {
    const dunsRegex = /^\d{9}$/;
    return dunsRegex.test(value);
  }

  return true;
};

export type MessageType = { validation: string; info?: string };

export enum ValidationTypes {
  HasValue,
  IsStrictEmail,
  IsEmail,
  IsNumber,
  IsPassword,
  IsConfirmPassword,
  IsCheckboxSelected,
  IsDunsNumber,
  NoValidation,
}
