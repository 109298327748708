const Popover = ({ placement, header, description, children }: PopoverProps) => {
    return (
        <div className={'msds-popover msds-popover--' + placement}>
            {children}
            <div className={(!header && !description || header.includes('userRoles')) ? 'd-none' : 'msds-popover__item msds-text-gray-10'}>
                <div className="msds-popover__content">
                    <div className="msds-popover__header mb-4">               
                        <div className="ms-2">
                            {header}
                        </div>
                    </div>
                    <div className="msds-popover__description mb-1">
                        <p className="mb-2">
                            {description}
                        </p>                
                    </div>
                </div>
                <div className="msds-popover__arrow">
                </div>
            </div>
        </div>
    );
};      
export default Popover;

type PopoverProps = {   
    placement: PopoverPlacement;     
    header: string;
    description: string;
    children?: React.ReactNode;     
};

export enum PopoverPlacement {
    Top = 'top',
    Bottom = 'bottom',
    left = 'left',
    right = 'right',
}