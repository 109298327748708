import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import {
  getUserTemporaryProfile,
  postUserTemporaryProfile,
  createUserTemporaryProfile,
} from "lib/api";
import { BaseUserType, UserTempDataType } from "lib/types";

export const getUserTemporaryProfileThunk = createAsyncThunk<
  AxiosResponse<UserTempDataType>, // response type
  string, // args type
  { rejectValue: AxiosResponse } // rejection response value type
>(
  "userTemporaryProfile/getUserTemporaryProfile",
  async (id, { rejectWithValue }) => {
    try {
      return await getUserTemporaryProfile(id);
    } catch (err) {
      let error: AxiosError<string> = err;
      return rejectWithValue(error.response);
    }
  }
);

export const postUserTemporaryProfileThunk = createAsyncThunk<
  AxiosResponse, // response type
  object, // args type
  { rejectValue: string } // rejection response value type
>(
  "userTemporaryProfile/postUserTemporaryProfile",
  async (object, { rejectWithValue }) => {
    try {
      return await postUserTemporaryProfile(object);
    } catch (err) {
      let error: AxiosError<string> = err;
      return rejectWithValue(error.response.data);
    }
  }
);

export const createUserTemporaryProfileThunk = createAsyncThunk<
  any,
  BaseUserType,
  { rejectValue: string }
>(
  "userTemporaryProfile/createUserTemporaryProfile",
  async (payload, { rejectWithValue }) => {
    try {
      return await createUserTemporaryProfile(payload);
    } catch (err) {
      let error: AxiosError<string> = err;
      return rejectWithValue(error.response.data);
    }
  }
);
