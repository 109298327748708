import { useEffect, useCallback, useContext } from "react";
import { AuthenticationContext } from "./AuthenticationProvider";
import { RedirectionType } from "./types";
import { useAuthentication, redirectToIDP } from "./authenticationUtilities";

function AuthenticationCallbackPage() {
    const setAuthorization = useAuthentication();
    const authenticationContext = useContext(AuthenticationContext);

    const requestToken = useCallback(async () => {
        await redirectToIDP(authenticationContext.authCallbackConfig, RedirectionType.SigninCallback)
        setAuthorization(authenticationContext.authConfig, true)
    }, [authenticationContext, setAuthorization])

    //Request access token on the page load. Takes authorization code sent by IDP (SSO) and exchange it for access token.
    useEffect(() => {
        requestToken()
    }, [requestToken]);

    return <></>;
}

export default AuthenticationCallbackPage;
