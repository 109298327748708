import SecondaryNavigationMobile from "components/SecondaryNavigation/SecondaryNavigationMobile";
import React from "react";

const MainNavigationMobileLinks = () => {
    return (
        <div className="navigation mymilestone">
            <nav id="MainMenu" className="navigation-overlay slide-right">
                <ul className="nav navbar-nav menuitems">
                    <SecondaryNavigationMobile />
                    {/* <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.milestonesys.com/my-milestone/"
                        >
                            My Portals
                        </a>
                    </li>
                    <li>
                        <a
                            data-category="NewNavigation"
                            data-label="MM_MyDetails"
                            data-enable-google-event-tracking="True"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.milestonesys.com/my-milestone/my-profile/my-details/"
                            className="googlelinktracking"
                        >
                            My Details
                        </a>
                    </li>
                    <li>
                        <a
                            data-category="NewNavigation"
                            data-label="MM_MyCertifications"
                            data-enable-google-event-tracking="True"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.milestonesys.com/my-milestone/my-profile/my-certifications/"
                            className="googlelinktracking"
                        >
                            My Certifications
                        </a>
                    </li>
                    <li>
                        <a
                            data-category="NewNavigation"
                            data-label="MM_MyUsers"
                            data-enable-google-event-tracking="True"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.milestonesys.com/my-milestone/my-profile/my-users/"
                            className="googlelinktracking"
                        >
                            My Users
                        </a>
                    </li>
                    <li>
                        <a
                            data-category="NewNavigation"
                            data-label="MM_MyResellers"
                            data-enable-google-event-tracking="True"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.milestonesys.com/my-milestone/distributor/reseller-overview/"
                            className="googlelinktracking"
                        >
                            My Resellers
                        </a>
                    </li>

                    <div
                        className="show-mobile header-links"
                        style={{ height: "264px" }}
                    >
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.milestonesys.com/community/find-a-milestone-partner/"
                        >
                            Where to Buy
                        </a>
                        <span>|</span>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.milestonesys.com/about-us/"
                        >
                            About Us
                        </a>
                        <span>|</span>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.milestonesys.com/about-us/career/"
                        >
                            Career
                        </a>
                        <span>|</span>
                    </div> */}
                </ul>
            </nav>
        </div>
    );
};

export default MainNavigationMobileLinks;
