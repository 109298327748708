import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse, AxiosError } from "axios";
import { getLicenses } from "lib/api";
import { LicenseType } from "lib/types";

export const getLicensesThunk = createAsyncThunk<
  AxiosResponse<LicenseType[]>,
  void,
  { rejectValue: AxiosError<any> }
>("licenses/getLicenses", async (_, { rejectWithValue }) => {
  try {
    return await getLicenses();
  } catch (err) {
    let error: AxiosError = err;
    return rejectWithValue(error);
  }
});
