import { AES } from "crypto-js";
import Utf8 from "crypto-js/enc-utf8";
import { RegisterLicenseFlowData } from "./types";

export const toShortDate = (dateString) => {
  if (dateString) {
    let date = new Date(dateString);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0");
    let yyyy = date.getFullYear();
    return dd + "/" + mm + "/" + yyyy;
  }
  return "";
};

const substitutionsAfterEncryption: Map<string, string> = new Map([
  ["+", "-"],
  ["/", "_"],
  ["=", "~"],
]);
const substitutionsBeforeDecryption: Map<string, string> = new Map([
  ["-", "+"],
  ["_", "/"],
  ["~", "="],
]);

export const encrypt = (
  plaintext: string,
  secretPassphrase: string,
  URLUsage: boolean = false
) => {
  const encryptedString = AES.encrypt(plaintext, secretPassphrase).toString();
  if (URLUsage) {
    return encryptedString.replace(
      /[+/=]/g,
      (match: string) => substitutionsAfterEncryption.get(match) ?? match
    );
  }

  return encryptedString;
};

export const decrypt = (
  cipher: string,
  secretPassphrase: string,
  URLUsage: boolean = false
) => {
  let stringToDecrypt = cipher;
  if (URLUsage) {
    stringToDecrypt = cipher.replace(
      /[-_~]/g,
      (match: string) => substitutionsBeforeDecryption.get(match) ?? match
    );
  }

  return AES.decrypt(stringToDecrypt, secretPassphrase).toString(Utf8);
};

export const getEmailDomainName = (value: string) => {
  const emailDomainName = value
    .trim()
    .split("@")[1]
    .split(".")[0]
    .toLowerCase();
  return emailDomainName;
};

export const alternativeEmailValidationMessages = {
  amazon:
    "Hello Amazonian, You have attempted to create an account with Milestone Systems using your corporate Amazon email account. Amazon has a company wide deployment of Milestone supported internally by the Security Integrations Group (SIG). Should you need assistance with the Milestone product or have any questions about its deployment in Amazon, please contact the SIG team using the GSO portal at https://security-central.amazon.com/sp/gsoportal, and searching for ‘Milestone’. Please note, you will need to be connected to the Amazon corporate network to utilize this resource. If this sign up was unrelated to Amazon, please sign up to Milestone using a personal or non-Amazon email address",
  milestone:
    "This form should not be used for internal account creation. Please reach out to your local sales support team to create a login.",
  milestonesys:
    "This form should not be used for internal account creation. Please reach out to your local sales support team to create a login.",
};

export const clearSessionRegisterLicenseData = () => {
  sessionStorage.removeItem("registerLicenseFlowData")
}

export const getRegisterLicenseFlowDataFromSessionStorage = () => {  
  const data = sessionStorage.getItem('registerLicenseFlowData');
  if (data) {
    return JSON.parse(data) as RegisterLicenseFlowData;
  } else {
    return null;
  }
}

export const toggleRegisterLicenseInSessionStorage = () => {
  const registerLicenseFlowData = getRegisterLicenseFlowDataFromSessionStorage()
  if (registerLicenseFlowData) {
    const updatedData = {
      ...registerLicenseFlowData,
      isRegisterLicenseFlow: !registerLicenseFlowData.isRegisterLicenseFlow,
    };
    sessionStorage.setItem('registerLicenseFlowData', JSON.stringify(updatedData));    
  }
};

export const getSoftwareLicenseAndTokenFromUrlString = (url: string) => {
    const slcStart = url.indexOf('slc=') + 4; // add 4 to skip over the 'slc=' part
    const slcEnd = url.indexOf('&', slcStart); // search for the next '&' starting from slcStart index
    const slcValue = url.slice(slcStart, slcEnd);

    const tokenStart = url.indexOf('token=') + 6; // add 6 to skip over the 'token=' part
    const tokenEnd = url.length;
    const tokenValue = url.slice(tokenStart, tokenEnd);
    const registerLicenseFlowData:  RegisterLicenseFlowData = {
      slc: slcValue,
      token: tokenValue,
    }
    return registerLicenseFlowData
}
