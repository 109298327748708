import { createSlice } from "@reduxjs/toolkit";
import { Status } from "lib/enums";
import { postUpdatedEmailConfirmationThunk as postUpostUpdatedEmailConfirmation } from "./actions";

type AdminAddNewUserRequestState = {
  postUpdatedEmailConfirmationtatus: Status;
  backendErrors: {
    postUpdatedEmailConfirmationErrorMsg: string;
  };
};

const initialState: AdminAddNewUserRequestState = {
  postUpdatedEmailConfirmationtatus: Status.Idle,
  backendErrors: {
    postUpdatedEmailConfirmationErrorMsg: "",
  },
};

const adminAddNewUserRequestSlice = createSlice({
  name: "adminDeclineUserApplication",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postUpostUpdatedEmailConfirmation.pending, (state) => {
        state.postUpdatedEmailConfirmationtatus = Status.Pending;
      })
      .addCase(postUpostUpdatedEmailConfirmation.rejected, (state, action) => {
        state.postUpdatedEmailConfirmationtatus = Status.Rejected;
        state.backendErrors.postUpdatedEmailConfirmationErrorMsg =
          action.payload.title;
      })
      .addCase(postUpostUpdatedEmailConfirmation.fulfilled, (state) => {
        state.postUpdatedEmailConfirmationtatus = Status.Success;
      });
  },
});

export default adminAddNewUserRequestSlice.reducer;
