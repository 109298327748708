import LeftSideColumn from "components/LeftSideColumn/LeftSideColumn";
import RightSideColumn from "components/RightSideColumn/RightSideColumn";
import { Status } from "lib/enums";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { NavLink, useParams } from "react-router-dom";
import { postUpdatedEmailConfirmationThunk } from "redux/api/userUpdatedEmailConfirmation/actions";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

const UserUpdateEmailConfirmationPage = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useAppDispatch();

    const postUpdatedEmailConfirmationtatus = useAppSelector((state) => state.userUpdatedEmailConfirmation.postUpdatedEmailConfirmationtatus);

    const backendErrorMessage = useAppSelector((state) => state.userUpdatedEmailConfirmation.backendErrors.postUpdatedEmailConfirmationErrorMsg);

    const handleActivateAccountClick = () => {
        dispatch(postUpdatedEmailConfirmationThunk(id));
    };

    useEffect(() => {
        handleActivateAccountClick();
    }, []);

    return (
        <>
            <div className="container-fluid flex-grow-1">
                <div className="user-portal__split-container row flex-column flex-lg-row">
                    <LeftSideColumn>
                        <div className="user-portal__user-updated-email-confirmation">
                            <div className="msds-text-gray-10 msds-text-header-2">{t("pages.userUpdateEmail.title")}</div>
                            <div className="breaker msds-bg-clear-blue" />
                            {postUpdatedEmailConfirmationtatus === Status.Success && (
                                <p className="msds-text-gray-10 msds-text-body-1">
                                    <Trans i18nKey={"pages.userUpdateEmail.description"}>
                                        Your email has been updated successfully. Please go to
                                        <NavLink className="navigation__links" to="/edit-user-details">
                                            My Details
                                        </NavLink>
                                        to see the changes.
                                    </Trans>
                                </p>
                            )}
                            {postUpdatedEmailConfirmationtatus === Status.Rejected && (
                                <div className="msds-input msds-input--error">
                                    <p className="msds-input__info-message">{backendErrorMessage}</p>
                                </div>
                            )}
                        </div>
                    </LeftSideColumn>
                    <RightSideColumn />
                </div>
            </div>
        </>
    );
};

export default UserUpdateEmailConfirmationPage;
