const domHelper = {
    getIndex(element) {
        var children = element.parentNode.children;
        var index = 0;

        for (var child in children) {
            if (children[child] === element) {
                return index;
            }

            index++;
        }
    },

    hasClass(element, className) {
        if (!element) return;

        return new RegExp('(\\s|^)' + className + '(\\s|$)').test(element.className);
    },

    isDescendant(parent, descendant) {
        if (parent && descendant) {
            return (parent === descendant)
                ? false
                : parent.contains(descendant);
        } else {
            return false;
        }        
    },

    toArray(collection) {
        var array = [];

        for (var index = 0; index < collection.length; index++) {
            array.push(collection[index]);
        }

        return array;
    }
}

export default domHelper;
