import { SignupSteps } from "lib/enums";
import { useAppSelector } from "redux/hooks";
import EnterCompanyData from "./SingupSteps/1-EnterCompanyData/EnterCompanyData";
import SelectCompany from "./SingupSteps/2-SelectCompany/SelectCompany";
import ContactSupport from "./SingupSteps/2-NoMatch-ContactSupport/ContactSupport";
import OverviewAndSubmissionStep from "./SingupSteps/4.OverviewAndSubmissionStep.tsx/OverviewAndSubmissionStep";
import EnterUserData from "./SingupSteps/3-EnterUserData/EnterUserData";
import FormConfirmation from "components/FormConfirmation/FormConfirmation";
import ContactSupportConfirmation from "./SingupSteps/2-NoMatch-ContactSupportConfirmation/ContactSupportConfirmation";
import SignUpConfirmation from "./SingupSteps/5-SignUpConfirmation/SignUpConfirmation";

const SignUpPage = () => {
  const signupStep = useAppSelector((state) => state.signup.step);
  const matchedCompanies = useAppSelector(
    (state) => state.signup.matchedCompanies
  );

  const signupFormDataLayer = {
    dataLayer: {
      event: "Form_submission",
      eventCategory: "User Portal",
      eventLabel: "Sign-up Form",
    },
  };

  const page = {
    [SignupSteps.EnterCompanyData]: <EnterCompanyData />,
    [SignupSteps.SelectCompany]: (
      <SelectCompany matchedCompanies={matchedCompanies} />
    ),
    [SignupSteps.ContactSupport]: <ContactSupport />,
    [SignupSteps.ContactSupportConfirmation]: (
      <FormConfirmation content={<ContactSupportConfirmation />} />
    ),
    [SignupSteps.EnterUserData]: <EnterUserData />,
    [SignupSteps.OverviewAndSubmission]: <OverviewAndSubmissionStep />,
    [SignupSteps.SignupConfirmation]: (
      <FormConfirmation
        gtmDataLayer={signupFormDataLayer}
        content={<SignUpConfirmation />}
      />
    ),
  };

  return page[signupStep];
};

export default SignUpPage;
