import { createSlice } from "@reduxjs/toolkit";
import { getConfigurationListsThunk as getConfigurationLists } from "./actions";
import { Status } from "../../lib/enums";

export type ConfigurationListsState = {
  PreferredLanguages: { label: string; value: string }[] | null;
  CountryTelephoneCodes: { label: string; value: string }[] | null;
  RegionCountries: { label: string; value: string }[] | null;
  states: {
    USStates: { label: string; value: string }[] | null;
    CanadaStates: { label: string; value: string }[] | null;
    AustraliaStates: { label: string; value: string }[] | null;
    IndiaStates: { label: string; value: string }[] | null;
  };

  status: Status;
};

const initialState: ConfigurationListsState = {
  PreferredLanguages: null,
  CountryTelephoneCodes: null,
  RegionCountries: null,
  states: {
    USStates: null,
    CanadaStates: null,
    AustraliaStates: null,
    IndiaStates: null,
  },
  status: Status.Idle,
};

const configurationListsSlice = createSlice({
  name: "configurationLists",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConfigurationLists.pending, (state) => {
        state.status = Status.Pending;
      })
      .addCase(getConfigurationLists.rejected, (state) => {
        state.status = Status.Rejected;
      })
      .addCase(getConfigurationLists.fulfilled, (state, action) => {
        // Make all necessary transformations to data so that global state is
        // compatible with our local formData inside the CrateLoginForm component
        if (action.payload.listName === "CountryTelephoneCodes") {
          const newData = action.payload.data.map((e) => {
            const code = e.value.replace("00", "+");
            return { label: e.key + " (" + code + ")", value: e.key };
          });
          state[action.payload.listName] = newData;
        } else {
          action.payload.listName.includes("States")
            ? (state.states[action.payload.listName] = action.payload.data.map(
                (e) => {
                  return { label: e.value, value: e.key };
                }
              ))
            : (state[action.payload.listName] = action.payload.data.map((e) => {
                return { label: e.value, value: e.key };
              }));
        }
        state.status = Status.Success;
      });
  },
});

export default configurationListsSlice.reducer;
