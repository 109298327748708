import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { getConfigurationList } from "../../lib/api";

export const getConfigurationListsThunk = createAsyncThunk<
  { data: Array<{ key: string; value: string }>; listName: string }, // response type
  string, // args type
  { rejectValue: string } // rejection response value type
>(
  "configurationLists/getConfigurationList",
  async (configurationListName: string, { rejectWithValue }) => {
    try {
      return await getConfigurationList(configurationListName);
    } catch (err) {
      let error: AxiosError<string> = err;
      return rejectWithValue(error.response.data);
    }
  }
);
