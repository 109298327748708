import bannerImage from "./banner-image.jpg";
import "./right-side-column.scss";

function RightSideColumn({ children }: RightSideColumnProps) {
    return (
        <div className="user-portal__split-container-right-side-container col-12 col-lg-6 offset-lg-1 pl-0 pl-lg-2 pr-0">
            <div
                className={
                    children
                        ? "user-portal__right-container user-portal--right-container-content msds-bg-mixed-blue-gradient"
                        : "user-portal__right-container"
                }
                style={
                    !children
                        ? {
                            backgroundImage: `linear-gradient( rgba(0,0,0,0) 100%, #FFF 100%), url(${bannerImage})`,
                        }
                        : {}
                }
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">{children}</div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default RightSideColumn;

type RightSideColumnProps = {
    children?: React.ReactNode;
};
