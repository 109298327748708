import {
  CompanyVerificationResponseDataType,
  CompanyVerificationResponseWithMatches,
  Nullable,
} from "lib/types";

// This function uses a very helpful functionality of TypeScript called "type predicates".
// They help us to narrow down the function's parameter to a specific type. (In this case CompanyVerificationResponseWithMatches)
// Check them here: https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
export const matchesFound = (
  data: Nullable<CompanyVerificationResponseDataType>
): data is CompanyVerificationResponseWithMatches => {
  if (data == null) {
    return false;
  } else {
    return (
      (data as CompanyVerificationResponseWithMatches).matchCandidates !=
      undefined
    );
  }
};
