import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { useTranslation } from "react-i18next";
import Routing from "../../routing/Routing";
import LoaderOverlay from "../LoaderOverlay/LoaderOverlay";
import {
    setLanguageUrlParamOnLoad,
    changeLanguageAndUrl,
} from "../../localization/helpers";
import { getMyDetailsThunk } from "redux/api/userDetails/actions";
import { getMySubscriptionThunk } from "redux/api/subscription/actions";
import { getUserDetailsCompanyThunk } from "redux/api/userDetails/actions";
import { getUserRolesThunk } from "redux/userRoles/userRolesSlice";
import { getConfigurationListsThunk } from "redux/configurationLists/actions";
import { getLicensesThunk } from "redux/licenses/actions";
import { selectIsAuthenticated } from "redux/user/userSlice";
import "./app.scss";
import "@milestone-sys/web-design-system/main.css";
import MainNavigation from "components/Navigation/MainNavigation";
import { AuthenticationContext } from "components/Authentication/AuthenticationProvider";
import { useHideNavBaseOnLocation } from "lib/hooks";

function App() {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const authenticationContext = useContext(AuthenticationContext);
    const isNavigationHidden = useHideNavBaseOnLocation()

    const isAuthenticated = useAppSelector(selectIsAuthenticated);

    const isLoading: boolean = useAppSelector((state) => state.app.isLoading);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getUserDetailsCompanyThunk());
            dispatch(getUserRolesThunk());
            dispatch(getMyDetailsThunk());
            dispatch(getMySubscriptionThunk());
            dispatch(getLicensesThunk());
        }
    }, [isAuthenticated]);

    useEffect(() => {
        dispatch(getConfigurationListsThunk("RegionCountries"));
        dispatch(getConfigurationListsThunk("CountryTelephoneCodes"));
        dispatch(getConfigurationListsThunk("PreferredLanguages"));
        dispatch(getConfigurationListsThunk("USStates"));
        dispatch(getConfigurationListsThunk("IndiaStates"));
        dispatch(getConfigurationListsThunk("CanadaStates"));
        dispatch(getConfigurationListsThunk("AustraliaStates"));
    }, [dispatch]);

    useEffect(() => {
        setLanguageUrlParamOnLoad();
    }, []);

    return (
        <>
            <LoaderOverlay isLoading={isLoading} />
            {!isNavigationHidden && <MainNavigation onLoginButtonClick={authenticationContext.handleLoginButtonClick} />}
            <Routing />
        </>
    );
}

export default App;
