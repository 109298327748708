import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { postUpdatedEmailConfirmation } from "lib/api";

export const postUpdatedEmailConfirmationThunk = createAsyncThunk<
  AxiosResponse, // response type
  string,
  { rejectValue: any } // rejection response value type
>(
  "adminAddNewUserRequest/postUserTemporaryProfileAdmin",
  async (id, { rejectWithValue }) => {
    try {
      return await postUpdatedEmailConfirmation(id);
    } catch (err) {
      let error: AxiosError<any> = err;
      return rejectWithValue(error.response.data);
    }
  }
);
