import MainNavigationMobile from "./MainNavigationMobile";
import MainNavigationDesktop from "./MainNavigationDesktop";
import "./headerblock";
import "./header.scss";
import "./footer.scss";
import MainNavigationDesktopLinks from "./MainNavigationDesktopLinks";
import MainNavigationMobileLinks from "./MainNavigationMobileLinks";
import { MILESTONE_URL } from "config";
import { useWindowSize } from "./device";

const MainNavigation = (props: MainNavigationProps) => {
  const [width] = useWindowSize();
  const desktopSize = 769;
  let isDesktop = false;
  isDesktop = (width >= desktopSize) ? true : false;

  return (
    <div id="HeaderSection">
      <div className="row no-gutters">
        <div className="header col-12">
          <div className="header-container desktop">
            <div className="links">
              <div className="milestone-logo">
                <a
                  rel="noopener noreferrer"
                  href={MILESTONE_URL()}
                  className="headerlogolink"
                ></a>
              </div>

              <MainNavigationMobile
                onLoginButtonClick={props.onLoginButtonClick}
              />

              <MainNavigationDesktop
                onLoginButtonClick={props.onLoginButtonClick}
              />

              <MainNavigationDesktopLinks />

              {!isDesktop && (
                <MainNavigationMobileLinks />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export type MainNavigationProps = {
  onLoginButtonClick: () => void;
};

export default MainNavigation;
