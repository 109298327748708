import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as AlertmarkIcon } from "@milestone-sys/web-design-system/svg/rich/warning.svg";
import { MILESTONE_URL } from "config";

const SignUpError = ({companyName: companyName, submissionStatusCode: submissionStatusCode, specificErrorMessage: specificErrorMessage, timestamp: timestamp }) => {
  const { t } = useTranslation();

  let headline;
  let message1;
  let message2;
  let message3;
    
  if(submissionStatusCode === 404) {
    headline = t("pages.signUp.submissionErrorNoAdmin.title");
    message1 = t("pages.signUp.submissionErrorNoAdmin.description.message1", {selectedCompany: companyName});
    message2 = t("pages.signUp.submissionErrorNoAdmin.description.message2");
    message3 = t("pages.signUp.submissionErrorNoAdmin.description.message3");
  }

  else if(submissionStatusCode === 400 && specificErrorMessage?.length > 0) {
    headline = specificErrorMessage;
    message1 = "";
    message2 = "";
    message3 = "";
  }

  else {
    message3 = t("pages.signUp.submissionError.description.message3");

    return (
      <div className="msds-input msds-input--error">
        <div className='text-center'>
          <AlertmarkIcon className="overview-submission-step__no-admin-error-icon" />
          <div className="msds-text-lead-bold">{t("pages.signUp.submissionError.title")}</div>
          <p className='msds-text-header-3-bold'>{t("pages.signUp.submissionError.description.message1")}</p>
          <p className='msds-text-header-3-bold'> 
            <Trans
              components={{
                supportLink: (
                  <a target="_blank" href={`${MILESTONE_URL()}/support/contact-us/contact-us/sales-and-other-inquiries/`} />
                ),
              }}
            >
              {t("pages.signUp.submissionError.description.message2")}
            </Trans>
          </p>                 
          <p className='msds-text-header-3-bold'>{t("pages.signUp.submissionError.description.message3", { timestamp: timestamp || "" })}</p>              
          <p className='msds-text-header-3-bold'>{t("pages.signUp.submissionError.description.message4")}</p>              
        </div>
      </div>
    )
  }

  return (
    <div className="msds-input msds-input--error">
      <div className='text-center'>
        <AlertmarkIcon className="overview-submission-step__no-admin-error-icon" />
        <div className="msds-text-lead-bold">{headline}</div>
        {(message1 && message1.length > 0) &&           
          <p className='msds-text-header-3-bold'>{message1}</p>
        }                 
        {(message2 && message2.length > 0) &&           
          <p className='msds-text-header-3-bold'>{message2}</p>                 
        }              
        {(message3 && message3.length > 0) &&           
          <p className='msds-text-header-3-bold'>{message3}</p>              
        }
      </div>
    </div>
  )
}

export default SignUpError