import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { UpdateSubscriptionType, SubscriptionType } from "lib/types";
import { getMySubscription, updateMySubscription } from "../../../lib/api";

export const getMySubscriptionThunk = createAsyncThunk<
  SubscriptionType, // response type
  void, // args type
  { rejectValue: string } // rejection response value type
>("subscription/getMyubscription", async (_, { rejectWithValue }) => {
  try {
    return await getMySubscription();
  } catch (err) {
    let error: AxiosError<string> = err;
    return rejectWithValue(error.response.data);
  }
});

export const updateMySubscriptionThunk = createAsyncThunk<
  any, // response type
  UpdateSubscriptionType, // args type
  { rejectValue: string } // rejection response value type
>("subscription/updateSubscription", async (payload, { rejectWithValue }) => {
  try {
    return await updateMySubscription(payload);
  } catch (err) {
    let error: AxiosError<string> = err;
    return rejectWithValue(error.response.data);
  }
});
