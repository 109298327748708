import burgerMenu from "./burgerMenu";
import signInMenu from "./signInMenu";
import device from "./device";
import domHelper from "./domHelper";
import animations from "./animations";

var headerBlock = {
    prevScrollpos: 0,
    loadedWidth: device.isCurrentClientWidth(),

    initialize: function () {
        setTimeout(() => {            
            if (document.querySelector("#HeaderSection .header-container") == null)
                return;
    
            burgerMenu.initialize();
            signInMenu.initialize();
    
            window.onresize = headerBlock.onWindowResize;
    
            headerBlock.setNavigationBarDisplay();
    
            document.onclick = function (event) {
                headerBlock.onOutsideHeaderClick(event);
            };
    
            var subMenuIsPresent = document.querySelector(".firstline");
            if (subMenuIsPresent) {
                headerBlock.adjustLayout();
            }
    
            document
                .querySelectorAll(".logout")
                .forEach((element) => (element.onclick = headerBlock.logout));
        }, 500);
    },


    onNavigationHover: function () {
        if (!domHelper.hasClass(this, "selected")) {
            const $navigation = this.closest(".navigation");
            const $navNavbarNav = document.querySelector(".nav.navbar-nav");
            if ($navigation && $navNavbarNav) {
                const $navTabBox = $navigation.querySelectorAll(".nav-tab-box");
                const $menuLinkContainer =
                    $navigation.querySelector(".nav-tab-boxes");
                if ($navTabBox && $menuLinkContainer) {
                    const $navNavbarNavASelected =
                        $navNavbarNav.querySelectorAll("a.selected");
                    domHelper
                        .toArray($navNavbarNavASelected)
                        .forEach(function (element) {
                            element.classList.remove("selected");
                        });
                    this.classList.add("selected");
                    domHelper.toArray($navTabBox).forEach(function (element) {
                        animations.fadeOut(element);
                    });
                    const $menuLinkItem =
                        $navTabBox[domHelper.getIndex(this.parentNode)];
                    if ($menuLinkItem) {
                        animations.fadeIn($menuLinkItem);
                        animations.slideDown($menuLinkContainer);
                    }
                }
            }
        }
    },

    onNavigationLeave: function () {
        const $navigation = this.closest(".navigation");
        const $navNavbarNav = document.querySelector(".nav.navbar-nav");
        if ($navigation && $navNavbarNav) {
            const $navTabBox = $navigation.querySelectorAll(".nav-tab-box");
            const $navTabBoxes = $navigation.querySelector(".nav-tab-boxes");
            if ($navTabBox && $navTabBoxes) {
                const $navNavbarNavASelected =
                    $navNavbarNav.querySelectorAll("a.selected");
                domHelper.toArray($navTabBox).forEach(function (element) {
                    animations.fadeOut(element);
                });
                if ($navTabBoxes) {
                    animations.slideUp($navTabBoxes);
                }
                domHelper
                    .toArray($navNavbarNavASelected)
                    .forEach(function (element) {
                        element.classList.remove("selected");
                    });
            }
        }
    },

    onOutsideHeaderClick: function (event) {
        if (device.isVerySmall() || device.isSmall()) {
            var navigationContainer = document.querySelector(".navigation");
            var signInNavigation = document.querySelector(".signin-menu-mobil");

            var shouldCloseMenu =
                navigationContainer === event.target ||
                signInNavigation === event.target;

            if (shouldCloseMenu) {
                signInMenu.close();
                burgerMenu.close();
            }
        } else {
            const domSigninMenu = document.querySelector(
                ".menu-button-with-dropdown--signin-menu"
            );

            if (!domHelper.isDescendant(domSigninMenu, event.target)) {
                signInMenu.closeSignInMenuForLargerDevice(event);
            }
        }
    },

    setNavigationBarDisplay: function () {
        var headerSection = document.querySelector("#HeaderSection");
        var headerContainer = document.querySelector(".header-container");

        if (device.isLarger()) {
            headerSection.classList.remove("smalldevices");
            headerContainer.classList.add("desktop");
            headerBlock.bindOnHover();
        } else {
            headerSection.classList.add("smalldevices");
            headerContainer.classList.remove("desktop");
            headerBlock.unbindOnHover();
        }
    },

    bindOnHover: function () {
        const navigationWithChildNav = document.querySelector(".navigation .nav");
        const childNavigation = document.querySelector(".navigation");

        if (navigationWithChildNav) {
            domHelper
                .toArray(
                    navigationWithChildNav.querySelectorAll("a")
                )
                .forEach(function (element) {
                    element.onmouseover = headerBlock.onNavigationHover;
                });
        }

        if (childNavigation) {
            childNavigation.onmouseleave =
                headerBlock.onNavigationLeave;
        }
    },

    unbindOnHover: function () {
        domHelper
            .toArray(
                document.querySelector(".navigation .nav").querySelectorAll("a")
            )
            .forEach(function (element) {
                element.onmouseover = null;
            });

        document.querySelector(".navigation").onmouseleave = null;
    },

    adjustLayout: function () {
        var subMenuIsPresent = document.querySelector(".firstline");
        var windowWidth = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        );
        var menuHeight = subMenuIsPresent.clientHeight;

        if (windowWidth < 480) {
            headerBlock.prevScrollpos = window.pageYOffset;
            window.onscroll = headerBlock.onScroll;
        }
    },

    onScroll: function () {
        var element = document.querySelector(".firstline");
        var currentScrollPos = window.pageYOffset;

        if (headerBlock.prevScrollpos > currentScrollPos) {
            element.classList.remove("hide-menu");
        } else if (headerBlock.prevScrollpos + 2 < currentScrollPos) {
            element.classList.add("hide-menu");
        }
        headerBlock.prevScrollpos = currentScrollPos;
    },

    onWindowResize: function () {
        let signInLinks = document
            .querySelector(".links")
            .querySelector(".account-menu-link");
        let resizedWidth = document.body.clientWidth;

        headerBlock.setNavigationBarDisplay();
        burgerMenu.close();

        if ((!device.isAndroid) || (headerBlock.loadedWidth != device.isCurrentClientWidth() && device.isAndroid)) {
            if (!device.isLarger()) {
                headerBlock.loadedWidth = device.isCurrentClientWidth();
            } else {
                headerBlock.loadedWidth = resizedWidth;
            }
        }

        if (domHelper.hasClass(signInLinks, "close")) {
            signInMenu.close();
        } else {
            signInMenu.closeSignInMenuForLargerDevice();
        }
    },

    logout: function () {
        document.getElementById("logout-button").click();
    },
};

//Listen for the event
window.addEventListener("externalnavigationload", function (evt) {
    headerBlock.initialize();
});

window.addEventListener("load", function (evt) {
    headerBlock.initialize();
});
